import zapierBlockImg from 'images/zapier-connections.png';
import zapierBlockImg2 from 'images/zapier-user.jpg';
import zapierBlockImg4 from 'images/zapier-listening.png';
import zapierBlockImg6 from 'images/zapier-user-5.png';

import React, { FC } from 'react';
import SectionFounder from './SectionFounder';
import SectionStatistic from './SectionStatistic';
import { Helmet } from 'react-helmet';
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from '../../components/BgGlassmorphism/BgGlassmorphism';
import SectionHero from './SectionHero';

export interface PageAboutProps {
  className?: string;
}

const ZapierHelp: FC<PageAboutProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id='PageAbout'
    >
      <Helmet>
        <title>About || Skillfusion.ai</title>
        <link
          rel='canonical'
          href='https://skillfusion.ai/zapier-help'
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>
        <SectionHero
          rightImg={zapierBlockImg}
          heading='Zapier Block'
          btnText=''
          leftText={false}
          subHeading="The Zapier block will allow you to send the output of the current AI Tool to any of Zapier's 6,000+ APIs Integrations.<br/><br/>
          Such as your email client (like gmail or outlook), or to your website (wordpress, shopify, magento). <br/><br/>
          "
        />

        <SectionHero
          rightImg={zapierBlockImg2}
          heading='Using the Zapier Block'
          btnText=''
          leftText={true}
          subHeading='
          Go to a tool that says it uses zapier, or our Zapier Test tool.<br/><br/>
          Work your way through the tool until you get to the Zapier block
          '
        />

        <SectionHero
          rightImg={zapierBlockImg4}
          heading=''
          btnText=''
          leftText={false}
          subHeading="
          Head over to Zapier, choose Skillfusion as the trigger for your new zap, and follow the steps until you see <q>We're listening</q><br/>
          "
        />

        <SectionHero
          rightImg={zapierBlockImg2}
          heading=''
          btnText=''
          leftText={true}
          subHeading='
          Come back to Skillfusion and press <q>Refresh Hooks</q>, the last hook on the list should be the one you just created, it should match the current date and time.<br/><br/>
          
          '
        />

        <SectionHero
          rightImg={zapierBlockImg6}
          heading=''
          btnText=''
          leftText={false}
          subHeading='Go back to Zapier and click <q>Test Trigger</q>, you should see your data appear<br/><br/>
          Now you can carry on building your Zap to send that data wherever you want<br/><br/>'
        />
      </div>
    </div>
  );
};

export default ZapierHelp;
