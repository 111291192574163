import React, { FC } from 'react';

const Toggle: FC<{
  toggled: boolean;
  onChange: any;
  className?: string;
  toggledClassName?: string;
}> = ({ toggled, onChange, className, toggledClassName }) => {
  return (
    <div className={`relative ${className ?? ''}`}>
      <input
        type='checkbox'
        className='hidden'
        checked={toggled}
        onChange={onChange}
      />
      <div className='toggle__line w-10 h-4 bg-gradient-to-r from-gray-400 to-gray-500 rounded-full shadow-inner transition-all duration-200 ease-in-out overflow-hidden'>
        <div
          className={`toggle__dot absolute top-1/2 transform -translate-y-1/2 w-6 h-6 bg-white rounded-full shadow-md transition-all duration-200 ease-in-out ${
            toggled ? 'translate-x-full' : 'translate-x-0'
          } `}
          style={{
            background: 'linear-gradient(to right, #3b82f6, #60a5fa)',
            boxShadow: '0px 0px 2px 3px rgba(0,0,0,0.1)',
          }}
        ></div>
      </div>
    </div>
  );
};

export default Toggle;
