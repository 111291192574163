const randomNumberBetween = (min: number, max: number) => {
  return min + Math.random() * (max - min);
};

const cosinesim = function (A: number[], B: number[]) {
  let dotproduct = 0;
  let mA = 0;
  let mB = 0;

  for (let i = 0; i < A.length; i++) {
    dotproduct += A[i] * B[i];
    mA += A[i] * A[i];
    mB += B[i] * B[i];
  }

  mA = Math.sqrt(mA);
  mB = Math.sqrt(mB);

  return dotproduct / (mA * mB);
};

function excelColumnToNumber(column: string) {
  column = column.toUpperCase();

  let number = 0;
  const length = column.length;

  for (let i = 0; i < length; i++) {
    const charCode = column.charCodeAt(i) - 64; // 'A' is 65 in ASCII, so subtract 64 to get 1 for 'A', 2 for 'B', etc.
    number = number * 26 + charCode;
  }

  return number;
}

function numberToExcelColumn(number:number) {
  let column = '';

  while (number > 0) {
    const remainder = (number - 1) % 26;
    column = String.fromCharCode(65 + remainder) + column;
    number = Math.floor((number - 1) / 26);
  }

  return column;
}


export { randomNumberBetween, cosinesim, excelColumnToNumber,numberToExcelColumn };
