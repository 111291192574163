import React, { useContext, useMemo } from 'react';
import ButtonClose from '../../shared/ButtonClose/ButtonClose';
import Logo from '../../shared/Logo/Logo';
import { Disclosure } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import { NavItemType } from './NavigationItem';
import { NAVIGATION_DEMO_2 } from '../../data/navigation';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useAuthUser } from 'react-auth-kit';
import { useCredit } from '../../context/CreditContext';
import HeaderContext from '../../context/HeaderContext';
import Avatar from '../Avatar/Avatar';
export interface NavMobileProps {
  data?: NavItemType[];
  closeMenu?: any;
}

const NavMobile: React.FC<NavMobileProps> = ({ closeMenu }) => {
  const { setReferAFriendModalOpen } = useContext(HeaderContext);
  const auth = useAuthUser();
  const user = auth();
  const { credit, setCredit } = useCredit();
  const data = useMemo(
    () =>
      NAVIGATION_DEMO_2({
        uid: user?.id,
        onReferAFriendClick: () => setReferAFriendModalOpen(true),
        credit,
        profileImage: user?.profileImage,
        sidebar: true,
        trialAccount: user?.trialAccount,
      }),
    [auth, credit]
  );

  const _renderMenuChild = (item: NavItemType) => {
    return (
      <ul className='nav-mobile-sub-menu pl-6 pb-1 text-base'>
        {item.children?.map((i, index) => (
          <Disclosure
            key={(i.href ?? i.name ?? i.id) + index}
            as='li'
          >
            <NavLink
              end
              to={{
                pathname: i.href || undefined,
              }}
              className={({ isActive }) =>
                `flex px-4 py-2.5 dark:text-neutral-200 text-sm font-medium rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-[2px] ${
                  isActive ? 'text-secondary-500' : 'text-neutral-900'
                }`
              }
              onClick={closeMenu}
            >
              <span className={!i.children ? 'block w-full' : ''}>
                {i.name}
              </span>
              {i.children && (
                <span
                  className='block flex-grow'
                  onClick={e => e.preventDefault()}
                >
                  <Disclosure.Button
                    as='span'
                    className='flex justify-end flex-grow'
                  >
                    <ChevronDownIcon
                      className='ml-2 h-4 w-4 text-neutral-500'
                      aria-hidden='true'
                    />
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.children && (
              <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure
        key={item.id}
        as='li'
        className='text-neutral-900 dark:text-white'
      >
        {item.href ? (
          <NavLink
            end
            className={({ isActive }) =>
              `flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg ${
                isActive ? 'text-secondary-500' : ''
              }`
            }
            to={{
              pathname: item.href,
            }}
          >
            <span
              className={!item.children ? 'block w-full' : ''}
              onClick={() => {
                if (item.onClick) item.onClick();
                closeMenu();
              }}
            >
              {item.type === 'imageDropdown' ? (
                <Avatar
                  imageUrl={item?.image}
                  sizeClass='h-9 w-9'
                  radius='rounded-full'
                />
              ) : (
                item.name
              )}
            </span>
            {item.children && (
              <span
                className='block flex-grow'
                onClick={e => e.preventDefault()}
              >
                <Disclosure.Button
                  as='span'
                  className='flex justify-end flex-grow'
                >
                  <ChevronDownIcon
                    className='ml-2 h-4 w-4 text-neutral-500'
                    aria-hidden='true'
                  />
                </Disclosure.Button>
              </span>
            )}
          </NavLink>
        ) : (
          <button
            className='flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg'
            onClick={() => {
              item.onClick();
              closeMenu();
            }}
          >
            {item.name}
            {item.children && (
              <span
                className='block flex-grow'
                onClick={e => e.preventDefault()}
              >
                <Disclosure.Button
                  as='span'
                  className='flex justify-end flex-grow'
                >
                  <ChevronDownIcon
                    className='ml-2 h-4 w-4 text-neutral-500'
                    aria-hidden='true'
                  />
                </Disclosure.Button>
              </span>
            )}
          </button>
        )}
        {item.children && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };

  return (
    <div className='overflow-y-auto w-full max-w-sm h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800'>
      <div className='py-6 px-5'>
        <Logo />
        <div className='flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm'>
          <div className='flex justify-between items-center mt-4'>
            {/*<SocialsList itemClass="w-7 h-7 sm:w-8 sm:h-8 flex items-center justify-center rounded-full text-xl" />*/}
            {/* <span className="block">
              <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" />
            </span>*/}
          </div>
        </div>
        <span className='absolute right-2 top-2 p-1'>
          <ButtonClose onClick={closeMenu} />
        </span>
      </div>
      <ul className='flex flex-col py-6 px-2 space-y-1'>
        {data.map(_renderItem)}
      </ul>
      <div className='flex items-center justify-between py-6 px-5 space-x-2'>
        {/* <Link to={`/profile/${auth()?.id}`}>
       <ButtonPrimary className="!px-10">
          My Profile
        </ButtonPrimary>
  </Link>

        {auth()?.id ? (
  <ButtonSecondary onClick={useSignOut} className="flex-1">
    Logout
  </ButtonSecondary>
) : (
  <ButtonSecondary href="/login" className="flex-1">
    Login
  </ButtonSecondary>
)}

        */}
      </div>
    </div>
  );
};

export default NavMobile;
