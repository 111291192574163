import { useNavigate } from 'react-router-dom';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import axios from 'axios';
import { backendURL } from '../constants/environmental';
import React, { useCallback, useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { useCredit } from '../context/CreditContext';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This test secret API key is a placeholder. Don't include personal details in requests with this key.
// To see your test secret API key embedded in code samples, sign in to your Stripe account.
// You can also find your test secret API key at https://dashboard.stripe.com/test/apikeys.
const stripePromise = loadStripe(
  'pk_test_51PY3QLCx0Rplw9dfcGEztIr3KWhZXOV0tQ1wzvCvZr1qBIjEw6st1ZymA9SCDhv25iJl2X17vM8zW41APQJYKLKS00ZP7DHO6O'
);

const StripeTestPage = ({ inModal }: any) => {
  const navigate = useNavigate();
  const authHeader = useAuthHeader()();
  const { credit, updateCredits } = useCredit();

  const [sessionId, setSessionId] = useState<string | undefined>();
  const [checkoutComplete, setCheckoutComplete] = useState<boolean>(false);

  // this is set only if the user's payment method involved a redirect
  const [sessionIdUrlParameter, setSessionIdUrlParameter] = useState<
    string | null
  >(null);

  const [paymentStatusMessage, setPaymentStatusMessage] = useState(
    'Waiting for payment confirmation...'
  );

  const fetchClientSecret = useCallback(() => {
    return axios
      .post(
        `${backendURL}/stripe/create-checkout-session`,
        {
          currency: 'GBP',
          amountOfCredits: 200,
          billingFrequency: 'monthly',
        },
        { headers: { Authorization: authHeader } }
      )
      .then(({ data: { clientSecret } }) => {
        setSessionId(clientSecret.split('_secret_')[0]);
        return clientSecret;
      });
  }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParameters = new URLSearchParams(queryString);

    setSessionIdUrlParameter(urlParameters.get('session_id'));
  }, []);

  useEffect(() => {
    if (!(sessionIdUrlParameter || checkoutComplete)) {
      return;
    }

    (async () => {
      try {
        const fulfilledOrderResponse = await axios.get(
          `${backendURL}/stripe/await-order-fulfillment?session_id=${sessionIdUrlParameter || sessionId}`,
          { headers: { Authorization: authHeader } }
        );

        const {
          data: {
            creditsBought,
            transactionType,
            bonusCredits,
            dateOfNextPaymentString,
            newCreditAmount,
          },
        } = fulfilledOrderResponse;

        setPaymentStatusMessage(
          'Payment successful! Redirecting you to the tools page...'
        );

        updateCredits(newCreditAmount);

        // if (inModal) onPayment();
        // else {
        navigate(`/page-search/`, {
          state: {
            creditsBought,
            newCreditAmount,
            transactionType,
            bonusCredits,
            dateOfNextPaymentString,
          },
        });
        // }
      } catch (error) {
        if (error?.response?.status === 408) {
          setPaymentStatusMessage(
            'Request timed out. Please check if your credits have been added by refreshing the page.'
          );
        }
      }
    })();
  }, [sessionIdUrlParameter, checkoutComplete]);

  return (
    <div>
      <h1 className='text-5xl text-center'>Stripe test</h1>
      <br />
      <div id='checkout'>
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{
            fetchClientSecret,
            onComplete: () => {
              setCheckoutComplete(true);
            },
          }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
      {(sessionIdUrlParameter || checkoutComplete) && (
        <p className='text-center'>{paymentStatusMessage}</p>
      )}
    </div>
  );
};

export default StripeTestPage;
