import React, { useState } from 'react';
import PageSignUp from '../containers/PageSignUp/PageSignUp';
import VerifyEmail from '../containers/PageLogin/VerifyEmail';

function SignupModalSequence() {
  const [showSignup, setShowSignup] = useState(true);
  const [showVerification, setShowVerification] = useState(false);
  const [verificationDetails, setVerificationDetails] = useState({
    tempID: '',
    verifyEmailMessage: '',
  });

  const handleVerification = (email: string, tempID: string) => {
    setVerificationDetails({
      tempID,
      verifyEmailMessage: `A 6-digit code has been sent to your email ${email}, please enter the code here to verify your email.`,
    });
    setShowVerification(true);
    setShowSignup(false);
  };

  return (
    <>
      {showSignup && (
        <PageSignUp
          inModal={true}
          onVerify={(tempID: string, email: string) =>
            handleVerification(tempID, email)
          }
        />
      )}
      {showVerification && (
        <VerifyEmail
          tempModalID={verificationDetails.tempID}
          message={verificationDetails.verifyEmailMessage}
        />
      )}
    </>
  );
}

export default SignupModalSequence;
