import React, { useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { backendURL } from '../../constants/environmental';

const ForgotPassword: React.FC = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const [statusMessage, setStatusMessage] = useState('');
  const navigate = useNavigate();

  const [resetPasswordButtonText, setResetPasswordButtonText] =
    useState('Send reset code');

  const handleForgotPassword = async (event: React.FormEvent) => {
    event.preventDefault();
    setResetPasswordButtonText('Sending reset code...');

    const email = emailRef.current!.value;

    try {
      const response = await axios.post(`${backendURL}/user/forgot-password`, {
        code: '',
        email,
      });
      if (response.status === 200) {
        navigate('/reset-password/0', {
          state: {
            instructions: `If an account with the email address ${email} exists and is verified, then a 12 digit code has been sent to that email. Please enter the code here, or alternatively follow the instructions in the email.`,
          },
        });
      }
    } catch (error) {
      console.error('Error sending password reset link', error);
      setStatusMessage('There was a problem sending your reset code');
      setResetPasswordButtonText('Send reset code');
    }
  };

  return (
    <div className='w-full max-w-xs mx-auto mt-20'>
      <form
        onSubmit={handleForgotPassword}
        className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col gap-4'
      >
        <p>
          Please enter the email address you registered your account with, and
          we will send a code to reset your password.
        </p>
        <div className='mb-4'>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='email'
          >
            Email
          </label>
          <input
            ref={emailRef}
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            id='email'
            type='email'
            placeholder='Email Address'
            required
          />
        </div>
        <div className='flex justify-center'>
          <button
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            type='submit'
          >
            {resetPasswordButtonText}
          </button>
        </div>
        {statusMessage && (
          <>
            <br />
            <p>{statusMessage}</p>
          </>
        )}
      </form>

      <p className='text-center text-gray-500 text-xs'>
        &copy;{new Date().getFullYear()} Skillfusion.AI. All rights reserved.
      </p>
    </div>
  );
};

export default ForgotPassword;
