import creditsImg2 from 'images/office.jpg';
import SectionHero, { VideoHero } from './SectionHero';
import { useNavigate } from 'react-router-dom';
import React, { FC } from 'react';
import SectionFounder from './SectionFounder';
import SectionStatistic from './SectionStatistic';
import { Helmet } from 'react-helmet';
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import ButtonPrimary from '../../shared/Button/ButtonPrimary';
import PageSearch from '../PageSearch';

export interface PageAboutProps {
  className?: string;
}

const OpportunityHelp: FC<PageAboutProps> = ({ className = '' }) => {
  const navigate = useNavigate();
  const handleLinkClick = (event: any) => {
    // Prevent the default link behavior
    event.preventDefault();
    const newPath = event.currentTarget.getAttribute('href');
    // Navigate to the link href
    navigate(newPath);
  };

  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id='PageAbout'
    >
      <Helmet>
        <title>About || Skillfusion.ai</title>
        <link
          rel='canonical'
          href='https://skillfusion.ai/opportunity-help'
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      {/*<BgGlassmorphism /> */}

      <div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>
        <VideoHero
          videoUrl={
            'https://www.youtube.com/embed/mbA3Aygc870?si=9AEe3srkr4o1-6j2'
          }
          rightImg=''
          heading='Make &amp; Sell AI Tools'
          btnText=''
          leftText={true}
          subHeading="
          <p>Turn your knowledge and skills into sellable AI Tools. <br/><br/>Make high quality tools that are more tailored and powerful than GPTs.</p>
          <br/>
          <ul style='list-style:disc; margin:0 30px; text-align:left'>
          <!--<li>Make and sell AI Tools &amp; Chatbots on our marketplace</li>-->
          <li>Use our no-code block based builder</li>
          <li>You get 100% of all sales</li>
          <!--<li>You can turn any skill or knowledge in to a tool</li>-->
          </ul>
          <br/>
          <p>Our block builder allows you to make multi-step tools. Tools that can generate an entire book (60,000 words) from a single idea.</p>
          
          "
        />

        <ul
          style={{ listStyle: 'disc', margin: '20px 30px', textAlign: 'left' }}
          className='z-10'
        >
          <li>
            Step 1:{' '}
            <a
              href='/signup'
              style={{ color: 'cornflowerblue' }}
              onClick={handleLinkClick}
            >
              Register
            </a> for free
          </li>
          <li>
            Step 2: Check out our{' '}
            <a
              href='/fuser/0'
              style={{ color: 'cornflowerblue' }}
              onClick={handleLinkClick}
            >
              Tool Maker
            </a>{' '}
            here
          </li>
          <li>
            Help file: {' '}
            <a
              href='/maker-help'
              style={{ color: 'cornflowerblue' }}
              onClick={handleLinkClick}
            >
              how to make AI tools</a>.
          </li>
          <li>
            Help file: {' '}
            <a
              href='/chatbot-help'
              style={{ color: 'cornflowerblue' }}
              onClick={handleLinkClick}
            >
              how to make Chatbots</a>.
          </li>
          <li>Step 3: Tell everybody about your AI creation!</li>
        </ul>

        <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">Check out some of our popular tools:</h2>
        <div className='flex-grow mt-2 space-y-2' style={{marginTop:0}}>
              <PageSearch mode='preview' />
            </div>


        <SectionHero
          rightImg={creditsImg2}
          heading=''
          btnText=''
          leftText={false}
          subHeading="
          
          The Skillfusion Marketplace already has 130 tools and over 10,000 users signed up users.
          <br/><br/>
          
          Make tools that allow users to do more than they can with chatgpt alone.
          <br/><br/>
          
          Each tool is focused on a particular task – like writes a book, solves a particular business problem, reviews contracts.
          <br/><br/>
          
          The benefit to users:
          <br/>
          ✔️ Tools created and vetted by real humans<br/>
          ✔️ multiple prompts combined<br/>
          ✔️ web access<br/>
          ✔️ image generation<br/>
          ✔️ embedded knowledge<br/>
          ✔️ Zapier connection<br/>
          ✔️ Google search<br/>
          ✔️ Longer output
          <br/><br/>
          
          
          Whatever you have knowledge about, or a useful prompt for, you can make it in to a tool.
          <br/><br/>
          
          You can either:
          <ul  style='list-style:inside'>
          <li>sell the tool on our site and get 100% of all sales</li>
          <li>or publish it as a free tool and add your contact details (e.g. “contact us for more help with this”)</li>
          <li>Or make your tool free to get some purchases and reviews and then chage it to a paid tool going forward</li>
          </ul>
          <br/><br/>
          Tools can be sold for a one off payment or for a recurring monthly fee."
        />
        <p
          className='text-right'
          style={{ marginTop: '5px' }}
        >
          <ButtonPrimary
            type='submit'
            onClick={() => navigate('/signup')}
          >
            Register Now
          </ButtonPrimary>
        </p>
      </div>
    </div>
  );
};

export default OpportunityHelp;
