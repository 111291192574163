import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import 'firebase/auth';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { backendURL } from '../constants/environmental';
// import { SearchResult } from '../../TypeScript/Interfaces';
// import { UserSchema } from '../../TypeScript/Interfaces';
// import { Skill } from '../../TypeScript/Types';
// import { AiOutlineArrowRight } from 'react-icons/ai';
// import { FiMoreHorizontal } from 'react-icons/fi';
// import FuserCard from '../components/CardFuser';
// import Input from '../shared/Input/Input';
// import EarningsChart from '../components/EarningsChart';
// import { MdPayments } from 'react-icons/md';
// import Pagination from '../shared/Pagination/Pagination';
// import ButtonPrimary from '../shared/Button/ButtonPrimary';
// import { avatarImgs, nftsImgs } from '../constants/fakeData';
// import ButtonCircle from '../shared/Button/ButtonCircle';
// import { count } from "console";

/*
todo:
earnings and downloads data for creators
could make this in to the search page too eventually to make app more efficient
*/
export interface DashboardPageProps {
  className?: string;
}

interface Block {
  _id: string;
  name: string;
  description: string;
  authorId: string;
  coverPhoto: string;
  price: number;
  priceFrequency: 'monthly' | 'yearly' | 'one-time';
  isPublished: string;
  numberOfTransactions: 0;
  totalUserCost: 0;
  totalCostToSF: 0;
  // ... add other properties if needed
}

interface purchasedTools {
  blocks: Block[];
  // ... add other properties if the API response has more
}

// enum DashboardMode {
//   Creator = 'Creator',
//   User = 'User',
// }

const AdminPage: FC<DashboardPageProps> = ({ className = '' }) => {
  const authHeader = useAuthHeader()();

  const user = useAuthUser();
  const navigate = useNavigate();
  const location = useLocation();
  const uid = location.pathname.split('/').pop()?.split('?')[0] || '';
  // const [searchTerm, setSearchTerm] = useState<string>('');
  // const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  // const debounceTimeout = useRef<NodeJS.Timeout | number | null>(null);
  // const [filters, setFilters] = useState({});
  // const [category, setCategory] = useState<string>('All SkillFusion');

  const [userOverview, setuserOverview] = useState(
    '<div style={{width: "100%", textAlign: "center", paddingBottom: "50px"}}><p>checking access...</p></div>'
  );

  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalUserCost, setTotalUserCost] = useState(0);
  const [totalCostToSF, setTotalCostToSF] = useState(0);
  // const [toolProfit, settoolProfit] = useState(0);
  const [apiProfit, setApiProfit] = useState(0);
  const [allCosts, setAllCosts] = useState('');

  const [apiStats, setApiStats] = useState('');
  // redirects user if not logged in
  useEffect(() => {
    if (!user()?.id) {
      console.log('Not logged in');
      navigate('/login');
    }
  });

  // get list of purchased tools
  const [Tools, setTools] = useState<purchasedTools | null>(null);

  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await axios.post(
          `${backendURL}/admin/getAllUserStats`,
          {},
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );

        console.log('admin response', response);

        if (response.status === 200) {
          let overview =
            'Total users: ' +
            response.data.userDetails[0].count +
            '<br/>' +
            'Pledged Free Credit: $' +
            response.data.userDetails[0].totalFreeCredit +
            " - (watch this doesn't get too high)<br/>\n";
          const boughtCredit = Math.round(
            response.data.userDetails[0].totalUserCredit -
              response.data.userDetails[0].totalFreeCredit
          );
          overview +=
            'Bought Credit: $' +
            boughtCredit +
            ' - (this will add up better when we launch!)';
          setuserOverview(overview);

          //totalFreeCredit
          //totalUserCredit

          console.log(response.data);
        }
      } catch (error) {
        console.error('Error fetching purchased tools', error);
      }
    }

    fetchUsers();
  }, [location]);

  useEffect(() => {
    async function fetchTransactions() {
      try {
        const response = await axios.post(
          `${backendURL}/admin/getAllApiStats`,
          {},
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );

        if (response.status === 200) {
          const difference =
            Number(response.data.apiDetails[0].userCost || '0') -
            Number(response.data.apiDetails[0].costToSF || '0');
          let overview =
            'Total API Costs: ' +
            response.data.apiDetails[0].costToSF +
            '<br/>' +
            "user's charged: $" +
            response.data.apiDetails[0].userCost +
            ' - (some will be free credit)<br/>\n';
          overview += 'Difference: $' + difference.toString() + '<br/>';
          overview +=
            'Total API calls: ' + response.data.apiDetails[0].count + '<br/>';
          overview +=
            'Total tracked profit: ' + response.data.apiDetails[0].sfProfit;

          setApiStats(overview);

          setApiProfit(response.data.apiDetails[0].sfProfit);
        }
      } catch (error) {
        console.error('Error fetching purchased tools', error);
      }
    }

    fetchTransactions();
  }, [location]);

  useEffect(() => {
    async function fetchAllCosts() {
      try {
        const response = await axios.post(
          `${backendURL}/admin/allCosts`,
          {},
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );

        if (response.status === 200) {
          let overview = '';

          console.log(
            'allCosts',
            response.data,
            response.data.length,
            typeof response.data
          );

          /*
            response.data.map((type : any)=> {
              overview += type._id + ": user's charged: $" + type.userCost + " | Cost to SF: $" + type.costToSF + " | Profit: $" + type.sfProfit + " - " + type.count + " transactions<br />";
              console.log("overview in map", overview);
            })
            */

          let totalProfit = 0;
          Object.keys(response.data.allCosts).forEach(function (key, index) {
            overview +=
              response.data.allCosts[key]._id +
              ': SF Profit: $' +
              response.data.allCosts[key].sfProfit +
              '<br/>';
            totalProfit += Number(response.data.allCosts[key].sfProfit);
          });

          overview +=
            'Total SF Profit: $' +
            totalProfit.toString() +
            '<br/><br/>Transactions Breakdown:<br />';

          Object.keys(response.data.allCosts).forEach(function (key, index) {
            overview +=
              response.data.allCosts[key]._id +
              ': Users charged: $' +
              response.data.allCosts[key].userCost +
              ': SF Costs: $' +
              response.data.allCosts[key].costToSF +
              ': Total Transactions: ' +
              response.data.allCosts[key].count +
              '<br/>';
          });

          console.log('overview after', overview);

          setAllCosts(overview);
        }
      } catch (error) {
        console.error('Error fetching purchased tools', error);
      }
    }

    fetchAllCosts();
  }, [location]);

  useEffect(() => {
    async function fetchTools() {
      try {
        const response = await axios.post(
          `${backendURL}/blocks/getMadeBlocksByUserIDPrivate/${uid}`,
          {},
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );
        // todo get creator stats here like toos sold, monthly sign ups

        if (response.status === 200) {
          // Sorting the array based on numberOfTransactions, in descending order
          const sortedBlocks = [...response.data.blocks].sort(
            (a, b) => b.numberOfTransactions - a.numberOfTransactions
          );

          setTools({ ...response.data, blocks: sortedBlocks });

          //setTools(response.data);

          let tempTotalTransactions = 0;
          let tempTotalUserCost = 0;
          let tempTotalCostToSF = 0;

          for (const tool of response.data.blocks) {
            tempTotalTransactions += tool.numberOfTransactions || 0;
            tempTotalUserCost += tool.totalUserCost || 0;
            tempTotalCostToSF += tool.totalCostToSF || 0;
          }

          setTotalTransactions(tempTotalTransactions);
          setTotalUserCost(tempTotalUserCost);
          setTotalCostToSF(tempTotalCostToSF);
          //settoolProfit(tempTotalUserCost - tempTotalCostToSF); // incorrect due to free credit
        }
      } catch (error) {
        console.error('Error fetching purchased tools', error);
      }
    }

    fetchTools();
  }, [location]);

  console.log('user ID when getting error: ' + user()?.id);
  if (user()?.id !== uid) {
    // front end?
    return (
      <div className='w-full  flex items-center justi fy-center flex-col gap-2 min-h-96'>
        <p>
          You have entered an incorrect user ID {user()?.id} vs {uid}
        </p>
      </div>
    );
  }

  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();

    const newPath = event.currentTarget.getAttribute('href');
    if (newPath) {
      navigate(newPath);
    }
  };

  /// copied from PageSearch
  /*
  const searchEntities = async (searchTerm: string, filters: any): Promise<SearchResult[]> => {
    try {
      const params = category !== 'All NFTs' ? { searchTerm, category } : { searchTerm };
      const searchForCategory = axios.get(`${backendURL}/site/search/categories`, { params: { searchTerm: searchTerm, ...filters } });
      const searchForBlock = axios.get(`${backendURL}/site/search/blocks`, { params: { searchTerm: searchTerm, ...filters } });

      const [categoryResponse, blockResponse] = await Promise.all([searchForCategory, searchForBlock]);
  
      const categories = categoryResponse.data.results.map((category: any) => ({...category, username: category.catName || 'default', _type: 'category'}));
      const blocks = blockResponse.data.results.map((block: any) => ({...block, title: block.title, price: block.price || 'default', _type: 'block'}));
  
      console.log("search results \n" , "\n category: " , categories, "\n blocks : " , blocks);
      return [...categories, ...blocks];
    } catch(err) {
      console.log("Error searching: ", err);
      return [];
    }
};


  const handleSearchInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      setSearchTerm(inputValue);
  
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current as any);
      }
  
      if (inputValue.length > 2) {
        debounceTimeout.current = setTimeout(async () => {
          const results = await searchEntities(inputValue, filters); // include filters here
          setSearchResults(results);
        }, 500);
      } else {
        setSearchResults([]);
      }
    },
    [filters] // include filters in the dependencies array
  );
  */
  //// end of copied from search page

  return (
    <div>
      {/* copied from search page for when we add search functionality */}
      {/*
<div className="container">
        <header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
          <form className="relative w-full ">
            <label
              htmlFor="search-input"
              className="text-neutral-500 dark:text-neutral-300"
            >
              <span className="sr-only">Search all icons</span>
              <Input
                className="shadow-lg border-0 dark:border"
                id="search-input"
                type="search"
                placeholder="Type your keywords"
                sizeClass="pl-14 py-5 pr-5 md:pl-16"
                rounded="rounded-full"
                onChange={handleSearchInputChange}
              />
              <ButtonCircle
                className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                size=" w-11 h-11"
                type="submit"
              >
                <i className="las la-arrow-right text-xl"></i>
              </ButtonCircle>
            </label>
          </form>
        </header>
      </div>
*/}

      <div className='container py-2 lg:pb-28 lg:pt-2 space-y-2 lg:space-y-4'>
        <h2 className='my-4 flex items-center text-xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
          Admin Stats
        </h2>

        <p className='text-neutral-900  dark:text-neutral-100'>
          All Costs:
          <br />
          <span dangerouslySetInnerHTML={{ __html: allCosts }} />
        </p>

        <p className='text-neutral-900  dark:text-neutral-100'>
          Users:
          <br />
          <span dangerouslySetInnerHTML={{ __html: userOverview }} />
        </p>

        <br />
        <p className='text-neutral-900  dark:text-neutral-100'>
          Tools:
          <br />
          Total tool sales: {totalTransactions} - (includes free tools)
          <br />
          Total sales revenue: ${totalUserCost} - (includes tools bought with
          free credits)
          <br />
          Total commission to pay out: ${totalCostToSF}
          <br />
          Profit from tools: ???
        </p>

        <br />
        <p className='text-neutral-900  dark:text-neutral-100'>
          Api:
          <br />
          <span dangerouslySetInnerHTML={{ __html: apiStats }} />
        </p>

        <br />
        <br />
        <p>All Tools by popularity:</p>
        <div className='grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-10 mt-2 lg:mt-2'>
          {Tools
            ? Tools.blocks.map((result, index) => {
                const link = `/fuser/${result._id}`;
                //const bgLightMode = `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${result.coverPhoto ? result.coverPhoto : nftsImgs[5]})`;
                //const bgDarkMode = `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${result.coverPhoto ? result.coverPhoto : nftsImgs[5]})`;

                return (
                  <Link
                    className='w-full rounded-lg text-black mb-20'
                    key={result._id}
                    to={link}
                    onClick={handleLinkClick}
                    style={{
                      background: `#ae95c1`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    <div className='border-2 p-4 rounded-md shadow-lg w-full flex h-full overflow-scroll'>
                      <span className='flex items-center justify-between w-full gap-2'>
                        <div className='flex flex-col h-52'>
                          <h2 className='font-bold text-lg mb-2 overflow-ellipsis max-w-full'>{`${result.name}`}</h2>
                          <p>
                            Price:&nbsp;
                            {typeof result.price === 'number' &&
                            result.price !== 0.0
                              ? `${
                                  result.priceFrequency === 'monthly'
                                    ? 'You pay ' +
                                      result.price * 10 +
                                      ' credits per month'
                                    : 'You paid ' +
                                      result.price * 10 +
                                      ' credits'
                                }`
                              : 'Free Tool'}
                          </p>
                          <p>Published:&nbsp;{String(result.isPublished)}</p>
                          <p className='overflow-ellipsis max-w-full'>
                            {`${result.description}` || ''}
                          </p>
                          {result.coverPhoto ? (
                            <img
                              src={result.coverPhoto}
                              alt='Cover Photo'
                            />
                          ) : null}
                          {/* <div className="flex items-center justify-end gap-2 z-10 text-blue-400">
            <AiOutlineContacts onClick={() => { navigate(`/profile/${result.authorId}`) }} className="hover:scale-110 " />
            <AiOutlineEye onClick={() => { navigate(`/fusion/${result._id}`) }} className="hover:scale-110 dark:text-blue-400 " />
            <MdViewInAr onClick={() => { navigate(`/fuser/${result._id}`) }} className="hover:scale-110 " />
  </div> */}
                        </div>
                      </span>
                    </div>
                    <p className='text-neutral-900  dark:text-neutral-100'>
                      Total sales {String(result.numberOfTransactions)}
                      <br />
                      Total sales value: ${String(result.totalUserCost)}
                      <br />
                      {`Total seller's commission: $${result.totalCostToSF}`}{' '}
                      <br />
                    </p>
                  </Link>
                );
              })
            : ''}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
