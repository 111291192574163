import React, { FC, useState, useEffect, useContext } from 'react';
import ReactGA from 'react-ga4';
import FuserLoader from '../../containers/FuserPage/FuserLoader';
import BlockProps from '../../models/BlockProps';
import { MAX_PREVIEW_CHARS } from '../../constants/blocks';
import '../../index.css';
import { useCredit } from '../../context/CreditContext';
import MyToolTips from '../../components/MyTooltip';
import FuserContext from '../../context/FuserContext';
import Block from '../../models/Block';
import RestartPromptButton from '../../containers/FuserPage/RestartPromptButton';
import useBlockRunner from '../../hooks/useBlockRunner';
import useCancellablePause from '../../hooks/useCancellablePause';
import axios from 'axios';
import TextareaAutosize from 'react-textarea-autosize';
import AutocompleteTextarea from '../../containers/FuserPage/AutocompleteTextarea';
import { useAuthHeader } from 'react-auth-kit';
import { testButtonStyles } from '../../constants/styles';
import { backendURL } from '../../constants/environmental';
import {
  updateAtIndexRun,
  flatten,
  unflatten,
  ensureArray,
  batchArray,
} from '../../utils/array';
import { truncateAfter } from '../../utils/string';
import {
  defaultInputValues,
  gptParamsType,
  handleAxiosError,
  MAX_PROMPT_BATCH_SIZE,
  MAX_RESPONSE_WAIT_TIME_IN_SECONDS,
  MAX_RETRIES,
  retryPromptCondition,
  sendReactGaRetryEvent,
} from '../../utils/prompt';
import { randomNumberBetween } from '../../utils/math';
import GptParameters from '../../containers/FuserPage/GptParameters';
import GptModelSelect from '../../containers/FuserPage/GptModelSelect';
import 'react18-json-view/src/style.css';
import { replacePlaceholders, tidyUpResponse } from '../../utils/fuser';

const PromptBlock: FC<BlockProps> = ({
  toolId,
  isLoading,
  setIsLoading,
  index,
  block,
  updateBlockData,
  handleChange,
  collapsed,
  isShared,
  updateBlocks,
}) => {
  const {
    userId,
    blockStyles,
    blocks,
    setBlocks,
    setActivityLog,
    runnerMode,
    runnerIndex,
    setRunnerIndex,
    setStillRunning,
    blockCancellationState,
    setBlockCancellationState,
    cancellingBlocksMemo,
    stillRunning,
  } = useContext(FuserContext);

  const authHeader = useAuthHeader()();

  const { pause, cancelPause } = useCancellablePause();

  useEffect(() => {
    setErrorMessage('');
  }, [runnerMode]);

  // const blocktypesWhichCancelPromptsOnEdit = ['info', 'question', 'prompt'];
  // const promptCancellationDependencies = useDeepCompareMemo(
  //   blocks
  //     .filter(
  //       (block: any, blockIndex: number) =>
  //         blockIndex !== index &&
  //         blocktypesWhichCancelPromptsOnEdit.includes(block.blocktype)
  //     )
  //     .map((block: any) => {
  //       if (block.blocktype === 'question') return block.data;
  //       if (block.blocktype === 'prompt') return block.data.response;
  //       const { response, ...dataExceptResponse } = block.data;
  //       return dataExceptResponse;
  //     })
  // );

  const [updatedOutput, setUpdatedOutput] = useState('');
  const [updateButtonClass, setUpdateButtonClass] = useState(
    'bg-slate-200 text-slate-500 hover:bg-slate-200'
  );
  const [updateButtonText, setUpdateButtonText] = useState('update');
  const [tidyResponse, setTidyResponse] = useState('');
  const [responseDescription, setResponseDescription] = useState('');

  const { credit, updateCredits } = useCredit();

  useEffect(() => {
    if (index === 1) {
      console.log('isloading at index', index, isLoading);
    }
  }, [isLoading]);

  useEffect(() => {
    if (cancellingBlocksMemo) onOtherBlockCancelRequest();
  }, [cancellingBlocksMemo]);

  useBlockRunner(onPromptSaveClick, index);

  useEffect(() => {
    if (isShared) return;
    const { cancellingBlocks, idsOfBlocksToCancel, idOfBlockToRunAfterwards } =
      blockCancellationState;

    if (cancellingBlocks) {
      const indexOfBlockToRunNext = blocks.findIndex(
        ({ id }: any) => id === idOfBlockToRunAfterwards
      );
      if (idsOfBlocksToCancel.length === 0 && index === indexOfBlockToRunNext) {
        // runs after all other blocks have cancelled
        console.log('restarting block', indexOfBlockToRunNext);
        setRunnerIndex(indexOfBlockToRunNext);

        if (!stillRunning) {
          onPromptSaveClick();
        }

        setBlockCancellationState({
          cancellingBlocks: false,
          idsOfBlocksToCancel: [],
          idOfBlockToRunAfterwards: undefined,
        });
      }
    }
  }, [blockCancellationState]);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const {
    response,
    blockHeading,
    inputToProcess,
    responseSchema,
    message,
    gptModel,
    showOutputToUser,
    editableOutput,
    temperature,
    topP,
    frequencyPenalty,
    presencePenalty,
    maximumWordsInResponse,
  } = block.data;

  useEffect(() => {
    let newTidyResponse: any = '';
    if (Array.isArray(response)) {
      newTidyResponse = response.join('\n----\n');
      setTidyResponse(newTidyResponse);
    } else setTidyResponse(response);

    //console.log(`block ${index} response:`, response);
    setResponseDescription(tidyUpResponse(response));
    //setResponseDescription(response);
    //console.log(response, tidyUpResponse(response));
  }, [response]);

  const [promptBatchData, setPromptBatchData] = useState({
    promptBatchNumber: 0,
    numberOfPromptBatches: 0,
  });

  const { promptBatchNumber, numberOfPromptBatches } = promptBatchData;

  const processedMessage = replacePlaceholders(message, blocks);

  if (isShared) {
    return (
      <>
        <div>
          {truncateAfter(MAX_PREVIEW_CHARS, blockHeading || 'GPT response')}
        </div>
        <TextareaAutosize
          className='resize-y grow min-h-[20vh] max-h-[50vh] shared-textarea bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner overflow-y-scroll w-full'
          value={response}
          readOnly={true}
        />
      </>
    );
  }

  if (collapsed) {
    if (errorMessage)
      return (
        <>
          <div>GPT Error</div>
          <div>{truncateAfter(MAX_PREVIEW_CHARS, errorMessage.toString())}</div>
        </>
      );
    if (response?.length > 0 && showOutputToUser)
      return (
        <>
          <div>
            {truncateAfter(
              MAX_PREVIEW_CHARS,
              message ? processedMessage : 'GPT response'
            )}
          </div>
          <div>{truncateAfter(MAX_PREVIEW_CHARS, response.toString())}</div>
        </>
      );
    else return <div>Prompt block</div>;
  }

  let responseSchemaValid;

  if (!runnerMode) {
    try {
      const replacedSchemas = ensureArray(
        replacePlaceholders(responseSchema, blocks)
      ).flat();
      for (const schema of replacedSchemas) {
        JSON.parse(schema);
      }
      responseSchemaValid = true;
    } catch {
      // possibly invalid JSON (may become valid after block reference replacement)
      if (!responseSchema) {
        responseSchemaValid = true;
      } else {
        responseSchemaValid = false;
      }
    }
  }
  // console.log({ model: block.data.gptModel });

  // console.log({ response });
  return (
    <FuserLoader
      name='Prompt Block'
      loading={isLoading}
      message={`${
        message ? processedMessage + ' ' : ''
      }(Prompt ${promptBatchNumber} of ${numberOfPromptBatches})`}
      onCancelClick={onCancelClick}
    >
      {runnerMode ? (
        response?.length > 0 || response === '' ? (
          <>
            <p>{processedMessage}</p>

            {showOutputToUser ? (
              <>
                <TextareaAutosize
                  className='resize-y max-h-96 bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner overflow-y-scroll w-full'
                  {...(editableOutput
                    ? {
                        value: response,
                        onChange: editsMade,
                      }
                    : {
                        value: response,
                        readOnly: true,
                      })}
                />
                <span className='flex items-center'>
                  <RestartPromptButton
                    onRestartPrompt={onRestartPrompt}
                    className={errorMessage ? 'avatar-glow' : ''}
                  />{' '}
                  &nbsp; &nbsp;
                  {editableOutput ? (
                    <button
                      className={testButtonStyles + ' ' + updateButtonClass}
                      onClick={handleOutputUpdate}
                    >
                      {updateButtonText}
                    </button>
                  ) : null}
                </span>
              </>
            ) : (
              <div>Prompt block</div>
            )}

            {errorMessage && <p>{errorMessage}</p>}
            {/*
              <button>
                EDIT
              </button>
            */}
          </>
        ) : errorMessage ? (
          <>
            <p>{errorMessage}</p>
            <RestartPromptButton
              onRestartPrompt={onRestartPrompt}
              className='animate-toms-glow'
            />
          </>
        ) : (
          <>
            <div>Prompt block</div>
            {runnerIndex === index && (
              <span className='flex items-center'>
                <RestartPromptButton onRestartPrompt={onRestartPrompt} /> &nbsp;
              </span>
            )}
          </>
        )
      ) : (
        <div
          className={blockStyles}
          key={index}
        >
          <>
            <label className='text-xs'>
              Prompt:{' '}
              <MyToolTips
                content="
                <p>This is where you write prompts and send them to whichever GPT model you choose.</p> 

                <p>We recommend adding <q>Optional Message for user</q> to improve the user experience. They won't be able to see your prompt so they will be waiting for it to process.</p>

                <p><q>Show response</q> lets the user see what is returned from GPT, in most cases it will be okay to let the user see this.
                You may want to let the user edit it too.</p>

                <p>GPT Models:
                <br />
                - Turbo 3.5 is the same model as the current free version of chatGPT, it is very cheap and it can process up to 12,000 words.
                <br />
                - GPT-4 is about 40x more expensive than Turbo. It is a text completion model so you can start the answer you want and it will complete it, as opposed to replying. It can be helpful if turbo is not producing the answer you wish.
                <br />
                </p>

                <p>Increasing <q>Temperature</q> will increase the randomness of your output. Information on the other settings are available in the openAI docs.</p>"
                tipID={index.toString()}
                datatooltipplace='below'
              />
            </label>

            <AutocompleteTextarea
              autosize={true}
              block={block}
              index={index}
              onChange={handleChange}
              textAreaIndex={0}
              className='w-full resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner h-36 overflow-y-scroll'
              name='inputToProcess'
              value={inputToProcess || ''}
              placeholder='Enter your prompt here'
            />

            {gptModel === 'gpt4o' && (
              <>
                <label className='text-xs'>
                  (Optional) JSON response schema:{' '}
                  <MyToolTips
                    content={`
                    <p>You can get structured outputs by specifying a JSON schema here</p>
                    <p>A JSON schema is the JSON string of an object with the following properties:</p>
                    <ul>
                      <li>  - One of "type", "enum", or "anyOf"</li>
                      <li>  - The value of "type" can be "object", "array", "string", "number", "boolean", or "integer"</li>
                      <li>  - The value of an "enum" / "anyOf" property must be an array of allowed responses, enum expects values and anyOf expects JSON schemas.</li>
                      <li>  - Arrays must also have an "items" property which is an array of JSON schemas</li>
                      <li>  - Objects must also have a "properties" property where each value is a JSON schema</li>
                      <li>  - Objects also need a boolean "additionalProperties" property, which determines whether additional properties not in the schema will be in the response</li>
                      <li>  - Objects finally need a "required" array property which specifies an array of the properties that are not optional in the response</li>
                    </ul>
                    <p>Every value which is not an array or object and every property must be wrapped in double quotes</p>
                    <p>You can also use block references in the schema</p>`}
                    tipID={index.toString()}
                    datatooltipplace='below'
                  />
                </label>
                {!responseSchemaValid && (
                  <p>
                    Warning: Response schema may not be valid JSON and could
                    cause an error if sent.
                  </p>
                )}
                <AutocompleteTextarea
                  autosize={true}
                  block={block}
                  index={index}
                  onChange={handleChange}
                  textAreaIndex={1}
                  className='w-full resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner h-36 overflow-y-scroll'
                  name='responseSchema'
                  value={responseSchema || ''}
                  placeholder='(Optional): Enter the JSON response schema here'
                />
              </>
            )}

            <div className='flex flex-col gap-2'>
              <GptModelSelect
                gptModel={gptModel}
                index={index}
                handleModelChange={handleModelChange}
              />

              <GptParameters
                gptModel={gptModel}
                temperature={temperature}
                topP={topP}
                frequencyPenalty={frequencyPenalty}
                presencePenalty={presencePenalty}
                maximumWordsInResponse={maximumWordsInResponse}
                handleChange={handleChange}
              />
            </div>

            <label className='text-xs'>Optional heading for user:</label>

            {/* old optional message input */}
            {/* <input
              className='resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner'
              type='text'
              name='message'
              value={message ?? ''}
              onChange={handleChange}
            /> */}

            <AutocompleteTextarea
              autosize={true}
              block={block}
              index={index}
              onChange={handleChange}
              textAreaIndex={2}
              className='w-full resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner'
              name='message'
              value={message ?? ''}
              placeholder='Heading for user'
            />

            <div className='flex gap-4 items-center'>
              <label className='flex gap-2 items-center'>
                <input
                  type='checkbox'
                  name='showOutputToUser'
                  checked={showOutputToUser ?? false}
                  onChange={handleCheckboxChange}
                />

                <p className='select-none'>Show response to user</p>
              </label>

              {showOutputToUser && (
                <label className='flex gap-2 items-center'>
                  <input
                    type='checkbox'
                    name='editableOutput'
                    checked={editableOutput ?? false}
                    onChange={handleEditableOutput}
                  />

                  <p className='select-none'>Let user edit the output</p>
                </label>
              )}
            </div>

            <span className='flex items-center justify-between w-full'>
              <button
                className={testButtonStyles}
                onClick={onPromptSaveClick}
              >
                TEST
              </button>

              {/* <button 
                className={testButtonStyles} 
                onClick={handleDownloadClick}
                >
                  Download
              </button>*/}
            </span>

            <div className='w-full'>
              {
                <>
                  <TextareaAutosize
                    className='resize-none max-h-96 bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner h-36 overflow-y-scroll w-full'
                    {...(editableOutput
                      ? {
                          value: tidyResponse,
                          onChange: editsMade,
                        }
                      : {
                          value: tidyResponse,
                          readOnly: true,
                        })}
                  />
                  <p> &nbsp; {responseDescription}</p>
                </>
              }
            </div>

            <p>{errorMessage}</p>
          </>
        </div>
      )}
    </FuserLoader>
  );

  async function gpt({
    userId = '',
    toolId,
    prompt = ['tell me that I forgot the prompt'],
    model = 'turbo',
    temperature = defaultInputValues.temperature,
    topP = defaultInputValues.topP,
    frequencyPenalty = defaultInputValues.frequencyPenalty,
    presencePenalty = defaultInputValues.presencePenalty,
    maximumWordsInResponse = defaultInputValues.maximumWordsInResponse['turbo'],
    responseSchema,
  }: gptParamsType) {
    prompt = ensureArray(prompt);
    const flatPrompt = flatten(prompt);

    const promptBatches: any[] = batchArray(MAX_PROMPT_BATCH_SIZE, flatPrompt);

    let responseSchemaBatches;
    if (responseSchema) {
      responseSchema = ensureArray(responseSchema);
      const flatResponseSchema = flatten(responseSchema);
      if (flatResponseSchema.length === flatPrompt.length) {
        responseSchemaBatches = batchArray(
          MAX_PROMPT_BATCH_SIZE,
          flatResponseSchema
        );
      } else if (flatResponseSchema.length === 1) {
        // use the same response schema for each prompt if there is only one schema
        responseSchemaBatches = promptBatches.map((batch: string[]) =>
          batch.map((prompt: string) => flatResponseSchema[0])
        );
      } else {
        console.log('Check dimensions:', { prompt, responseSchema });
        throw new Error('Response schema and prompt length mismatch');
      }
    }

    let response: any[] = [];

    const INITIAL_PAUSE_DURATION_IN_MS = randomNumberBetween(5000, 10000);

    let retriesRemaining = MAX_RETRIES;
    let retryWithOpenAi = false;

    for (
      let currentBatchIndex = 0, pauseDuration = INITIAL_PAUSE_DURATION_IN_MS;
      currentBatchIndex < promptBatches.length; // currentBatchIndex is updated in the block of code below

    ) {
      if (retriesRemaining === 0) {
        ReactGA.event('max_retries');
        throw new Error(
          `${MAX_RETRIES} consecutive failed requests from OpenAI, please try again later.`
        );
      }
      setPromptBatchData({
        promptBatchNumber: currentBatchIndex + 1,
        numberOfPromptBatches: promptBatches.length,
      });
      console.log(
        'prompt batch',
        currentBatchIndex + 1,
        'of',
        promptBatches.length,
        '. Retries remaining:',
        retriesRemaining
      );
      const promptBatch = promptBatches[currentBatchIndex];
      const responseSchemaBatch = responseSchemaBatches?.[currentBatchIndex];
      console.log('promptBatch', promptBatch);

      try {
        // fake response for testing
        // throw { response: { status: 400, statusText: 'AAAAAAA' } };

        const responseBatch: any = await axios.post(
          `${backendURL}/openai/prompt`,
          {
            userId,
            toolId,
            prompts: promptBatch,
            model,
            temperature,
            topP,
            frequencyPenalty,
            presencePenalty,
            maximumWordsInResponse,
            responseSchemas: responseSchema ? responseSchemaBatch : undefined,
            retryWithOpenAi,
          },
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );

        const { results, updatedCredit, message } = responseBatch.data;

        if (updatedCredit != null) {
          updateCredits(updatedCredit * 10);
        }

        if (message === 'cancelled') {
          return responseBatch;
        }

        if (message === 'timed-out') {
          console.log('response timed out, retrying');
          retriesRemaining--;
          continue;
        }

        // Handle the new results and updated credit balance as needed
        response = [...response, ...results];
        currentBatchIndex++;
        pauseDuration = INITIAL_PAUSE_DURATION_IN_MS;
        retriesRemaining = MAX_RETRIES;
        retryWithOpenAi = false;
      } catch (error) {
        // console.log(error);
        const code = error?.code;
        const status = error?.response?.status;
        const statusText =
          error?.response?.statusText || error?.response?.statusMessage;
        // console.log('gpt error:', error.response);

        const updatedCreditInDollars = error?.response?.data?.updatedCredit;
        if (updatedCreditInDollars != null) {
          updateCredits(updatedCreditInDollars * 10);
        }

        if (error?.response?.data?.retryWithOpenAi) {
          retryWithOpenAi = true;
          continue;
        }

        if (retryPromptCondition(status, statusText, code)) {
          sendReactGaRetryEvent(status, code);

          console.log('pausing for', pauseDuration, 'ms');
          await pause(pauseDuration);
          pauseDuration = Math.min(
            MAX_RESPONSE_WAIT_TIME_IN_SECONDS * 1000,
            pauseDuration * randomNumberBetween(1.5, 1.7)
          );
          console.log(
            'pauseDuration set to',
            pauseDuration,
            'ms on prompt',
            currentBatchIndex
          );
          retriesRemaining--;
          continue;
        } else {
          if (error?.response?.data?.code === 'content_policy_violation') {
            const modelProvider = gptModel === 'llama2' ? 'Meta' : 'OpenAI';
            throw new Error(
              `Your request was rejected as a result of ${modelProvider}'s safety system. Your prompt may contain text that is not allowed by ${modelProvider}'s safety system`
            );
          }
          await handleAxiosError(error, { toolId, runnerIndex, blocks });
        }
      }
    }
    const unflattenedResults = unflatten(response, prompt);
    return { unflattenedResults };
  }

  async function onPromptSaveClick() {
    // console.log(`running block ${index}`);
    setIsLoading(true); // start loading
    setErrorMessage('');
    const currentBlock = blocks[index];

    const promptText = currentBlock.data.inputToProcess;
    const responseSchema = currentBlock.data.responseSchema;
    const messageText = currentBlock.data.message;
    const processedInput = promptText
      ? replacePlaceholders(promptText, blocks)
      : '';

    const processedResponseSchema = responseSchema
      ? replacePlaceholders(responseSchema, blocks)
      : '';

    try {
      //For testing error handling
      //throw new Error(`Error: ${Math.random()}`);
      console.log('awaiting gpt');
      const gptResponse: any = await gpt({
        userId: userId ?? '',
        toolId: toolId ?? '',
        prompt: processedInput,
        model: block.data.gptModel,
        temperature: +block.data.temperature,
        topP: +block.data.topP,
        frequencyPenalty: +block.data.frequencyPenalty,
        presencePenalty: +block.data.presencePenalty,
        maximumWordsInResponse: +block.data.maximumWordsInResponse,
        responseSchema: processedResponseSchema,
      });

      // console.log(
      //   `Response from block ${index}:`,
      //   gptResponse,
      //   'sent: ',
      //   processedInput
      // );

      if (gptResponse?.data?.message === 'cancelled') {
        console.log('Cancelled prompt at block', index);
        return;
        //return 'CANCEL';
      }

      const promptResponses = gptResponse.unflattenedResults;

      console.log('Prompt response saving for block', index);

      setBlocks((blocks: Block[]) => {
        // Update the blocks state
        const newBlocks = [...blocks];
        newBlocks[index] = {
          ...newBlocks[index],
          updatedBlock: true,
          data: {
            ...newBlocks[index].data,
            inputToProcess: promptText,
            message: messageText,
            response: [], // reset the response
          },
        };

        for (let i = 0; i < promptResponses.length; i++) {
          newBlocks[index].data.response[i] = promptResponses[i]; //.trim(); // Add response directly to the response array
        }

        //console.log('Setting blocks to:', fnewBlocks); // writes this comment every time the blocks are rendered
        return newBlocks;
      }); // update the blocks
    } catch (error) {
      // console.log('Error generating prompt', error);
      const errorObj = error as any;
      setErrorMessage(
        (errorObj.message ?? 'Error with GPT response') +
          (runnerMode
            ? ' Click the restart button to try running this block again.'
            : '')
      );
      setStillRunning(false);
      return;
      //return 'CANCEL';
    } finally {
      console.log('setting isloading false for block', index);
      setIsLoading(false);
    }
    setActivityLog((prevLog: string[]) => [
      ...prevLog,
      `Saved prompt block at index: ${index}`,
    ]);
  }

  async function cancelPrompt() {
    if (isLoading) {
      console.log('cancelling prompt for block', index);
      // cancel the pause if the block is waiting for the next retry
      cancelPause();
      const cancelResponse = await axios.get(
        `${backendURL}/openai/cancelPrompt`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      console.log('cancelPrompt response:', cancelResponse);
      console.log('setting isloading false for block', index);
      setIsLoading(false);
    }
  }

  async function onCancelClick() {
    cancelPrompt();
    setStillRunning(false);
  }

  async function onOtherBlockCancelRequest() {
    const { idOfBlockToRunAfterwards } = blockCancellationState;

    if (block.id !== idOfBlockToRunAfterwards) {
      await cancelPrompt();
      console.log('removing block', index, 'from ids to cancel');
      setBlockCancellationState((previousState: any) => {
        return {
          ...previousState,
          idsOfBlocksToCancel: previousState.idsOfBlocksToCancel.filter(
            (id: string) => id !== block.id
          ),
        };
      });
    }
  }

  function onRestartPrompt() {
    const idsOfBlocksToCancel = blocks
      .filter((block: any, blockIndex: number) => {
        return block.blocktype === 'prompt' && blockIndex !== index;
      })
      .map((block: any) => block.id);

    setBlockCancellationState({
      cancellingBlocks: true,
      idsOfBlocksToCancel,
      idOfBlockToRunAfterwards: block.id,
    });
  }

  function handleCheckboxChange() {
    const { showOutputToUser } = block.data;

    if (showOutputToUser) {
      updateBlockData('showOutputToUser', false);
    } else {
      updateBlockData('showOutputToUser', true);
    }
  }

  function handleEditableOutput() {
    const { editableOutput } = block.data;
    if (editableOutput) {
      updateBlockData('editableOutput', false);
    } else {
      updateBlockData('editableOutput', true);
    }
  }

  function editsMade(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const { value } = e.target;
    if (value != response) {
      //setRunnerIndex(-Infinity);
      console.log('editsMade executing');
      //const [updateButtonClass,setUpdateButtonClass] = useState('bg-slate-200 text-slate-500 hover:bg-slate-200');
      setUpdateButtonClass(''); // make button usable again
      setUpdateButtonText('update');
      setUpdatedOutput(value);
      block.data.response = value;
      updateBlocks();
    }

    // set the button back to update
  }

  function handleOutputUpdate() {
    //console.log('running? before', stillRunning, runnerMode, runnerIndices);
    if (updatedOutput) block.data.response = updatedOutput;
    setUpdateButtonClass('bg-slate-200 text-slate-500 hover:bg-slate-200');
    setUpdateButtonText('updated!');
    //setStillRunning(true);
    setBlocks((blocks: Block[]) => updateAtIndexRun(index, block, blocks)); // restarts runner
    console.log('updated block at index', index);
    //updateBlocks();
    //setRunnerIndex(index + 1);
    //console.log('running?',stillRunning,runnerMode,runnerIndices,index,blocks[runnerIndices.previous].data.response?.length);
  }

  function handleModelChange(e: any) {
    const { value: newModel } = e.target;
    block.data.maximumWordsInResponse =
      defaultInputValues.maximumWordsInResponse[newModel];
    updateBlocks();
    handleChange(e);
  }
};

export default PromptBlock;
