import React, { FC } from 'react';
import twFocusClass from '../../utils/tailwind';

export interface PaginationProps {
  currentPage?: number;
  totalPages?: number;
  onPageChange?: (newPage: number) => void;
  className?: string;
}

const Pagination: FC<PaginationProps> = ({
  currentPage = 1,
  totalPages = 1,
  onPageChange,
  className = '',
}) => {
  const handlePageClick = (page: number) => (e: React.MouseEvent) => {
    e.preventDefault();
    onPageChange && onPageChange(page);
  };

  const renderPageNumber = (page: number) => {
    if (page === currentPage) {
      return (
        <span
          key={page}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
        >
          {page}
        </span>
      );
    }
    return (
      <a
        key={page}
        href='#'
        onClick={handlePageClick(page)}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
      >
        {page}
      </a>
    );
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {Array.from({ length: totalPages }, (_, index) =>
        renderPageNumber(index + 1)
      )}
    </nav>
  );
};

export default Pagination;
