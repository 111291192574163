import { createContext } from 'react';

interface PageGigContextProps {
  isOwner: any;
}

const PageGigContext = createContext<PageGigContextProps>({
  isOwner: null,
});

export default PageGigContext;
