import { createContext } from 'react';

interface HeaderContextProps {
  setReferAFriendModalOpen: any;
  menuBarVisible: any;
  setMenuBarVisible: any;
}

const HeaderContext = createContext<HeaderContextProps>({
  setReferAFriendModalOpen: null,
  menuBarVisible: null,
  setMenuBarVisible: null,
});

export default HeaderContext;
