import { backendURL } from "../constants/environmental";
import Block from "../models/Block";
import { excelRangeToSubarray, getArrayDimensions } from "./array";
import { numberToExcelColumn } from "./math";
import { convertHTMLtableToText } from "./string";
import axios, { AxiosResponse } from "axios";
import ReactGA from "react-ga4";

const { isInteger } = Number;

function isValidBlockNumber(input: any) {
  return isInteger(input) && input >= 0;
}

function pause(milliseconds: number) {
  return new Promise((resolve) =>
    setTimeout(() => resolve(true), milliseconds)
  );
}

function tidyUpResponse(response: any) {
  if (response === undefined || response == null || !Array.isArray(response)) { return ""; }
  // describe the object
  let totalObjects = response.length;
  let tempResponseDescription = "Response is " + response.length + " items ";
  if (Array.isArray(response[0])) {
    tempResponseDescription += "x " + response[0].length + " items ";
    totalObjects = totalObjects * response[0].length;
    if (Array.isArray(response[0][0])) {
      tempResponseDescription += "x " + response[0][0].length + " items ";
      totalObjects = totalObjects * response[0][0].length;
      if (Array.isArray(response[0][0][0])) {
        tempResponseDescription += "x " + response[0][0][0].length + " items ";
        totalObjects = totalObjects * response[0][0][0].length;
        if (Array.isArray(response[0][0][0][0])) {
          tempResponseDescription +=
            "x " + response[0][0][0][0].length + " items ";
          totalObjects = totalObjects * response[0][0][0][0].length;
        }
      }
    }
    tempResponseDescription += " = " + totalObjects.toString() + " items";
  }

  return tempResponseDescription;
}

// replaces all @blockName's with <indexOfBlockCalledblockName:output>
// (if a block has that name, otherwise leaves it as is)
function processAtSigns(input: string, blocks: any) {
  // console.log('process at signs input', input);

  // only allowing \w characters in block names
  return input
    ?.replace(
      /@Block(\d+)(?=\W|$)/g,
      (match, blockIndex) => `<${blockIndex}:output>`
    )
    ?.replace(/@(\w+)/g, (match, captureGroup1) => {
      const blockIndex = blocks
        .map(({ data: { name } }: any) => name)
        .indexOf(captureGroup1);
      if (blockIndex !== -1) {
        return `<${blockIndex}:output>`;
      } else {
        return match;
      }
    });
}

interface ProcessItem {
  matchedPlaceholder: string | null;
  block?: number;
  type?: string;
  placeholder: any;
  dimensions?: number;
  squareBracketsContent?: string;
}

function evaluatePlaceholder(promptText: string, blocks: Block[]): any {
  if (!Array.isArray(blocks)) {
    console.log("Blocks argument missing in evaluatePlaceholder");
  }
  promptText = processAtSigns(promptText, blocks);
  const toProcess: ProcessItem[] = [];
  const regex = /<(\d+):(output|input)>/g;
  let match: RegExpExecArray | null;

  while ((match = regex.exec(promptText)) !== null) {
    const temp_toPush: ProcessItem = {
      matchedPlaceholder: match[0],
      block: parseInt(match[1], 10),
      type: match[2],
      placeholder: null,
    };

    if (temp_toPush.type === "output") {
      temp_toPush.placeholder = blocks[temp_toPush.block!].data.response;
      // console.log(
      //   'temp_topush, response currently: ',
      //   blocks[temp_toPush.block!].data.response,
      //   blocks[temp_toPush.block!].data.response.length
      // );
    }
    if (temp_toPush.type === "input") {
      const { inputToProcess } = blocks[temp_toPush.block!].data;

      temp_toPush.placeholder = inputToProcess
        ? replacePlaceholders(inputToProcess, blocks)
        : "";
    }

    /*
      console.log("response should be in here:" , blocks[temp_toPush.block!])
      console.log("response should be in here:" , blocks)
      console.log("response should be in here:" , temp_toPush.block!)
      */

    temp_toPush.dimensions = getArrayDimensions(temp_toPush.placeholder);

    // console.log(temp_toPush)

    toProcess.push(temp_toPush);
  }

  if (toProcess.length == 0) {
    return promptText;
  }
  return toProcess[0].placeholder;

  /*
    if (toProcess.length == 0) return promptText;
    else {
      let result = promptText;
      for (const processItem of toProcess) {
        const { matchedPlaceholder, placeholder } = processItem;
        if (matchedPlaceholder !== null) {
          result.replace(matchedPlaceholder, placeholder);
        }
      }
      return result;
    }
    */
}

function replacePlaceholders(promptText: string, blocks: Block[]): any {
  if (!Array.isArray(blocks)) {
    console.log("Blocks argument missing in replacePlaceholders");
  }

  promptText = processAtSigns(promptText, blocks);
  const toProcess: ProcessItem[] = [];
  const regex = /<(\d+):(output|input)>(?:\[(.*?)\])?/g;
  let match: RegExpExecArray | null;

  //console.log('in replace placeholders');

  while ((match = regex.exec(promptText)) !== null) {
    const temp_toPush: ProcessItem = {
      matchedPlaceholder: match[0],
      block: parseInt(match[1], 10),
      type: match[2],
      placeholder: null,
    };

    const blockData = blocks?.[temp_toPush.block!]?.data;
    if (temp_toPush.type === "output") {
      temp_toPush.placeholder = blockData?.response ?? "";
      if (blockData.response == undefined || blockData.response.length == 0) {
        temp_toPush.placeholder = "";
        //console.log('temp_to-push empty', temp_toPush.placeholder);
      }
    }
    if (temp_toPush.type === "input") {
      const { inputToProcess } = blockData;

      temp_toPush.placeholder = inputToProcess
        ? replacePlaceholders(inputToProcess, blocks)
        : "";
      // console.log({
      //   data: blocks?.[temp_toPush.block!]?.data,
      // });
    }

    // console.log(
    //   'temp_toPush',
    //   temp_toPush.placeholder,
    //   blocks[temp_toPush.block!].data.response.length == 0
    // );

    /*
      console.log("response should be in here:" , blocks[temp_toPush.block!])
      console.log("response should be in here:" , blocks)
      console.log("response should be in here:" , temp_toPush.block!)
      */

    let squareBracketsContent = match[3];
    if (squareBracketsContent) {
      let matchHeadings = true;

      if (blockData.useColumnHeadingReferences) {
        matchHeadings = false;

        const columnHeadings = temp_toPush.placeholder[0].sort(
          (heading1: string, heading2: string) =>
            heading2.length - heading1.length
        );

        const firstMatchedHeadingIndex = columnHeadings.findIndex(
          (heading: string) => squareBracketsContent.includes(heading)
        );
        const firstMatchedHeading = columnHeadings[firstMatchedHeadingIndex];

        if (firstMatchedHeadingIndex !== -1) {
          squareBracketsContent = squareBracketsContent.replace(
            firstMatchedHeading,
            numberToExcelColumn(firstMatchedHeadingIndex + 1)
          );
          console.log(numberToExcelColumn(firstMatchedHeadingIndex + 1), {
            squareBracketsContent,
          });
        }

        const secondMatchedHeadingIndex = columnHeadings.findIndex(
          (heading: string) => squareBracketsContent.includes(heading)
        );
        const secondMatchedHeading = columnHeadings[secondMatchedHeadingIndex];

        if (secondMatchedHeadingIndex !== -1) {
          squareBracketsContent = squareBracketsContent.replace(
            secondMatchedHeading,
            numberToExcelColumn(secondMatchedHeadingIndex + 1)
          );
        }
      }

      temp_toPush.placeholder = excelRangeToSubarray({
        range: squareBracketsContent,
        array: temp_toPush.placeholder,
        matchHeadings,
      });
    }

    temp_toPush.dimensions = getArrayDimensions(temp_toPush.placeholder);

    //console.log(temp_toPush)

    toProcess.push(temp_toPush);
  }

  // The rest of the function remains the same...
  // ...

  if (toProcess.length == 0) return promptText;

  // console.log(toProcess);

  let currentOutput: ProcessItem = {
    matchedPlaceholder: "prompt",
    placeholder: promptText,
  };
  let highest_dimension = 0;

  function match_placeholders(a: ProcessItem, b: ProcessItem): any {
    if (b.dimensions! > highest_dimension) highest_dimension = b.dimensions!;

    if (!Array.isArray(a.placeholder)) {
      if (!Array.isArray(b.placeholder)) {
        //if (!b.matchedPlaceholder || !a.placeholder.includes(b.matchedPlaceholder)) return a;
        return a.placeholder.replace(b.matchedPlaceholder!, b.placeholder);
      } else {
        if (Array.isArray(b.placeholder[0])) {
          const reduced_dimensions: any[] = [];
          for (let i_b = 0; i_b < b.placeholder.length; i_b++) {
            reduced_dimensions.push(
              match_placeholders(a, {
                matchedPlaceholder: b.matchedPlaceholder,
                placeholder: b.placeholder[i_b],
              })
            );
          }
          return reduced_dimensions;
        } else {
          const b_return: any[] = [];
          for (let i_b = 0; i_b < b.placeholder.length; i_b++) {
            b_return.push(
              a.placeholder.replace(b.matchedPlaceholder!, b.placeholder[i_b])
            );
          }
          return b_return;
        }
      }
    } else {
      let reduced_dimensions: any[] = [];
      for (let i_a = 0; i_a < a.placeholder.length; i_a++) {
        if (a.placeholder.length == b.placeholder.length) {
          reduced_dimensions.push(
            match_placeholders(
              {
                matchedPlaceholder: a.matchedPlaceholder,
                placeholder: a.placeholder[i_a],
              },
              {
                matchedPlaceholder: b.matchedPlaceholder,
                placeholder: b.placeholder[i_a],
              }
            )
          );
        } else {
          reduced_dimensions.push(
            match_placeholders(
              {
                matchedPlaceholder: a.matchedPlaceholder,
                placeholder: a.placeholder[i_a],
              },
              b
            )
          );
        }
      }

      if (getArrayDimensions(reduced_dimensions) > highest_dimension) {
        reduced_dimensions = reduced_dimensions.flat();
      }

      return reduced_dimensions;
    }
  }

  for (let i_toProcess = 0; i_toProcess < toProcess.length; i_toProcess++) {
    // need to catch placeholders in current output and then send it back again

    // this one works
    // console.log(match_placeholders(currentOutput,toProcess[i_toProcess]));

    currentOutput = {
      matchedPlaceholder: null,
      placeholder: match_placeholders(currentOutput, toProcess[i_toProcess]),
    };

    //console.log("currentOutput up to :",currentOutput);
  }

  //console.log("final currentOutput:",currentOutput);

  return currentOutput.placeholder; // j
}

function handleAxiosError(error: any) {
  //console.log('Handling axios error');
  const axiosError = error as any;
  const response = axiosError?.response;
  const status = response?.status;
  const statusText = response?.statusText;
  const message = response?.data?.error?.message;

  //console.log('error object recieved:', errorObj);

  if (status === 400) {
    ReactGA.event("bad_openai_request");
    throw new Error(
      response.error ||
        "Error: Bad request to OpenAI API. Try reducing the prompt/input size."
    );
  } else if (status === 401) {
    ReactGA.event("unauthorized_openai_call");
    throw new Error(
      "Error: Unauthorized request. Please check that you are logged in."
    );
  } else if (status === 402) {
    throw new Error("Error: You have insufficient credit.");
  } else if (
    status === 429 &&
    statusText.startsWith("You exceeded your current quota")
  ) {
    ReactGA.event("monthly_openai_limit_reached");
    throw new Error("Error: Monthly limit reached");
  } else {
    ReactGA.event("express_error_" + status);

    //console.error('Error from Backend API:', axiosError, axiosError?.response?.data);
    throw new Error(
      `Error from Backend API: Status ${status} ${
        message ? ` - ${message}` : ""
      }`
    );
  }
}

async function generateImageFromDALLE({
  prompt,
  authHeader,
  userId,
  toolId,
  model = "dall-e-2",
}: {
  prompt: string;
  authHeader: string;
  userId: string;
  toolId: string;
  model?: string;
}) {
  const endpoint = `${backendURL}/openai/image`;
  const parameters = {
    prompts: [prompt],
    userId,
    toolId,
    model,
  };
  console.log("image toolID", toolId);
  try {
    const response: AxiosResponse = await axios.post(endpoint, parameters, {
      headers: {
        Authorization: authHeader,
      },
    });
    if (response.status === 204) return response;
    if (response.status === 200) {
      const imageUrl = response.data.imageUrls[0];
      // console.log('imageURL', imageUrl);
      // console.log(response.data.updatedCredit);
      return { imageURL: imageUrl, updatedCredit: response.data.updatedCredit };
    } else {
      console.error("Error from Backend API:", response.data);
      return `Error from Backend API: ${response.statusText}`;
    }
  } catch (error) {
    if (error?.response?.data?.code === "content_policy_violation")
      throw new Error(
        "Your request was rejected as a result of OpenAI's safety system. Your prompt may contain text that is not allowed by OpenAI's safety system"
      );
    handleAxiosError(error);
  }
}

async function fetchImageBlobFromBackend(url: string) {
  const encodedURL = encodeURIComponent(url);
  try {
    const response = await fetch(`${backendURL}/fetch-image/${encodedURL}`);
    const blob = await response.blob();
    if (response.ok) {
      return blob;
    } else {
      throw new Error("Error fetching image");
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function scrape(url: string) {
  // for web scraper

  const encodedURL = encodeURIComponent(url);

  let output = "";
  let html;

  try {
    const response = await fetch(`${backendURL}/fetch-data/${encodedURL}`);
    console.log(response);
    const jsonResponse = await response.json();
    if (response.ok) {
      html = jsonResponse;
    } else {
      const error = jsonResponse?.error;
      console.error("Error fetching URL. jsonRepsonse: ", jsonResponse);
      return `Error fetching URL: ${error ?? jsonResponse}`;
    }
  } catch (error) {
    console.log("Error in fetch request:", error);
    return error; //'An error occurred. Please try again.';
  }

  const match = html?.match(/<body[^>]*>([\s\S]*)<\/body>/);

  let content = match?.[1];

  if (typeof content !== "string") return "Invalid HTML content";

  content = content
    .replace(/<!--.*?-->/gs, "") // remove html comments
    .replace(
      /<(script|style|header|head|iframe|button|footer|svg|picture|nav|figure|aside|form|figcaption|blockquote|video|audio|canvas|comment|noscript|textarea|gu)( [^>]*?)?>.*?<\/\1>/gs,
      " "
    ) // remove irrelevant tags
    .replace(/<(img|meta|link|br|hr|input|base|embed|param)( [^>]*?)?>/g, "") // remove self closing tags
    .replace(/(<table[^>]*?>.*?<\/table>)/gms, convertHTMLtableToText);
  const noInnerHTMLRegex = /<([^\s>]*?)[^>]*?>\s*<\/\1>/g;
  while (noInnerHTMLRegex.test(content))
    content = content.replace(noInnerHTMLRegex, ""); // remove tags with no inner html

  const inlineTextRegex =
    /<p.*?<(a|b|em|span|strong|i|code|sup|sub|cite|q|abbr|time|var|del|ins|mark|small|u|s|bdi)( [^>]*?)?>(.*?)<\/\1>/gs;
  while (inlineTextRegex.test(content))
    content = content.replace(inlineTextRegex, "$3"); // turn inline text part of paragraphs into plain text

  content = content
    .replace(/<(a|span)( [^>]*?)?>.*?<\/\1>/gs, "") // remove all remaining a/span tags
    .replace(/(<(?:h[12345]|p|br|div|li)( [^>]*?)?>)/g, "\n\n") // add new line characters for readability
    .replace(/<[^>]+?>/g, " ") // remove any remaining html tags
    .replace(/\n\s*\n/g, "\n") // combine multiple new lines in to single new line
    .replace(/:/g, "-");

  output = content.trim();

  await pause(1000);

  return output;
}

export {
  pause,
  tidyUpResponse,
  processAtSigns,
  evaluatePlaceholder,
  replacePlaceholders,
  handleAxiosError,
  generateImageFromDALLE,
  fetchImageBlobFromBackend,
  scrape,
  isValidBlockNumber,
};
