import React, { FC, useState, useEffect } from 'react';
import ButtonPrimary from '../../shared/Button/ButtonPrimary';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import axios from 'axios';
import { backendURL } from '../../constants/environmental';
/*
todo: need to verify ID before showing them how many coupons they've had and the latest coupon code
maybe add the option on the page to create a coupon too
*/
export interface AccountPageProps {
  className?: string;
}

const PageOffers: FC<AccountPageProps> = ({ className = '' }) => {
  const user = useAuthUser();
  const userId = user()?.id;
  // const [isCreator, setIsCreator] = useState<Boolean>(false);
  const location = useLocation();
  const authHeader = useAuthHeader()();

  const [selectedOption, setSelectedOption] = useState('');

  const path = location.pathname;
  const pathParts = path.split('/');
  const lastSegment = pathParts.pop() || pathParts.pop(); // handle potential trailing slash
  let toolID = '';
  if (lastSegment !== '0' && lastSegment !== undefined) toolID = lastSegment;

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  // useEffect(() => {
  //   const fetchUserIsCreator = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${backendURL}/user/isCreator/${userId}`
  //       );
  //       const data = await response.data;
  //       setIsCreator(data.isCreator);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchUserIsCreator();
  // }, [userId]);

  // const handleButtonClick = () => {
  //   if (isCreator) {
  //     navigate(`/fuser`);
  //   } else {
  //     navigate(`/creator-form`);
  //   }
  // };

  // const buttonText = isCreator ? 'Create New Tool' : 'Register As Creator';

  // Pre-fill the form fields with user data
  const [displayName, setDisplayName] = useState<string>('');

  const [email, setEmail] = useState<string>('');
  const [bio, setBio] = useState<string>('');

  const [uploadedImage, setUploadedImage] = useState<string>('');
  const [coverImage, setCoverImage] = useState<string>('');
  const [fetchingUserDetails, setFetchingUserDetails] = useState(true);
  // const [imageUploadStatusMessage, setImageUploadStatusMessage] = useState('');
  // const [profileImagePrompt, setProfileImagePrompt] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [chosenTool, setChosenTool] = useState(<></>);
  const [currentCoupons, setCurrentCoupons] = useState([]);

  /*
  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageDestination = e.target.name;
    console.log('handling image change');
    if (e.target.files && e.target.files[0]) {
      setLoading(true);
      const file = e.target.files[0];
      const validFileTypes = ['png', 'jpeg', 'jpg'];
      if (
        !validFileTypes
          .map((type: string) => `image/${type}`)
          .includes(file.type)
      ) {
        setImageUploadStatusMessage(
          `Invalid file type, must be one of: ${validFileTypes.join(', ')}`
        );
        return setLoading(false);
      }
      if (file.size >= 1048576) {
        setImageUploadStatusMessage('Sorry, this image file too large');
        return setLoading(false);
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        if (reader.result) {
          try {
            // Convert to Data URL for Cloudinary upload
            const dataUrl = reader.result as string;
            // setImageUrl(dataUrl); // Update the image preview here
            if (imageDestination === 'profileImage') setUploadedImage(dataUrl);
            else if (imageDestination === 'coverImage') setCoverImage(dataUrl);
            else console.log('Invalid image destination:', imageDestination);
            setImageUploadStatusMessage(
              'Image successfully uploaded, please go to update profile to save changes'
            );
          } catch (e) {
            setImageUploadStatusMessage(
              'Sorry, there was an error uploading your image'
            );
          } finally {
            setLoading(false);
          }
        }
      };
      reader.readAsDataURL(file);
    }
    e.target.files = null;
  };

  // const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && e.target.files[0]) {
  //     console.log(e.target.files[0]);
  //     setUploadedImage('');
  //   }
  // };

  const handleCoverChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setCoverImage(e.target.value[0]);
    }
  };

  // We need a handle submit that will allow us to update the sanity user we just fetched
  */

  useEffect(() => {
    setLoading(true);
    try {
      const fetchTool = async () => {
        const response = await axios.get(
          `${backendURL}/blocks/public/${toolID}`,
          {
            headers: {
              authorization: authHeader,
              uid: `${toolID}`,
              userId: userId,
            },
          }
        );
        const toolDetailsDiv = (
          <div className='p-10 bg-slate-100'>
            <p>
              <img
                src={response.data.block.coverPhoto}
                className='sm:w-1/2 md-1/4'
              />
            </p>
            <h2 style={{ margin: '8px 0 2px 0' }}>
              {response.data.block.name}
            </h2>
            <p>Current Price: ${response.data.block.price}</p>
          </div>
        );

        setChosenTool(toolDetailsDiv);
      };

      fetchTool();

      const fetchUserDetails = async () => {
        try {
          const response = await axios.get(`${backendURL}/user/${userId}`, {
            headers: {
              Authorization: authHeader,
            },
          });
          //console.log('This is the user details ', response);
          // Set the user details in the state variables
          setDisplayName(response.data?.name ?? response.data.username);
          // data.username is the email address
          setEmail(response.data.username);
          setBio(response.data.description);
          // setWebsite(response.data.socialLinks.website);

          setUploadedImage(response.data.profileImage);
          setCoverImage(response.data.coverPhoto);
        } catch (err) {
          setError(
            'Error fetching user details, please try again in a few minutes'
          );
          console.log('Error fetching user details', err);
        } finally {
          setFetchingUserDetails(false);
          setLoading(false);
        }
      };

      fetchUserDetails();

      const getCoupons = async () => {
        const coupons_response = await axios.post(
          `${backendURL}/blocks/getSetCoupon`,
          {
            toolID: toolID,
            user: userId,
          },
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );

        setCurrentCoupons(coupons_response.data.block.coupons);
        //console.log("current coupons",coupons_response);
      };

      getCoupons();
    } catch (err) {
      console.log('There was an error fetching tool details', err);
    }
  }, []);

  //const generateProfileImage = () => {};

  useEffect(
    () => setStatusMessage(''),
    [displayName, email, bio, uploadedImage, coverImage]
  );

  // Handler for form submission
  const handleSubmit = async (event: React.FormEvent) => {
    //setImageUploadStatusMessage('');
    //setStatusMessage('Updating profile...');

    event.preventDefault();

    console.log('handling coupon request');
    //  here we need to grab all the credentials from the form below
    setLoading(true);
    // and submit them as an axios request to update the user within our mongo db

    const formData = {
      toolID: toolID,
      user: userId,
      coupon: selectedOption,
    };

    try {
      const response = await axios.post(
        `${backendURL}/blocks/getSetCoupon`,
        formData,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      console.log(response);
      setCurrentCoupons(response.data.block.coupons);

      setStatusMessage('new coupon ...');
    } catch (err: any) {
      const errorMessage =
        err?.response?.data?.message ??
        err?.response?.data?.error?.message ??
        err?.response?.data?.error;
      setStatusMessage(
        errorMessage ? `Error: ${errorMessage}` : 'Failed to update coupons'
      );
      setError(
        `Error updating coupon data please try again in a few minutes ${err}`
      );
      console.log('There was an error updating the info ', err);
    }

    setLoading(false);
  };

  function formatDate(date: Date): string {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    let daySuffix;
    switch (day) {
      case 1:
      case 21:
      case 31:
        daySuffix = 'st';
        break;
      case 2:
      case 22:
        daySuffix = 'nd';
        break;
      case 3:
      case 23:
        daySuffix = 'rd';
        break;
      default:
        daySuffix = 'th';
    }

    return `${monthNames[monthIndex]} ${day}${daySuffix} ${year}`;
  }

  /*

  There is a better version of this that uses the PayPalSection component

  const { createOrder, onApprove } = usePayPal(); // Use the custom hook

  const AttemptPaypalPurchase = async () => {
    try {
      const response = await createOrder([
        {
          id: 'YOUR_PRODUCT_ID',
          quantity: 'YOUR_PRODUCT_QUANTITY',
        },
      ]);

      // Call PayPal.Buttons with the createOrder and onApprove functions
      window.paypal.Buttons({
        createOrder: () => response.orderID,
        onApprove,
      }).render('#paypal-button-container');
    } catch (error) {
      console.error(error);
    }
  };

  const profileImageInputRef = useRef<HTMLInputElement>(null);
  const coverImageInputRef = useRef<HTMLInputElement>(null);

  const handleResetPasswordClick = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `${backendURL}/user/forgot-password`,
        {
          code: '',
          userId,
        }
      );
      if (response.status === 200) {
        navigate(`/reset-password/${userId}`, {
          state: {
            instructions:
              'You have been sent an email with a 12 digit code. Please enter the code here, or alternatively follow the instructions in the email.',
          },
        });
      }
    } catch (error) {
      console.error('Error sending password reset link', error);
      setStatusMessage('There was a problem sending your reset code');
    }
  };

  console.log('fetchingUserDetails', fetchingUserDetails);


  if (fetchingUserDetails)
    return (
      <div className='border-2 text-center h-48 flex items-center justify-center'>
        Loading details...
      </div>
    );
  else
  */
  return (
    <>
      <Helmet>
        <title>Make A Special Offer Coupon - To Help Promote Your Tool</title>
      </Helmet>

      <div className='container'>
        <div className='my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10'>
          {/* HEADING */}
          <div className=''>
            <h2 className='text-3xl sm:text-4xl font-semibold font-bold mb-4 text-purple-800 text-center'>
              Use A Special Offer Coupon <br />- To Help Promote Your Tool
            </h2>

            <span className='block mt-3 text-neutral-500 dark:text-neutral-400'>
              {/* You can set preferred display name, create your profile URL and
              manage other personal settings. */}
            </span>
          </div>

          <div className='w-full border-b-2 border-neutral-100 dark:border-neutral-700'>
            <p>
              <b>
                Grab a limited time discount code that you can share online to
                promote your tool.
              </b>
            </p>
            <br />
            {chosenTool}
            <br />
            <p>
              <b>Coupon Types:</b>
            </p>
            <br />
            <p>
              <b>Free</b> - this can be a good way to boost your purchase
              number, get reviews and feedback on your tool, and increase
              credibility. The more purchases your paid tool has the higher up
              it will appear on the Skillfusion Marketplace.
            </p>
            <br />
            <p>
              <b>Discount</b> - a limited time discount creates a real sense of
              urgency, encouraging people to buy now.
            </p>
            <br />
            <p>
              You have used{' '}
              <b>
                <span style={{ color: 'blueviolet' }}>
                  {currentCoupons.length} / 3
                </span>
              </b>{' '}
              available coupons this month
            </p>
            <br />
            <p>
              <b>Coupon Options:</b>
            </p>
            <br />
            <form onSubmit={handleSubmit}>
              <fieldset>
                <p>
                  <input
                    type='radio'
                    name='coupon-option'
                    value='100-free'
                    style={{ marginBottom: '2px' }}
                    onChange={handleOptionChange}
                  />{' '}
                  100 free signups - valid for 30 days{' '}
                </p>
                <p>
                  <input
                    type='radio'
                    name='coupon-option'
                    value='1000-free'
                    style={{ marginBottom: '2px' }}
                    onChange={handleOptionChange}
                  />{' '}
                  1,000 free signups - valid for 5 days{' '}
                </p>
                <p>
                  <input
                    type='radio'
                    name='coupon-option'
                    value='25-off'
                    style={{ marginBottom: '2px' }}
                    onChange={handleOptionChange}
                  />{' '}
                  25% off - valid for 30 days - 1,000 users max
                </p>
                <p>
                  <input
                    type='radio'
                    name='coupon-option'
                    value='50-off'
                    style={{ marginBottom: '2px' }}
                    onChange={handleOptionChange}
                  />{' '}
                  50% off - valid for 7 days - 300 users max
                </p>
                <p>
                  <input
                    type='radio'
                    name='coupon-option'
                    value='75-off'
                    style={{ marginBottom: '2px' }}
                    onChange={handleOptionChange}
                  />{' '}
                  75% off - valid for 5 days - 100 users max
                </p>
                <br />
                <ButtonPrimary
                  type='submit'
                  className='w-full sm:w-1/2 mt-2'
                >
                  Create Coupon Code
                </ButtonPrimary>
              </fieldset>
            </form>
            <br />
            <br />
            <div className='border border-black p-8 m-8'>
              <h2>Current Coupons:</h2>
              <br />
              {currentCoupons.map((coupon: any, index) => (
                <p key={index}>
                  Coupon {index + 1} / {currentCoupons.length}
                  <br />
                  Code: {coupon.code}
                  <br />
                  Start: {formatDate(new Date(coupon.start))}
                  <br />
                  End: {formatDate(new Date(coupon.end))}
                  <br />
                  Discount: {100 * (1 - coupon.discount)}%<br />
                  Uses: {coupon.used} / {coupon.remaining}
                  <br />
                  <br />
                  <br />
                </p>
              ))}
            </div>
            {/* // direct link with code? */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PageOffers;
