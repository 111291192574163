import React, { createContext, useState, useContext } from 'react';

export const CreditContext = createContext();

export function useCredit() {
  return useContext(CreditContext);
}

export function CreditProvider({ children }) {
  // Initialize with 0 or fetch from API
  const [credit, setCredit] = useState(undefined);

  const updateCredits = newCreditValue => {
    setCredit(newCreditValue);
  };

  const value = {
    credit,
    setCredit,
    updateCredits,
  };

  return (
    <CreditContext.Provider value={value}>{children}</CreditContext.Provider>
  );
}
