import React, { FC, useContext, useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { nftsImgs } from '../constants/fakeData';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import PageGigContext from '../context/PageGigContext';
import LinkToProfile from './LinkToProfile';
import { timestampToLocalDate } from '../utils/date';
import axios from 'axios';
import ReplyForm from './ReplyForm';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { backendURL } from '../constants/environmental';
import { updateAtIndex } from '../utils/array';
import ReactTextareaAutosize from 'react-textarea-autosize';

const ReviewsDisplay: FC<any> = ({ toolId }) => {
  const { isOwner } = useContext(PageGigContext);

  const authHeader = useAuthHeader()();
  const user = useAuthUser()();
  const userId = user?.id;

  const [replyFormReviewId, setReplyFormReviewId] = useState(false);

  const loadReviews = async () => {
    try {
      const response: any = await axios.get(
        `${backendURL}/blocks/${toolId}/reviews`
      );

      const reviews = response?.data?.reviewsData;

      if (reviews) setReviews(reviews);
      else throw new Error('Malformed response');
      // console.log('reviews:', reviews);
    } catch (error) {
      console.error('Error fetching block reviews: ', error);
    }
  };

  useEffect(() => {
    loadReviews();
  }, []);

  const [reviews, setReviews] = useState<any[] | undefined>();

  // console.log(reviews);
  // Calculate total stars and average stars
  const totalStars = reviews?.reduce(
    (total: number, review: { rating: number }) => total + review.rating,
    0
  );

  const averageStars = reviews?.length
    ? totalStars / reviews.length
    : undefined;

  const renderStars = (number: number) => {
    const stars: any[] = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        i <= number ? (
          <AiFillStar
            key={i}
            className='text-yellow-500 inline'
          />
        ) : (
          <AiOutlineStar
            key={i}
            className='text-gray-500 inline'
          />
        )
      );
    }
    return stars;
  };

  const createReply = (reviewId: string) => (replyText: string) => async () => {
    try {
      const response: any = await axios.post(
        `${backendURL}/blocks/review/${reviewId}/reply`,
        {
          toolId,
          replyText,
        },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      if (response) {
        //console.log('response:', response);
        setReviews((reviews: any) => {
          const indexOfOldReview = reviews.findIndex(
            ({ _id }: any) => _id === reviewId
          );
          return updateAtIndex(indexOfOldReview, response.data.review, reviews);
        });
        setReplyFormReviewId(false);
      } else throw new Error('Malformed response');
    } catch (error) {
      console.error('Error adding reply: ', error);
    }
  };

  const handleDeleteReview = async (reviewId: string) => {
    try {
      const response = await axios.delete(
        `${backendURL}/blocks/review/${reviewId}`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      //console.log('Delete successful', response.data);
      setReviews((reviews: any) =>
        reviews.filter((review: any) => review._id !== reviewId)
      );
    } catch (err) {
      console.log('There was an error deleting your review', err);
      //setStatusMessage('There was an error deleting your review');
    }
  };

  const handleDeleteReviewReply = (reviewId: string) => async () => {
    try {
      const response = await axios.delete(
        `${backendURL}/blocks/review/reply/${reviewId}`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      //console.log('Delete successful', response.data);

      setReviews((reviews: any) => {
        const indexOfReviewToUpdate = reviews.findIndex(
          ({ _id }: any) => _id === reviewId
        );
        return updateAtIndex(
          indexOfReviewToUpdate,
          response.data.review,
          reviews
        );
      });
    } catch (err) {
      console.log('There was an error deleting your reply', err);
    }
  };

  return (
    <div className='pt-9'>
      <h2>Reviews:</h2>
      {reviews === undefined ? (
        <p>Loading reviews...</p>
      ) : reviews.length === 0 ? (
        <p>No reviews yet</p>
      ) : (
        <>
          <div className='flex gap-2 text-xs'>
            <p>Average Stars: {averageStars?.toFixed(1)}</p>
            <p>
              ({reviews.length} Rating{reviews.length !== 1 ? 's' : ''})
            </p>
          </div>

          {reviews.map((review: any, index: number) => (
            <div
              key={index}
              className='bg-white shadow-md rounded px-8 py-6 flex flex-col gap-4 my-2 border-slate-300 border-2 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200'
            >
              <div className='flex justify-between items-start'>
                <div className='flex items-start w-full'>
                  <div className='flex flex-col items-center gap-2 shrink-0'>
                    <img
                      src={review?.author?.profileImage || nftsImgs[3]}
                      alt={review?.author?.name}
                      className='w-12 h-12 object-cover rounded-full'
                    />

                    {(userId === review?.author?._id ||
                      user?.loggedin === 'false') && (
                      <button onClick={() => handleDeleteReview(review._id)}>
                        <MdDelete
                          className='text-red-500 hover:text-red-700'
                          size={24}
                        />
                      </button>
                    )}
                  </div>

                  <div className='ml-4 flex flex-col gap-4 w-full overflow-x-hidden'>
                    <h3 className='font-bold'>{review?.title}</h3>

                    <ReactTextareaAutosize
                      className='text-sm overflow-x-auto resize-none border-0 p-0'
                      value={`Answer: ${review?.text}`}
                      readOnly
                    />
                    <div>
                      <h3 className='text-sm font-medium'>Author: </h3>
                      <LinkToProfile user={review?.author} />
                    </div>

                    <p className='text-sm'>
                      Date posted: {timestampToLocalDate(review?.timestamp)}
                    </p>

                    {!review?.replyAuthor &&
                      (isOwner || user?.loggedin === 'false') &&
                      (replyFormReviewId === review?._id ? (
                        <ReplyForm
                          setReplyFormVisible={setReplyFormReviewId}
                          createReply={createReply(review?._id)}
                        />
                      ) : (
                        <button
                          onClick={() => setReplyFormReviewId(review?._id)}
                        >
                          Reply
                        </button>
                      ))}
                  </div>
                </div>

                <div className='flex flex-col ml-4'>
                  {renderStars(review?.rating)}
                </div>
              </div>

              {/* if no user exists with the replyAuthor _id then replyAuthor will be null */}
              {review?.replyAuthor && (
                <>
                  <hr />
                  <div className='flex w-full justify-end items-start gap-4 overflow-x-hidden'>
                    {/* <div className='flex items-start gap-4'> */}
                    <div className='ml-4 flex flex-col gap-4 w-full overflow-x-hidden'>
                      <ReactTextareaAutosize
                        className='text-sm overflow-x-auto resize-none border-0 p-0'
                        value={`Answer: ${review?.replyText}`}
                        readOnly
                      />

                      <h3 className='text-sm'>
                        <p className='text-sm font-medium'>Author:</p>
                        <LinkToProfile user={review?.replyAuthor} />
                      </h3>
                      {/* <p className='text-sm'>
                Date posted:{' '}
                {timestampToLocalDate(questionAndAnswer?.replyTimestamp)}
              </p> */}
                    </div>

                    <div className='flex flex-col items-center gap-2 shrink-0'>
                      <img
                        src={review?.replyAuthor?.profileImage || nftsImgs[3]}
                        alt={review?.replyAuthor?.name}
                        className='w-12 h-12 object-cover rounded-full'
                      />
                      {(userId === review?.replyAuthor?._id ||
                        user?.loggedin === 'false') && (
                        <button onClick={handleDeleteReviewReply(review?._id)}>
                          <MdDelete
                            className='text-red-500 hover:text-red-700'
                            size={24}
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ReviewsDisplay;
