const unconvertedCurrencies = ['GBP', 'AUD', 'USD', 'EUR'];

const supportedCurrencyCodeNamePairs = [
  ['AUD', 'Australian dollar'],
  ['BRL', 'Brazilian real'],
  ['CAD', 'Canadian dollar'],
  ['CNY', 'Chinese Renmenbi'],
  ['CZK', 'Czech koruna'],
  ['DKK', 'Danish krone'],
  ['EUR', 'Euro'],
  ['HKD', 'Hong Kong dollar'],
  ['HUF', 'Hungarian forint'],
  ['ILS', 'Israeli new shekel'],
  ['INR', 'Indian Rupee'],
  ['JPY', 'Japanese yen'],
  ['MYR', 'Malaysian ringgit'],
  ['MXN', 'Mexican peso'],
  ['TWD', 'New Taiwan dollar'],
  ['NZD', 'New Zealand dollar'],
  ['NOK', 'Norwegian krone'],
  ['PHP', 'Philippine peso'],
  ['PLN', 'Polish złoty'],
  ['GBP', 'Pound sterling'],
  ['RUB', 'Russian ruble'],
  ['SGD', 'Singapore dollar'],
  ['SEK', 'Swedish krona'],
  ['CHF', 'Swiss franc'],
  ['THB', 'Thai baht'],
  ['USD', 'United States dollar'],
];

// If it needs to be more accurate then backend code could be added to find
// the location of the client's IP address
const languageToCurrency: any = {
  'en-US': 'USD',
  'en-GB': 'GBP',
  'en-CA': 'CAD',
  'en-AU': 'AUD',
  'fr-FR': 'EUR',
  'de-DE': 'EUR',
  'es-ES': 'EUR',
  'zh-CN': 'CNY',
  'ja-JP': 'JPY',
  'ru-RU': 'RUB',
  'pt-BR': 'BRL',
  'da-DK': 'DKK',
  'en-NZ': 'NZD',
  'en-SG': 'SGD',
  'zh-HK': 'HKD',
  'en-PH': 'PHP',
  'en-MY': 'MYR',
  'sv-SE': 'SEK',
  'nb-NO': 'NOK',
  'nl-NL': 'EUR',
  'fr-CA': 'CAD',
  'en-IE': 'EUR',
  'pl-PL': 'PLN',
  'th-TH': 'THB',
  'zh-TW': 'TWD',
  'hu-HU': 'HUF',
  'he-IL': 'ILS',
  'cs-CZ': 'CZK',
  'es-MX': 'MXN',
  'de-CH': 'CHF',
  'fr-CH': 'CHF',
  'it-IT': 'EUR',
};

const currenciesThatDontSupportDecimals = ['HUF', 'JPY', 'TWD'];

export {
  unconvertedCurrencies,
  supportedCurrencyCodeNamePairs,
  languageToCurrency,
  currenciesThatDontSupportDecimals,
};
