export const buildCategoryTree = (categories: any[]) => {
  const categoryNodes = categories.map((category: any) => ({
    ...category,
    children: [],
  }));
  const categoryTree: any[] = [];
  for (const categoryNode of categoryNodes) {
    const parentNodeId = categoryNode.parentCategory;
    if (parentNodeId)
      categoryNodes
        .find(({ _id }: any) => _id === parentNodeId)
        .children.push(categoryNode);
    else categoryTree.push(categoryNode);
  }

  const sortTree = (nodes: any) => {
    nodes.sort(({ index: index1 }: any, { index: index2 }: any) => {
      return index1 - index2;
    });
    for (const node of nodes) {
      sortTree(node.children);
    }
  };

  sortTree(categoryTree);

  return categoryTree;
};
