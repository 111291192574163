import { Tooltip } from 'react-tooltip';
import React from 'react';

const LikeButton: React.FC<any> = ({
  id,
  className,
  liked,
  usersWhoLiked,
  handleLikeClick,
}) => {
  const numberOfLikes = usersWhoLiked.length;
  const maxUsersToShow = 5;
  return (
    <button
      className={`bg-black/50 px-3.5 h-10 flex gap-2 items-center justify-center rounded-full text-white ${className}`}
      onClick={handleLikeClick}
    >
      <svg
        className='w-5 h-5'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          d='M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z'
          stroke='currentColor'
          fill={liked ? '#ef4444' : 'none'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
      {numberOfLikes > 0 ? (
        <span style={{ zIndex: 1 }}>
          {/* <style>{`
        #MyToolTips p {
          margin: 10px;
          z-index:1;
        }
      `}</style> */}
          <Tooltip
            id={id}
            style={{ maxWidth: '50%', zIndex: 99 }}
          />
          <span
            data-tooltip-html={`<div class="text-base" id="MyToolTips">${[
              'Liked by:',
              ...usersWhoLiked
                .slice(0, maxUsersToShow)
                .map(({ name }: any) => name),
              numberOfLikes > maxUsersToShow
                ? `and ${numberOfLikes - maxUsersToShow} more`
                : '',
            ]
              .map(line => `<p>${line}</p>`)
              .join('')}</div>`}
            data-tooltip-place={'right'}
            data-tooltip-id={id}
            className='w-fit'
          >
            {numberOfLikes}
          </span>
        </span>
      ) : (
        <span>{numberOfLikes}</span>
      )}
    </button>
  );
};

export default LikeButton;
