import creditsImg from 'images/100credits.jpeg';
import creditsImg2 from 'images/500credits.jpeg';

import React, { FC } from 'react';
import SectionFounder from './SectionFounder';
import SectionStatistic from './SectionStatistic';
import { Helmet } from 'react-helmet';
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from '../../components/BgGlassmorphism/BgGlassmorphism';
import BackgroundSection from '../../components/BackgroundSection/BackgroundSection';
import SectionHero from './SectionHero';
import SectionClientSay from '../../components/SectionClientSay/SectionClientSay';

export interface PageAboutProps {
  className?: string;
}

const CreditHelp: FC<PageAboutProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id='PageAbout'
    >
      <Helmet>
        <title>About || Skillfusion.ai</title>
        <link
          rel='canonical'
          href='https://skillfusion.ai/credit-help'
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>
        <SectionHero
          rightImg={creditsImg}
          heading='How Do Credits Work?'
          btnText=''
          leftText={true}
          subHeading="
            
<p>You use credits whenever a tool performs an action that Skillfusion has to pay for:</p>
<p><br/>
- prompts to the GPT API <br/>
- AI image generation <br/>
- Embedding and searching text <br/>
- Google Search API<br/>
</p>
<br/>
<p>You also use credit for buying paid tools. </p>
<br/>
<p>Skillfusion pays a commission to the user that made the tool.  </p>
<br/>
<h2><b>How To Buy Credits</b></h2>
<br/>
<p>Top up your credits to help support skillfusion</p>
<br/>
<p>To top up your credits go to the credits menu and choose 'Buy Credits'.  </p>
<br/>
<h2><b>Payment Methods</b></h2>
<br/>
<p>We use Paypal to handle credit purchases. Your payment details are never stored by Skillfusion.</p>
<br/>
<p>Paypal also give you an option to pay by card if you don't have a Paypal account. </p>
<br/>
<h2><b>What Is A Credit Worth?</b></h2>
<br/>
<p>The API costs are quite cheap so your credits should last a while depending on which tool you use. </p>
<br/>
<p>We add a mark-up to help cover our costs. 100 credits should get you close to 1,000,000 words of output from tools that use GPT-3.5. Depending on the length of the prompts that the tool is using.  </p>
<br/>
<p>The fiction story writers tend to use a lot more credits than other tools, as they use the larger 12,000 word model. Also a summary of the story so far has to be fed back with each prompt.</p>
 
          "
        />
      </div>

      <div
        className={`nc-PageAbout overflow-hidden relative ${className}`}
        data-nc-id='PageAbout'
      >
        <Helmet>
          <title>About || Skillfusion.ai</title>
        </Helmet>

        {/* ======== BG GLASS ======== */}
        <BgGlassmorphism />

        <div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>
          <SectionHero
            rightImg={creditsImg2}
            heading=''
            btnText=''
            leftText={false}
            subHeading="
 
<h2><b>Expiration Date</b></h2>
<br/>
<p>There is currently no expiry date on credits.</p>
<br/>
<h2><b>Credit Usage</b></h2>
<br/>
<p>AI technology, whilst revolutionary,  is still new and not 100% reliable yet. We sometimes get errors back from openai. Simply pressing the re-spin on the current block may work in most cases.</p>
<br/>
<p>While we strive for a seamless experience, occasional credit consumption due to errors or bugs is inevitable. We seek your understanding as we constantly work to improve our software.</p>
<br/>
<p>You may use up some of your credits due to these errors from the APIs or bugs on the site. This is to be expected and we can't refund credits every time this happens. It should only make up a small percentage of your credit usage.</p>


 
          "
          />
        </div>
      </div>
    </div>
  );
};

export default CreditHelp;
