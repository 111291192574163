import React, { FC, useContext, useEffect } from "react";
import { FiArrowRightCircle } from "react-icons/fi";
import FuserContext from "../../context/FuserContext";
import Block from "../../models/Block";
import { prettyPrintMultidimensionalStringArray } from "../../utils/array";
import { truncateAfter } from "../../utils/string";
import { replacePlaceholders } from "../../utils/fuser";

const BlockReferenceInputSection: FC<{}> = () => {
  const {
    blocks,
    setBlocks,
    focusedTextarea,
    cursorPositionRef,
    selectedTextareaId,
    selectedBlockId,
    selectedBlockReference,
    setSelectedBlockReference,
    setFocusedTextArea,
  } = useContext(FuserContext);

  useEffect(() => {
    const selectedBlockIndex = +selectedBlockReference[0];
    const largestBlockIndex = blocks.length - 1;
    if (selectedBlockIndex >= largestBlockIndex) {
      setSelectedBlockReference("0:input");
    }
  }, [blocks]);

  const handleSelectReference = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedBlockReference(event.target.value);
  };

  const selectedBlockIndex = +selectedBlockReference.split(":")[0];
  const selectedBlockContentType = selectedBlockReference.split(":")[1];
  const selectedBlockData = blocks?.[selectedBlockIndex]?.data;

  let blockReferenceContent = "";
  if (selectedBlockReference && selectedBlockData) {
    if (selectedBlockContentType === "input") {
      const { inputToProcess } = selectedBlockData;
      if (inputToProcess) {
        try {
          blockReferenceContent = replacePlaceholders(inputToProcess, blocks);
        } catch {
          console.log(
            "Bad block reference used in block",
            selectedBlockIndex,
            ":",
            inputToProcess
          );
        }
      }
    } else {
      blockReferenceContent = selectedBlockData.response;
    }
  }

  const blockReferenceText = Array.isArray(blockReferenceContent)
    ? prettyPrintMultidimensionalStringArray(blockReferenceContent)
    : blockReferenceContent;

  const previewText =
    blockReferenceText && `Preview: ${truncateAfter(100, blockReferenceText)}`;

  return (
    <div className="w-full gap-2 bg-blue-200 dark:bg-neutral-800 p-2 flex flex-col items-center justify-between rounded-lg shadow-xl">
      <div className="flex flex-col lg:flex-row w-full gap-2">
        <p>Insert Block References</p>
        <select
          onChange={handleSelectReference}
          className="w-full lg:w-3/4 rounded-lg text-xs dark:bg-neutral-900 dark:text-neutral-200 "
          id="block-reference"
        >
          {blocks.slice(0, -1)?.map((block: Block, index: number) => (
            <React.Fragment key={index}>
              <option value={`${index}:input`}>{`Block ${index} input`}</option>

              <option
                value={`${index}:output`}
              >{`Block ${index} output`}</option>
            </React.Fragment>
          ))}
        </select>

        <button
          onClick={handleInsertReference}
          className="w-full lg:w-1/4 bg-blue-500 hover:bg-blue-700 text-white font-thin py-2 px-4 rounded-xl flex items-center justify-center text-xs"
          data-tip
          data-for="insertReferenceTooltip"
        >
          <FiArrowRightCircle />
        </button>
      </div>

      {previewText && <p className="text-xs self-start">{previewText}</p>}

      {/*<span className="text-xs bg-neutral-100 rounded-lg p-2 w-full flex flex-col dark:bg-neutral-900 dark:text-neutral-200">
        Focused Textarea: {selectedBlockId ? selectedBlockId.split(':')[0] : 'None'}
          </span>*/}
    </div>
  );

  function handleInsertReference() {
    const currentBlockIndex = blocks.findIndex(
      (block: any) => block.id === selectedBlockId
    );

    if (currentBlockIndex === -1) {
      console.error(`Block with ID ${selectedBlockId} not found`);
      return;
    }

    if (currentBlockIndex === null || !focusedTextarea.current) {
      alert("Please click on a textarea to edit before inserting a reference.");
      return;
    }

    const reference = `<${selectedBlockReference}>`; // Add pointy brackets around the selectedBlockId
    //console.log(reference);
    const cursorPosition = focusedTextarea.current.selectionStart || 0;

    const currentTextareaValue = focusedTextarea.current.value;

    // Create a new string with the reference inserted at the cursor position
    const updatedValue = `${currentTextareaValue.slice(
      0,
      cursorPosition
    )}${reference}${currentTextareaValue.slice(cursorPosition)}`;

    focusedTextarea.current.value = updatedValue;

    cursorPositionRef.current = cursorPosition + reference.length;

    setBlocks((blocks: Block[]) => {
      // Add the updated value to the blocks state
      const newBlocks = [...blocks];
      newBlocks[currentBlockIndex] = {
        ...newBlocks[currentBlockIndex],
        data: {
          ...newBlocks[currentBlockIndex].data,
          [focusedTextarea!.current!.name]: updatedValue,
        },
      };
      return newBlocks;
    });

    console.log(selectedBlockId, selectedTextareaId);
    setFocusedTextArea(selectedBlockId, selectedTextareaId);
  }
};

export default BlockReferenceInputSection;
