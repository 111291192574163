import React, { useContext } from 'react';
import FuserContext from '../../context/FuserContext';
import { useAuthUser } from 'react-auth-kit';

function ReportIssueButton() {
  const { runnerIndex, setShowReportIssueDialog, saveRunnerModeBlockData } =
    useContext(FuserContext);

  const user = useAuthUser()();

  return (
    <button
      className={
        'border border-gray-300 border-2 p-1 px-2 rounded-lg bg-white hover:bg-blue-100'
      }
      onClick={() => {
        if (user?.trialAccount) {
          return alert('Please register your account to report an issue.');
        }
        if (Number.isInteger(runnerIndex)) {
          saveRunnerModeBlockData(runnerIndex);
        }
        setShowReportIssueDialog(true);
      }}
    >
      Report Issue
    </button>
  );
}

export default ReportIssueButton;
