import React, { useState } from 'react';
import missingpic from '../images/missing.jpg';

const ImageWithFallback = ({
  src,
  fallbackSrc = missingpic,
  alt = '',
  height,
  containerClassName,
  className,
  inContainer = true,
}: {
  src: string;
  fallbackSrc?: string;
  alt?: string;
  height?: number;
  containerClassName?: string;
  className?: string;
  inContainer?: boolean;
}) => {
  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);

  const imgElement = (
    <img
      className={className ?? 'object-contain h-full'}
      src={imageFailedToLoad ? fallbackSrc : src}
      alt={alt}
      onError={() => setImageFailedToLoad(true)}
    />
  );

  return inContainer ? (
    <div
      className={
        containerClassName ?? `h-${height} flex items-center justify-center`
      }
    >
      {imgElement}
    </div>
  ) : (
    imgElement
  );
};

export default ImageWithFallback;
