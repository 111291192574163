import React, { FC, useState, useRef, useContext } from "react";
import axios from "axios";
import Input from "../../shared/Input/Input";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import FuserLoader from "./FuserLoader2";
import { useCredit } from "../../context/CreditContext";
import uploadImage from "../../images/uploadbig.png";
import FuserContext from "../../context/FuserContext";
import { backendURL } from "../../constants/environmental";
import { generateImageFromDALLE } from "../../utils/fuser";

const ImageGenerator: FC<{}> = () => {
  const {
    userId,
    toolId,
    toolMetadata: { coverPhoto },
    updateToolMetadata,
  } = useContext(FuserContext);

  const { credit, updateCredits } = useCredit();
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(`${coverPhoto}`);
  const [generatedImage, setGeneratedImage] = useState<string | null>(null);
  const [statusMessage, setStatusMessage] = useState<string>("");

  const fileInputRef = useRef<HTMLInputElement>(null);
  // const auth = useAuthUser();
  const authHeader = useAuthHeader()();

  //console.log("cover photo : ", coverPhoto)

  const checkToolIsSaved = () => {
    if (toolId === "") {
      alert("Please save tool first");
      return false;
    } else {
      return true;
    }
  };

  const generateImage = async () => {
    if (!checkToolIsSaved()) return;

    if (inputValue.trim() === "") {
      return setStatusMessage(
        "Please enter an image description to generate a new cover photo"
      );
    }

    const colours = [
      "blue",
      "blue",
      "blue",
      "blue",
      "purple",
      "purple",
      "purple",
      "purple",
      "green",
      "red",
      "orange",
      "yellow",
    ];
    const chosen_colour = colours[Math.floor(Math.random() * colours.length)];

    setLoading(true);
    try {
      const response = await generateImageFromDALLE({
        prompt:
          "an icon in " +
          chosen_colour +
          " metallic iridescent material, isometric perspective, square 3D render on dark background of: " +
          inputValue,
        authHeader,
        userId,
        toolId,
      });

      if (typeof response === "object" && "imageURL" in response) {
        console.log("response.imageURL", response.imageURL);
        setImageUrl(response.imageURL);
        setGeneratedImage(response.imageURL); // set generatedImage state here
        updateCredits(response.updatedCredit * 10);
        await handleSave(response.imageURL);
      }
    } catch (error) {
      const errorObj = error as { message: string };
      setStatusMessage(errorObj.message ?? "Error with GPT response");
      return;
    } finally {
      setLoading(false);
    }
  };

  // function dataURLtoBlob(dataurl: string): Blob {
  //   const arr = dataurl.split(',');
  //   const mime = arr[0].match(/:(.*?);/);

  //   if (!mime || arr.length < 2) {
  //     throw new Error('Invalid data URL');
  //   }

  //   const bstr = atob(arr[1]);
  //   let n = bstr.length; // Use let instead of const
  //   const u8arr = new Uint8Array(n);

  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }

  //   return new Blob([u8arr], { type: mime[1] });
  // }

  // const uploadImageToCloudinary = async (imageUrl: string) => {
  //   let cloudinaryUrl: string | null = null;
  //   try {
  //     const response = await axios.post(
  //       `${backendURL}/openai/cloudinary/image`,
  //       { imageUrl },
  //       {
  //         headers: {
  //           Authorization: authHeader,
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );
  //     cloudinaryUrl = response.data.url;
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   return cloudinaryUrl;
  // };

  async function onUploadClick() {
    if (checkToolIsSaved()) fileInputRef.current?.click();
  }

  async function handleSave(imageDataURL: string) {
    if (!checkToolIsSaved()) {
      return;
    }
    if (!imageDataURL) {
      setStatusMessage("This image is already saved");
      return;
    }

    setStatusMessage("Saving cover photo...");

    try {
      await axios.patch(
        `${backendURL}/blocks/${toolId}/coverPhoto`,
        { imageDataURL },
        {
          headers: {
            Authorization: authHeader,
            "Content-Type": "application/json",
          },
        }
      );
      const newStatusMessage = "Cover photo saved";
      setStatusMessage(newStatusMessage);
      setGeneratedImage(null); // Reset the generated image state
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.msg;
      setStatusMessage(errorMessage ?? "Error saving cover photo");
    }
  }

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setLoading(true);
      const file = e.target.files[0];
      if (!["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
        setStatusMessage("Invalid file type");
        return setLoading(false);
      }
      if (file.size >= 4048576) {
        setStatusMessage("File too large");
        return setLoading(false);
      }
      const reader = new FileReader();
      console.log("reader created");
      reader.onloadend = async () => {
        if (reader.result) {
          try {
            console.log("reader has a result");
            // Convert to Data URL for Cloudinary upload
            const dataUrl = reader.result as string;
            //console.log(dataUrl);
            setImageUrl(dataUrl); // Update the image preview here
            updateToolMetadata({ coverPhoto: dataUrl });
            setGeneratedImage(dataUrl); // Set generatedImage state with manual upload
            await handleSave(dataUrl);
          } finally {
            setLoading(false);
            if (fileInputRef?.current) {
              // clear value so that if the upload fails the user can attempt to upload the same image
              fileInputRef.current.value = "";
            }
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setStatusMessage("");
  };

  return (
    <>
      {/*<div className='bg-blue-200 p-2 px-4 rounded-lg text-xs w-full flex flex-col items-center justify-center text-center gap-2'>*/}
      <div className="h-full w-full text-xs">
        <h2>
          Cover Photo (click to upload a new photo; this will overwrite the
          current image)
        </h2>
        <div className="flex justify-center">
          {loading ? (
            <FuserLoader loading={loading} />
          ) : (
            <img
              className="hover:cursor-pointer"
              src={imageUrl || coverPhoto || uploadImage}
              onClick={onUploadClick}
            />
          )}
        </div>
      </div>

      <p className="text-md">{statusMessage}</p>

      <div className="w-full flex">
        <Input
          className="w-full px-2 py-2 rounded-md resize-none text-xs dark:bg-neutral-900 dark:text-neutral-200"
          value={inputValue}
          placeholder="Enter cover photo description here"
          onChange={handleInputChange}
          disabled={loading}
        />
      </div>

      <div className="w-full flex gap">
        <button
          className="w-full bg-blue-500 hover:bg-blue-700 text-white font-thin py-2 px-4 rounded-xl flex items-center justify-center text-xs"
          onClick={generateImage}
          disabled={loading}
        >
          Generate cover photo (this costs credits and will overwrite the
          current image)
        </button>

        {/* <button
          className='w-1/2 bg-blue-500 hover:bg-blue-700 text-white font-thin py-2 px-4 rounded-xl flex items-center justify-center text-xs'
          onClick={handleSave}
          disabled={loading}
        >
          Save Image
        </button> */}

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileUpload}
          disabled={loading}
        />
      </div>
      {/*</div>*/}
    </>
  );
};

export default ImageGenerator;
