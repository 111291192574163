import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import missingpic from '../images/missing.jpg';
import { Fusion, Skill } from '../../TypeScript/Types';

export interface CardNFT2Props {
  className?: string;
  isLiked?: boolean;
  fusion?: Fusion;
}

const trimDescriptionAndRemoveSkillfusionLinks = (
  desc: string,
  maxLength: number
) => {
  if (typeof desc !== 'string') {
    // Return a default string or keep it empty if desc is not valid
    return '';
  }

  if (desc.length > maxLength) {
    // Return the trimmed description with an ellipsis
    return `${desc.substring(0, maxLength)} <u class="underline-offset-2 text-blue-600">...read more</u>`;
  }

  // Return the original description with skillfusion links removed if it doesn't need trimming
  return desc.replace(
    /(https?:\/\/)?(www\.)?(blog\.)?skillfusion\.ai[A-Za-z0-9\-._~!$&'()*+,;=:@/?#[\]%]* ?/gi,
    ''
  );
};

const FusionCard: FC<any> = ({
  link,
  handleLinkClick,
  price,
  priceFrequency,
  coverPhoto,
  name,
  views,
  downloads,
  description,
}) => {
  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);

  return (
    <div>
      <Link
        //className={`w-full rounded-lg text-black bg-white flex-col border-2 rounded-md shadow-lg w-full flex h-full rounded-3xl hover:shadow-blue pb-6 ${(result.price != null && result.price > 0) ? 'border-yellow-600 border-2' : ''}`}
        className={`w-full text-black bg-white border-2 grid grid-cols-[auto_1fr] md:grid-rows-[auto_1fr] gap-x-3 shadow-lg w-full h-full max-h-72 md:h-40 hover:shadow-blue overflow-hidden`}
        to={link}
        onClick={handleLinkClick}
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className='relative flex-shrink-0 max-h-28 md:max-h-40 md:row-span-2'>
          <img
            src={imageFailedToLoad ? missingpic : coverPhoto || missingpic}
            onError={() => setImageFailedToLoad(true)}
            className='group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform object-cover h-28 w-28 md:h-40 md:w-40'
            alt='nc-imgs'
          />
        </div>
        <div className='pt-1 md:pt-3'>
          <h2 className='text-lg mb-2 overflow-ellipsis max-w-full'>{name}</h2>
          <p>
            <b>{downloads.toLocaleString()}</b> Users /{' '}
            <b>{views.toLocaleString()}</b> views
          </p>
          <p>
            Price:&nbsp;
            <b
              style={{
                color:
                  typeof price === 'number' && price > 0
                    ? 'rebeccapurple'
                    : 'black',
              }}
            >
              {typeof price === 'number' && price !== 0.0
                ? `${price * 10} credits ${
                    priceFrequency === 'monthly' ? ' per month' : ''
                  }`
                : 'Free'}
            </b>
          </p>
        </div>
        <div className='col-span-2 md:col-span-1 overflow-y-scroll h-full'>
          <span className='flex items-center justify-between w-full gap-2'>
            <div className='flex flex-col p-3 md:p-0 w-full'>
              <p
                className='overflow-ellipsis max-w-full text-sm'
                dangerouslySetInnerHTML={{
                  __html: trimDescriptionAndRemoveSkillfusionLinks(
                    description,
                    250
                  ),
                }}
              ></p>
            </div>
          </span>
        </div>
      </Link>
    </div>
  );
};

export default FusionCard;
