import React from 'react';
import { Link } from 'react-router-dom';

const LinkToProfile: React.FC<{ user: any; className?: string }> = ({
  user,
  className,
}) => {
  // console.log(`text-sm font-semibold${className ? ' ' + className : ''}`);
  return (
    <Link
      className={`text-sm font-semibold${className ? ' ' + className : ''}`}
      to={`/profile/${user._id}`}
    >
      {user.name || 'username'} - Visit Profile
    </Link>
  );
};

export default LinkToProfile;
