import React, { useContext, useState } from 'react';
import DatalistInput, { useComboboxControls } from 'react-datalist-input';
import FuserContext from '../../context/FuserContext';
import ReactTextareaAutosize from 'react-textarea-autosize';
import Block from '../../models/Block';
import { updateAtIndex } from '../../utils/array';
import getCaretCoordinates from 'textarea-caret';
import { keyPath, setProperty } from '../../utils/object';

const AutocompleteTextarea = ({
  block,
  index,
  onChange,
  textAreaIndex,
  className,
  containerClassName,
  name,
  value,
  placeholder,
  autosize,
}: {
  block: any;
  index: number;
  onChange: any;
  textAreaIndex: any;
  value: any;
  name: string;
  className?: string;
  containerClassName?: string;
  placeholder?: string;
  autosize?: boolean;
}) => {
  const { setBlocks, textAreaRefs, handleTextareaFocus, blocks } =
    useContext(FuserContext);

  const updateBlocks = () =>
    setBlocks((blocks: Block[]) => updateAtIndex(index, block, blocks));

  const onTextareaFocus =
    (textareaId: number) =>
    (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
      handleTextareaFocus(e, block.id, textareaId);
    };

  const blockNames = blocks.map(
    ({ data: { name } }: any, index: number) => name ?? `Block${index}`
  );

  const [blockNameSuggestionsVisible, setBlockNameSuggestionsVisible] =
    useState<boolean>(false);

  const [blockNameSuggestionsCoordinates, setBlockNameSuggestionsCoordinates] =
    useState<{ x: number; y: number }>({ x: 0, y: 0 });

  const {
    setValue: setComboboxValue,
    value: comboboxValue,
    setIsExpanded,
  } = useComboboxControls({
    isExpanded: true,
  });

  const [indexOfLastFocusedAt, setIndexOfLastFocusedAt] = useState<number>(-1);

  const onBlockNameSuggestionSelect = (selectedItem: any) => {
    const blockDataPath = name.includes('.') ? name.split('.') : name;

    const textareaValue = keyPath(blockDataPath, block.data);

    const endIndexOfPartiallyTypedBlockNameRelativeToAt = textareaValue
      .slice(indexOfLastFocusedAt + 1)
      .search(/[^\w]/g);

    setProperty(
      blockDataPath,
      textareaValue.slice(0, indexOfLastFocusedAt + 1) +
        selectedItem.value +
        (endIndexOfPartiallyTypedBlockNameRelativeToAt === -1
          ? ''
          : textareaValue.slice(
              indexOfLastFocusedAt +
                1 +
                endIndexOfPartiallyTypedBlockNameRelativeToAt
            )),
      block.data
    );

    updateBlocks();
    setComboboxValue(''); // Custom behavior: Clear input field once a value has been selected
    setBlockNameSuggestionsVisible(false);
  };

  const updateSuggestions = (e: any) => {
    //console.log(e);
    setIsExpanded(true);
    const textareaValue = e.target.value;
    const indexOfCharacterAfterCursor = e.target.selectionStart;

    const indexOfFirstAtBeforeCursor = textareaValue
      .slice(0, indexOfCharacterAfterCursor)
      .lastIndexOf('@');

    if (indexOfFirstAtBeforeCursor === -1) {
      setComboboxValue('');
      return setBlockNameSuggestionsVisible(false);
    }

    setIndexOfLastFocusedAt(indexOfFirstAtBeforeCursor);

    const wordFromCursorToFirstAtBeforeCursor = textareaValue.slice(
      indexOfFirstAtBeforeCursor + 1,
      indexOfCharacterAfterCursor
    );

    if (!/^\w*$/.test(wordFromCursorToFirstAtBeforeCursor)) {
      setComboboxValue('');
      return setBlockNameSuggestionsVisible(false);
    }

    const endIndexOfWordSurroundingCursorRelativeToAt = textareaValue
      .slice(indexOfFirstAtBeforeCursor + 1)
      .search(/[^\w]/g);

    const wordSurroundingCursor =
      endIndexOfWordSurroundingCursorRelativeToAt === -1
        ? textareaValue.slice(indexOfFirstAtBeforeCursor + 1)
        : textareaValue.slice(
            indexOfFirstAtBeforeCursor + 1,
            indexOfFirstAtBeforeCursor +
              endIndexOfWordSurroundingCursorRelativeToAt +
              1
          );

    setComboboxValue(wordSurroundingCursor);

    // console.log(wordSurroundingCursor);

    // const newBlockNameSuggestions = fakeBlockNames.filter((name: string) =>
    //   name
    //     .toLocaleLowerCase()
    //     .includes(wordSurroundingCursor.toLocaleLowerCase())
    // );

    // if (newBlockNameSuggestions.length === 0) {
    //   return setBlockNameSuggestionsVisible(false);
    // }

    setBlockNameSuggestionsVisible(true);

    const { scrollTop } = textAreaRefs.current[`${block.id}:${textAreaIndex}`];

    const { top, left, height } = getCaretCoordinates(
      e.target,
      indexOfFirstAtBeforeCursor
    );

    setBlockNameSuggestionsCoordinates({
      x: left,
      y: top + height - scrollTop,
    });
  };

  // const handleTextareaChange = (e: any) => {
  //   handleChange(e);
  //   updateSuggestions(e);
  // };

  const textareaProps = {
    className,
    name,
    value,
    onChange,
    placeholder,
    onFocus: (e: any) => {
      onTextareaFocus(textAreaIndex)(e);
      //setBlockNameSuggestionsVisible(true);
    },
    onClick: updateSuggestions,
    onKeyUp: updateSuggestions,
    ref: (el: any) => {
      textAreaRefs.current[`${block.id}:${textAreaIndex}`] = el;
    },
    onBlur: (e: any) => {
      if (
        !e?.relatedTarget?.classList?.contains(
          'react-datalist-input__listbox-option'
        )
      )
        setBlockNameSuggestionsVisible(false);
    },
  };

  return (
    <div className={'relative ' + (containerClassName ?? '')}>
      {autosize ? (
        <ReactTextareaAutosize {...textareaProps} />
      ) : (
        <textarea {...textareaProps} />
      )}

      {/* <textarea
         className={className}
         name={name}
         onFocus={onTextareaFocus(textAreaIndex)}
         value={value}
         onChange={handleChange}
         onClick={updateSuggestions}
         onKeyUp={updateSuggestions}
         ref={el => {
           textAreaRefs.current[`${block.id}:${textAreaIndex}`] = el;
         }}
         placeholder={placeholder}
       /> */}

      {blockNameSuggestionsVisible && (
        <DatalistInput
          style={{
            position: 'absolute',
            top: blockNameSuggestionsCoordinates.y,
            left: blockNameSuggestionsCoordinates.x,
          }}
          placeholder='Suggestions'
          value={comboboxValue}
          setValue={setComboboxValue}
          label='Suggestions'
          showLabel={false}
          items={blockNames.map((name: string) => ({
            id: name,
            value: name,
            node: DatalistItem({ id: name, value: name }),
          }))}
          onSelect={onBlockNameSuggestionSelect}
          isExpanded={true}
          setIsExpanded={setIsExpanded}
          inputProps={{ readOnly: true }}
        />
      )}
    </div>
  );
};

const DatalistItem = ({ id, value }: any) => {
  // const context = useComboboxContext();
  // console.log(context);
  return (
    <div
      key={value}
      className='datalist-item bg-white p-1'
    >
      <p>{value}</p>
    </div>
  );
};

export default AutocompleteTextarea;
