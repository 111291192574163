import React, { FC, useState, useContext } from "react";
import ReactGA from "react-ga4";
import jszip from "jszip";
import FuserLoader from "../../containers/FuserPage/FuserLoader";
import Block from "../../models/Block";
import BlockProps from "../../models/BlockProps";
import { useCredit } from "../../context/CreditContext";
import FuserContext from "../../context/FuserContext";
import useBlockRunner from "../../hooks/useBlockRunner";
import axios from "axios";
import AutocompleteTextarea from "../../containers/FuserPage/AutocompleteTextarea";
import {
  batchArray,
  ensureArray,
  ensureNotArray,
  updateAtIndexRun,
  flatten,
  unflatten,
} from "../../utils/array";
import GptParameterSection from "../../containers/FuserPage/GptParameterSection";
import MyToolTips from "../MyTooltip";
import useCancellablePause from "../../hooks/useCancellablePause";
import { useAuthHeader } from "react-auth-kit";
import { backendURL } from "../../constants/environmental";
import { truncateAfter } from "../../utils/string";
import { MAX_PREVIEW_CHARS } from "../../constants/blocks";
import { randomNumberBetween } from "../../utils/math";
import { replacePlaceholders } from "../../utils/fuser";

const TextToSpeechBlock: FC<BlockProps> = ({
  isLoading,
  index,
  block,
  setIsLoading,
  toolId,
  handleChange,
  collapsed,
  isShared,
  // sharedBy,
}) => {
  const {
    blockStyles,
    runnerMode,
    setBlocks,
    setActivityLog,
    blocks,
    setStillRunning,
  } = useContext(FuserContext);

  const authHeader = useAuthHeader()();

  const { credit, updateCredits } = useCredit();

  const [downloadStatusMessage, setDownloadStatusMessage] =
    useState<string>("");
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);

  const {
    response,
    inputToProcess,
    message,
    model,
    voice,
    speed,
    responseFormat,
    blockHeading,
    downloadName,
  } = block.data;

  const { pause, cancelPause } = useCancellablePause();

  useBlockRunner(onTestClick, index);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [promptBatchData, setPromptBatchData] = useState({
    promptBatchNumber: 0,
    numberOfPromptBatches: 0,
  });

  const { promptBatchNumber, numberOfPromptBatches } = promptBatchData;

  const processedMessage = ensureNotArray(replacePlaceholders(message, blocks));

  const previews = response?.length > 0 && (
    // <div>
    //   <h2>Preview</h2>

    <div
      className={`grid items-center grow ${
        response.length > 1
          ? "gap-2 gap-x-12 overflow-y-scroll xl:grid-cols-2 lg:grid-cols-1"
          : ""
      }`}
    >
      {response.map((url: string, urlIndex: number) => {
        const format = responseFormat ?? url.split(".").at(-1);
        return (
          <div key={url} className="flex flex-col items-center">
            <h3 className="text-sm">Audio {urlIndex}</h3>
            <audio controls>
              <source
                src={url}
                type={`audio/${format === "mp3" ? "mpeg" : format}`}
              />
              {"Sorry, your browser can't preview this audio."}{" "}
            </audio>
          </div>
        );
      })}
    </div>
    // </div>
  );

  const downloadAudioSection = (
    <div className="flex flex-col gap-2 w-full items-center">
      <div className="download-options w-full flex items-center flex-wrap gap-2 items-center justify-center">
        {isShared || (
          <>
            <label className="text-sm">
              Download name (optional, 50 character limit):
            </label>
            {/* <input
            className='p-1 border border-black rounded-lg text-sm w-max grow'
            name='downloadName'
            value={downloadName ?? ''}
            onChange={handleDownloadNameChange}
          /> */}

            <AutocompleteTextarea
              autosize={true}
              block={block}
              index={index}
              onChange={handleChange}
              textAreaIndex={2}
              containerClassName="flex grow"
              className="p-1 border border-black rounded-lg text-sm w-max grow"
              name="downloadName"
              value={downloadName ?? ""}
              placeholder="Enter download name here..."
            />
          </>
        )}
      </div>
      {response?.length > 0 && (
        <>
          <button
            className="border-2 p-2 text-md rounded-xl bg-blue-100 hover:bg-white"
            onClick={downloadAudioFiles}
          >
            Download audio
          </button>

          <p className="text-sm">{downloadStatusMessage}</p>
        </>
      )}
    </div>
  );

  if (collapsed) {
    return (
      <div className="overflow-auto">
        {message ? processedMessage : "Text to Speech block"}
        {previews}
        {errorMessage && <p>{errorMessage}</p>}
      </div>
    );
  }

  if (isShared) {
    return (
      <>
        <div className="grid gap-1 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1"></div>

        <div>
          {truncateAfter(
            MAX_PREVIEW_CHARS,
            blockHeading || "Text to Speech block"
          )}
        </div>

        {previews}

        {downloadAudioSection}
      </>
    );
  }

  return (
    <FuserLoader
      name="Text to Speech Block"
      loading={isLoading}
      message={`${
        message ? processedMessage + " " : ""
      }(Prompt ${promptBatchNumber} of ${numberOfPromptBatches})`}
      onCancelClick={cancelRequest}
    >
      <div className={blockStyles} key={index}>
        {runnerMode ? (
          message ? (
            <p>{processedMessage}</p>
          ) : (
            <div className="mb-4">Text to Speech block</div>
          )
        ) : (
          <>
            <label className="text-xs">Text to convert to speech:</label>

            <AutocompleteTextarea
              block={block}
              index={index}
              onChange={handleChange}
              textAreaIndex={0}
              className="w-full prompt-textarea resize-none  bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner "
              name="inputToProcess"
              value={inputToProcess || ""}
            />
          </>
        )}
        <div
          className={`grid lg:grid-cols-2 md:grid-cols-1 gap-3 ${
            runnerMode ? "mb-4" : ""
          }`}
        >
          <label className="text-xs w-max-content">
            Model:{"  "}
            <MyToolTips
              content="<p>The cost is proportional to the amount of characters</p>
                  <p>The HD model is twice as expensive</p>"
              tipID={index.toString() + "-block-types-definition"}
              datatooltipplace="right"
            />
            <select
              className="text-xs bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner"
              onChange={handleChange}
              value={model ?? "tts-1"}
              name="model"
            >
              <option value="tts-1">TTS 1</option>
              <option value="tts-1-hd">TTS 1 HD</option>
            </select>
          </label>

          <label className="text-xs w-max-content">
            Voice:{"  "}
            <select
              className="text-xs bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner"
              onChange={handleChange}
              value={voice ?? "alloy"}
              name="voice"
            >
              <option value="alloy">Alloy</option>
              <option value="echo">Echo</option>
              <option value="fable">Fable</option>
              <option value="onyx">Onyx</option>
              <option value="nova">Nova</option>
              <option value="shimmer">Shimmer</option>
            </select>
          </label>

          <label className="text-xs w-max-content">
            Response format:{"  "}
            <select
              className="text-xs bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner"
              onChange={handleChange}
              value={responseFormat ?? "mp3"}
              name="responseFormat"
            >
              <option value="mp3">mp3</option>
              {/* 
                  opus doesn't preview properly
                  <option value='opus'>opus</option> 
                  */}
              <option value="aac">aac</option>
              {/* 
                  flac won't upload to cloudinary
                  <option value='flac'>flac</option> 
                  */}
            </select>
          </label>

          <GptParameterSection
            labelText="Speed:"
            name="speed"
            min="0.25"
            max="4"
            value={speed ?? 1}
            handleChange={handleChange}
          />
        </div>
        {runnerMode || (
          <>
            {" "}
            <span className="text-xs" id="prompt-message-input">
              Optional heading for user:
            </span>
            <input
              className=" bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner "
              type="text"
              name="message"
              value={message || ""}
              onChange={handleChange}
            />
          </>
        )}

        <p>{errorMessage}</p>
        <div className="flex flex-col items-center gap-2">
          <button
            className="border-2 p-2 text-md rounded-xl bg-blue-100 hover:bg-white"
            onClick={onTestClick}
          >
            Generate new audio
          </button>
          {previews}
          {downloadAudioSection}
        </div>
      </div>
    </FuserLoader>
  );

  async function onTestClick() {
    try {
      setIsLoading(true);
      setDownloadStatusMessage("");
      const block = blocks[index];
      const promptText = block.data.inputToProcess;
      const messageText = block.data.message;

      if (["", undefined].includes(promptText?.trim())) {
        setErrorMessage("Please enter some text");
        setIsLoading(false);
        return;
      }

      setErrorMessage("");

      const processedInput: any[] = ensureArray(
        replacePlaceholders(promptText, blocks)
      );

      const totalPromptsLength = totalLength(processedInput);
      let chunkedPrompts = processedInput.map((prompt) =>
        prompt.match(/.{1,4096}($|\.|\n|\r)/gs)
      );

      // check if any characters are missed out in case of massive stretches of text with no . or 2 newlines, separate by \s if so
      if (totalPromptsLength !== totalLength(chunkedPrompts)) {
        chunkedPrompts = processedInput.map((prompt) =>
          prompt.match(/.{1,4096}($|\.|\n|\r|\s)/gs)
        );
        if (totalPromptsLength !== totalLength(chunkedPrompts)) {
          chunkedPrompts = processedInput.map((prompt) =>
            prompt.match(/.{1,4096}/gs)
          );
        }
      }

      const MAX_PROMPT_BATCH_SIZE = block.data.model === "tts-1" ? 2 : 1;

      const promptBatches = batchArray(
        MAX_PROMPT_BATCH_SIZE,
        flatten(chunkedPrompts)
      );

      const MAX_RETRIES = 40;
      const MAX_RESPONSE_WAIT_TIME_IN_SECONDS = 120;
      const INITIAL_PAUSE_DURATION_IN_MS = randomNumberBetween(5000, 10000);
      let retriesRemaining = MAX_RETRIES;

      let audioFiles: any[] = [];

      for (
        let currentPromptBatchIndex = 0,
          pauseDuration = INITIAL_PAUSE_DURATION_IN_MS;
        currentPromptBatchIndex < promptBatches.length; // currentPromptBatchIndex is updated in the block of code below

      ) {
        console.log(
          "prompt batch",
          currentPromptBatchIndex + 1,
          "of",
          promptBatches.length,
          ". Retries remaining:",
          retriesRemaining
        );

        if (retriesRemaining === 0) {
          ReactGA.event("max_retries");
          throw new Error(
            `${MAX_RETRIES} consecutive failed requests from OpenAI, please try again later.`
          );
        }

        setPromptBatchData({
          promptBatchNumber: currentPromptBatchIndex + 1,
          numberOfPromptBatches: promptBatches.length,
        });

        // each prompt can have at most 4096 characters
        const promptBatch = promptBatches[currentPromptBatchIndex];

        try {
          const response: any = await axios.post(
            `${backendURL}/openai/textToSpeech`,
            {
              prompts: promptBatch,
              toolId,
              model: block.data?.model ?? "tts-1",
              voice: block.data?.voice ?? "alloy",
              speed: block.data?.speed ?? "1",
              responseFormat: block.data?.responseFormat ?? "mp3",
            },
            {
              headers: {
                Authorization: authHeader,
              },
            }
          );

          const { updatedCreditInDollars, message } = response.data;

          if (updatedCreditInDollars != null) {
            updateCredits(updatedCreditInDollars * 10);
          }

          if (message === "cancelled") {
            console.log("Cancelled text to speech generation");
            return;
            //return 'CANCEL';
          }

          const newAudioFiles = response.data?.audioFiles;

          audioFiles = [...audioFiles, ...(newAudioFiles ?? [])];

          currentPromptBatchIndex++;
          pauseDuration = INITIAL_PAUSE_DURATION_IN_MS;
          retriesRemaining = MAX_RETRIES;
        } catch (error) {
          console.log(error);
          const code = error?.code;
          const status = error?.response?.status;
          const statusText =
            error?.response?.statusText || error?.response?.statusMessage;

          const updatedCreditInDollars =
            error?.response?.data?.updatedCreditInDollars;
          if (updatedCreditInDollars !== undefined) {
            updateCredits(updatedCreditInDollars * 10);
          }

          console.log("gpt error:", error.response);
          if (
            [502, 503].includes(status) ||
            (status === 429 && statusText?.startsWith("Rate limit reached")) ||
            statusText?.startsWith("Too Many Requests") ||
            (status === 500 &&
              statusText?.startsWith("The server had an error")) ||
            code === "ERR_NETWORK"
          ) {
            if (status === 429) {
              ReactGA.event("token_limit_retry");
            }
            if (status === 500) ReactGA.event("openai_server_error_retry");
            if (status === 502) ReactGA.event("502_error_retry");
            if (status === 503) ReactGA.event("openai_server_overloaded_retry");
            if (code === "ERR_NETWORK") ReactGA.event("network_error");

            console.log("pausing for", pauseDuration, "ms");
            await pause(pauseDuration);
            pauseDuration = Math.min(
              MAX_RESPONSE_WAIT_TIME_IN_SECONDS * 1000,
              pauseDuration * randomNumberBetween(1.5, 1.7)
            );
            console.log(
              "pauseDuration set to",
              pauseDuration,
              "ms on prompt",
              currentPromptBatchIndex + 1
            );
            retriesRemaining--;
            continue;
          } else {
            setErrorMessage(
              error?.response?.data?.code === "content_policy_violation"
                ? "Your request was rejected as a result of OpenAI's safety system. Your prompt may contain text that is not allowed by OpenAI's safety system"
                : error?.message ?? "Error with GPT response"
            );
            setIsLoading(false);
            return;
          }
        }
      }

      const chunkedArrayBuffers = unflatten(audioFiles, chunkedPrompts);

      const audioBlobs = chunkedArrayBuffers.map(
        (arrayBuffers) =>
          new Blob(
            arrayBuffers.map(({ data }: any) => new Uint8Array(data).buffer),
            {
              type: `audio/${
                responseFormat === "mp3" ? "mpeg" : responseFormat
              }`,
            }
          )
      );

      // let totalAudioSize = audioBlobs
      //   .map(blob => blob.size)
      //   .reduce((x, y) => x + y, 0);
      // if (totalAudioSize > 10 * 2 ** 20) {
      const cloudinaryStreamUploadResponses = await Promise.all(
        audioBlobs.map((audioBlob) => {
          const formData = new FormData();
          formData.append("file", audioBlob, `filename.${responseFormat}`);
          formData.append("format", responseFormat);
          if (block.data.response)
            formData.append("oldURLsJSON", JSON.stringify(block.data.response));
          return axios.post(
            `${backendURL}/openai/cloudinary/audio-stream`,
            formData,
            {
              headers: {
                Authorization: authHeader,
              },
            }
          );
        })
      );
      const urls = cloudinaryStreamUploadResponses.map(
        (response) => response?.data?.url
      );
      // } else {
      //   const cloudinaryUploadResponse = await axios.post(
      //     `${backendURL}/openai/cloudinary/audio`,
      //     {
      //       chunkedArrayBuffers,
      //       format: responseFormat,
      //       oldURLs: block.data.response,
      //     },
      //     {
      //       headers: {
      //         Authorization: authHeader,
      //       },
      //     }
      //   );

      //   urls = cloudinaryUploadResponse.data.urls;
      // }

      const newBlock = {
        ...block,
        data: {
          ...block.data,
          type: "textToSpeech",
          inputToProcess: promptText,
          message: messageText,
          response: urls,
        },
        run: true,
      };

      // console.log(newBlock.data.response);
      setBlocks((blocks: Block[]) => updateAtIndexRun(index, newBlock, blocks)); // This will update your state with the new blocks

      // Updating the local storage for the individual block
      // localStorage.setItem(`block_${index}`, JSON.stringify(blocks[index]));

      setIsLoading(false);
      setActivityLog((prevLog: string[]) => [
        ...prevLog,
        `Saved image block at index: ${index}`,
      ]);
      setErrorMessage("");
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.error?.error?.name === "TimeoutError")
        setErrorMessage(
          "Audio upload timed out, please check your internet connection."
        );
    } finally {
      setIsLoading(false);
    }
  }

  async function downloadAudioFiles() {
    if (downloadInProgress) return;
    if (!block.data.response) return;

    // const { responseFormat } = block.data;

    setDownloadInProgress(true);
    try {
      const zip = new jszip();
      setDownloadStatusMessage(
        "Downloading audio files; this may take a minute or two"
      );

      await Promise.all(
        block.data.response.map(async (audioUrl: string, index: number) => {
          const response = await axios.get(audioUrl, { responseType: "blob" });
          const audioBlob = response.data;
          // Determine the file extension based on the response content type
          const contentType = response.headers["content-type"];
          const extension = contentType.includes("mpeg")
            ? "mp3"
            : contentType.split("/")[1];

          zip.file(
            `${downloadName ?? "audio"}-${index + 1}.${extension}`,
            audioBlob
          );
        })
      );

      zip.generateAsync({ type: "blob" }).then((content) => {
        const url = URL.createObjectURL(content);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${downloadName ?? "audio"}.zip`;
        a.click();
      });

      setDownloadStatusMessage("Download successful!");
    } catch (error) {
      const errorObj = error as { message: string };
      setDownloadStatusMessage(errorObj?.message ?? "Download failed");
    } finally {
      setDownloadInProgress(false);
    }
  }

  async function cancelRequest() {
    setStillRunning(false);
    cancelPause();

    if (isLoading) {
      console.log("cancelling text to speech request for block", index);
      const cancelResponse = await axios.get(
        `${backendURL}/openai/cancelTextToSpeech`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      setIsLoading(false);
      console.log("cancel effect finished for block", index);
    }
  }

  function totalLength(array: any[]) {
    return array
      .flat(Infinity)
      .reduce(
        (totalLength: number, nextElement: any) =>
          totalLength + nextElement?.length,
        0
      );
  }

  // useEffect(() => {
  //   if (index !== runnerIndex && runnerMode && stillRunning) {
  //     cancelRequest();
  //   }
  // }, [runnerIndex]);

  // useEffect(() => {
  //   if (index !== runnerIndex && runnerMode && stillRunning) {
  //     //console.log(`cancelling any prompts loading at block ${index}`);
  //     cancelRequest();
  //   }
  // }, [runnerIndex]);

  // const onRestartPrompt = async () => {
  //   console.log(`restarting prompt at block ${index}`);
  //   onTestClick();
  // };

  // console.log(block.data.response[0]);
  // const downloadMp3 = () => {
  //   // Convert the string back to binary data

  //   // Create a temporary anchor element and trigger the download
  //   const anchor = document.createElement('a');
  //   anchor.href = blobUrl;
  //   anchor.download = 'download.mp3'; // Suggest a filename for the download
  //   document.body.appendChild(anchor); // Required for Firefox
  //   anchor.click();

  //   // Clean up
  //   document.body.removeChild(anchor);
  //   URL.revokeObjectURL(blobUrl);
  // };
};

export default TextToSpeechBlock;
