import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Input from '../../shared/Input/Input';
import { useLocation, useNavigate } from 'react-router-dom';
import { backendURL } from '../../constants/environmental';

// const ResetPassword: React.FC = () => {
//   const passwordRef = useRef<HTMLInputElement>(null);
//   const token = window.location.pathname.split('/')[2]; // Extract token from URL

//   const handleResetPassword = async (event: React.FormEvent) => {
//     event.preventDefault();

//     const password = passwordRef.current!.value;

//     try {
//       const response = await axios.post(`${backendURL}/user/reset/${token}`, {
//         password,
//       });
//       if (response.status === 200) {
//         // Display success message
//       }
//     } catch (error) {
//       console.error('Error resetting password', error);
//       // Display error message
//     }
//   };

//   return (
//     <div className='w-full max-w-xs mx-auto mt-20'>
//       <form
//         onSubmit={handleResetPassword}
//         className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'
//       >
//         <div className='mb-4'>
//           <label
//             className='block text-gray-700 text-sm font-bold mb-2'
//             htmlFor='password'
//           >
//             New Password
//           </label>
//           <input
//             ref={passwordRef}
//             className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
//             id='password'
//             type='password'
//             placeholder='New Password'
//             required
//           />
//         </div>
//         <div className='flex items-center justify-between'>
//           <button
//             className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
//             type='submit'
//           >
//             Submit
//           </button>
//         </div>
//       </form>
//       <p className='text-center text-gray-500 text-xs'>
//         &copy;2023 Skillfusion.AI. All rights reserved.
//       </p>
//     </div>
//   );
// };

const ResetPassword: React.FC = () => {
  const [statusMessage, setStatusMessage] = useState('');
  const navigate = useNavigate();

  const userId = window.location.pathname.split('/').at(-1);

  const codeRef = useRef<HTMLInputElement>(null);

  const [resetPasswordButtonText, setResetPasswordButtonText] =
    useState('Reset Password');

  const handleNewPassword = async (event: React.FormEvent) => {
    event.preventDefault();
    const code = codeRef.current!.value;

    if (!passWordIsValid || !passwordsMatch) return;

    setResetPasswordButtonText('Resetting password...');

    try {
      const response = await axios.post(`${backendURL}/user/reset-password`, {
        code,
        // userId is 0 if not already logged in
        userId: userId === '0' ? undefined : userId,
        password,
      });
      console.log(response);
      if (response.status === 200) {
        // advise password reset, with link to login page
        navigate('/login', {
          state: {
            resetPasswordMessage: 'Your password has been reset.',
          },
        });
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.error;
      console.error('Error sending password reset link', error);
      setStatusMessage(
        `There was a problem resetting your password${
          errorMessage ? `: ${errorMessage}` : ''
        }`
      );
      setResetPasswordButtonText('Reset Password');
    }
  };

  const [formErrorMessageVisibile, setFormErrorMessageVisibile] = useState<any>(
    {
      password: false,
      repeatPassword: false,
    }
  );

  const showErrorMessage = (formField: string) =>
    setFormErrorMessageVisibile((previous: any) => ({
      ...previous,
      [formField]: true,
    }));

  const [password, setPassword] = useState('');
  const passwordRef = useRef<HTMLInputElement>(null);
  const PASSWORD_REGEX = /^(?=.*[a-zA-Z]).{8,}$/;
  const passWordIsValid = PASSWORD_REGEX.test(password);
  const invalidPasswordError =
    'Password must be at least 8 characters long and must contain at least one letter';

  const [repeatPassword, setRepeatPassword] = useState('');
  const repeatPasswordRef = useRef<HTMLInputElement>(null);
  // check rpassword is valid

  const passwordsMatch = password === repeatPassword;
  const passwordsDontMatchError = 'Passwords do not match';

  const passwordInputClasses = `border ${
    !passWordIsValid && formErrorMessageVisibile.password
      ? 'border-red-800 dark:border-red-800 border-4'
      : 'border-gray-300 dark:border-gray-300'
  } mt-1`;
  const repeatPasswordInputClasses = `border ${
    !passwordsMatch && formErrorMessageVisibile.repeatPassword
      ? 'border-red-800 dark:border-red-800 border-4'
      : 'border-gray-300 dark:border-gray-300'
  } mt-1`;

  useEffect(() => {
    if (repeatPassword && !formErrorMessageVisibile.repeatPassword) {
      // shows the repeat password error message (if applicable) when
      // no character has been typed for a certain amount of time
      const errorMessageTimeout = setTimeout(() => {
        showErrorMessage('repeatPassword');
      }, 500);
      return () => clearTimeout(errorMessageTimeout);
    }
  }, [repeatPassword]);

  const location = useLocation();

  return (
    <div className='w-full max-w-xs mx-auto mt-20'>
      <p className='text-center'>{location?.state?.instructions}</p>
      <form
        onSubmit={handleNewPassword}
        className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col gap-2'
      >
        <div className='mb-4'>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='code'
          >
            12 Digit Code Sent To Your Email
          </label>
          <input
            ref={codeRef}
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            id='code'
            type='number'
            placeholder='12 Digit Code'
            autoComplete='off'
            required
          />
        </div>
        <label className='block'>
          <span className='flex justify-between items-center text-neutral-800 dark:text-neutral-200'>
            New Password
          </span>
          <Input
            type='password'
            placeholder='Password'
            className={passwordInputClasses}
            ref={passwordRef}
            onChange={e => setPassword(e.target.value)}
            onBlur={e => showErrorMessage('password')}
            autoComplete='new-password'
          />
          {!passWordIsValid && formErrorMessageVisibile.password && (
            <p className='text-black-500 mt-1 text-sm'>
              {invalidPasswordError}
            </p>
          )}
        </label>
        <label className='block'>
          <span className='flex justify-between items-center text-neutral-800 dark:text-neutral-200'>
            Repeat New Password
          </span>
          <Input
            type='password'
            placeholder='Repeat Password'
            className={repeatPasswordInputClasses}
            ref={repeatPasswordRef}
            onChange={e => setRepeatPassword(e.target.value)}
            onBlur={e => showErrorMessage('repeatPassword')}
            autoComplete='new-password'
          />
        </label>
        {!passwordsMatch && formErrorMessageVisibile.repeatPassword && (
          <p className='text-black-500 mt-1 text-sm'>
            {passwordsDontMatchError}
          </p>
        )}
        <p className='text-sm'>{statusMessage}</p>
        <div className='flex justify-center'>
          <button
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            type='submit'
          >
            {resetPasswordButtonText}
          </button>
        </div>
      </form>
    </div>
  );
};
export default ResetPassword;
