import React, { FC, useContext } from "react";
import FuserContext from "../../context/FuserContext";
import { useAuthUser } from "react-auth-kit";
import { dangerousBlockTypes } from "../../constants/blocks";

const AddOrSaveBlockSection: FC<{}> = () => {
  const {
    addBlock,
    saveBlock,
    saveBlockStatusMessage,
    setSaveBlockStatusMessage,
    blocks,
    toolMetadata: { title, isPublished },
    updateToolMetadata,
  } = useContext(FuserContext);

  const user = useAuthUser();

  const dangerousBlockSaveWarning =
    "As your tool contains a HTML preview/Custom Javascript block, if you save then your tool will need reviewing by an admin for safety before it can be published again.";

  const handleSaveBlockMouseEnter = () => {
    const blockTypes = blocks.map((block: any) => block.blocktype);
    const containsDangerousBlock = dangerousBlockTypes.some((dangerousType) =>
      blockTypes.includes(dangerousType)
    );

    if (containsDangerousBlock && isPublished && user()?.loggedin !== "false") {
      setSaveBlockStatusMessage(dangerousBlockSaveWarning);
    }
  };

  const handleSaveBlockMouseLeave = () => {
    if (saveBlockStatusMessage === dangerousBlockSaveWarning) {
      setSaveBlockStatusMessage("");
    }
  };

  return (
    <div className="items-center bg-blue-200 dark:bg-neutral-800 rounded-lg w-full px-2 py-2 shadow-xl">
      <p className="text-center">Title:</p>

      <input
        type="text"
        placeholder="descriptive title"
        className="w-full px-2 py-2 rounded-md mb-2 text-xs dark:bg-neutral-900 dark:text-neutral-200"
        value={title}
        onChange={({ target: { value } }) =>
          updateToolMetadata({ title: value })
        }
      />

      <div className="p-1 flex flex-col lg:flex-row items-center justify-center gap-2">
        <button
          onClick={(e: React.MouseEvent) => addBlock()}
          className="flex-shrink-0 flex-grow-1 bg-blue-400 p-2 rounded-lg text-white hover:bg-blue-500 transition-colors text-xs  whitespace-nowrap text-xs w-full lg:w-1/2"
        >
          Add Block
        </button>

        <button
          onClick={(e: React.MouseEvent) => saveBlock()}
          onMouseEnter={handleSaveBlockMouseEnter}
          onMouseLeave={handleSaveBlockMouseLeave}
          className="flex-shrink-0 flex-grow-1 bg-purple-400 p-2 rounded-lg text-white hover:bg-purple-500 transition-colors text-xs w-full lg:w-1/2"
        >
          Save
        </button>
      </div>
      <p>{saveBlockStatusMessage}</p>
    </div>
  );
};

export default AddOrSaveBlockSection;
