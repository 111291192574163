import React, { FC, useState, useEffect, useContext } from 'react';
import FuserLoader from '../../containers/FuserPage/FuserLoader';
import Block from '../../models/Block';
import BlockProps from '../../models/BlockProps';
import FuserContext from '../../context/FuserContext';
import useBlockRunner from '../../hooks/useBlockRunner';
import ReactTextareaAutosize from 'react-textarea-autosize';
import AutocompleteTextarea from '../../containers/FuserPage/AutocompleteTextarea';
import { testButtonStyles } from '../../constants/styles';
import deepEqual from 'fast-deep-equal';
import { evaluatePlaceholder, isValidBlockNumber } from '../../utils/fuser';
import { ensureNotArray } from '../../utils/array';

const { isNaN } = Number;
const { isArray } = Array;

const IfElseBlock: FC<BlockProps> = ({
  isLoading,
  setIsLoading,
  block,
  index,
  handleChange,
  handleTextareaFocus,
}) => {
  const {
    blockStyles,
    runnerMode,
    blocks,
    setBlocks,
    setActivityLog,
    textAreaRefs,
  } = useContext(FuserContext);

  useBlockRunner(onTestClick, index);

  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => console.log(errorMessage), [errorMessage]);

  const {
    inputToProcess,
    secondInputToProcess,
    selectValue,
    response,
    ifInput,
    elseInput,
  } = block.data;
  //console.log('If-else response: ', response);
  return (
    <FuserLoader
      name='If-else Block'
      loading={isLoading}
    >
      {runnerMode ? (
        <>
          <div>If-else block</div>

          {response && isArray(response) && response.length > 0 && (
            <div
              dangerouslySetInnerHTML={{
                __html: `Going to block ${response[0]}`,
              }}
            />
          )}
        </>
      ) : (
        <div
          className={blockStyles}
          key={index}
        >
          <label
            className='text-xs'
            id='prompt-textarea'
          >
            If
          </label>

          <div className='flex flex-col lg:flex-row items-center justify-between gap-2'>
            <AutocompleteTextarea
              block={block}
              index={index}
              onChange={handleChange}
              textAreaIndex={0}
              containerClassName='w-full lg:w-1/3'
              className='w-full bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
              name='inputToProcess'
              value={inputToProcess || ''}
              placeholder='Enter first expression here...'
            />

            <select
              className='text-xs bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
              id='block-select'
              name='selectValue'
              value={selectValue}
              onChange={handleChange}
            >
              <option value='equals'>equals</option>
              <option value='does-not-equal'>does not equal</option>
              <option value='less-than'>is less than</option>
              <option value='less-than-or-equal-to'>
                is less than or equal to
              </option>
              <option value='greater-than'>is greater than</option>
              <option value='greater-than-or-equal-to'>
                is greater than or equal to
              </option>
              <option value='length-equals'>length equals</option>
              <option value='length-does-not-equal'>
                length does not equal
              </option>
              <option value='length-is-less-than'>length is less than</option>
              <option value='length-is-less-than-or-equal-to'>
                length is less than or equal to
              </option>
              <option value='length-is-greater-than'>
                length is greater than
              </option>
              <option value='length-is-greater-than-or-equal-to'>
                length is greater than or equal to
              </option>
              <option value='starts-with'>starts with</option>
              <option value='ends-with'>ends with</option>
              <option value='contains-character-sequence'>
                contains the character sequence
              </option>
              <option value='contained-in'>is contained in</option>
              {/*<option value='regex-test'>test with regex</option>*/}
            </select>

            <AutocompleteTextarea
              block={block}
              index={index}
              onChange={handleChange}
              textAreaIndex={1}
              containerClassName='w-full lg:w-1/3'
              className='w-full bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
              name='secondInputToProcess'
              value={secondInputToProcess || ''}
              placeholder='Enter second expression here...'
            />
          </div>

          <div className='grid grid-cols-[max-content_auto] gap-4 content-center'>
            <label className='flex items-center'>Go to block number: </label>
            <ReactTextareaAutosize
              className='w-20 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
              // type='number'
              // min='0'
              // pattern='[0-9]*'
              name='ifInput'
              value={ifInput ?? ''}
              onChange={onNumberInputChange}
              onFocus={handleTextareaFocus(2)}
              ref={el => {
                textAreaRefs.current[`${block.id}:2`] = el;
              }}
            />

            <label className='flex items-center'>
              Else go to block number:{' '}
            </label>
            <ReactTextareaAutosize
              className='w-20 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
              // type='number'
              // min='0'
              // pattern='[0-9]*'
              name='elseInput'
              value={elseInput ?? ''}
              onChange={onNumberInputChange}
              onFocus={handleTextareaFocus(3)}
              ref={el => {
                textAreaRefs.current[`${block.id}:3`] = el;
              }}
            />
          </div>

          <button
            onClick={onTestClick}
            className={testButtonStyles}
          >
            Test
          </button>

          <p>{errorMessage}</p>

          {response && isArray(response) && response.length > 0 && (
            <div
              dangerouslySetInnerHTML={{
                __html: `Block ${response[0]} will run next in runner mode`,
              }}
            />
          )}
        </div>
      )}
    </FuserLoader>
  );

  async function onTestClick() {
    setIsLoading(true);
    setErrorMessage('');
    const { inputToProcess, secondInputToProcess, selectValue } = block.data;

    let processedInput1: any = inputToProcess
      ? evaluatePlaceholder(inputToProcess, blocks)
      : '';
    let processedInput2: any = secondInputToProcess
      ? evaluatePlaceholder(secondInputToProcess, blocks)
      : '';

    let testResult: boolean | undefined;
    let error;

    function get_number(x_string: any) {
      const found = ensureNotArray(x_string).match(/\d+/);
      if (found) return +found;
    }
    try {
      if (selectValue === 'equals') {
        testResult =
          processedInput1 == processedInput2 ||
          (isArray(processedInput1) &&
            isArray(processedInput2) &&
            deepEqual(processedInput1, processedInput2));
      } else if (selectValue === 'does-not-equal') {
        testResult = processedInput1 != processedInput2;
      } else if (selectValue === 'contained-in') {
        if (!isArray(processedInput2)) {
          throw new Error('The second input needs to be an array');
        }
        testResult =
          processedInput2.includes(processedInput1) ||
          (isArray(processedInput1) &&
            processedInput1.length === 1 &&
            processedInput2.includes(processedInput1[0]));
      } else if (
        [
          'length-equals',
          'length-does-not-equal',
          'length-is-less-than',
          'length-is-greater-than',
          'length-is-less-than-or-equal-to',
          'length-is-greater-than-or-equal-to',
        ].includes(selectValue)
      ) {
        if (processedInput1?.length === undefined) {
          throw new Error('The first input needs to have a length property');
        }
        processedInput2 = +processedInput2;

        if (isNaN(processedInput2)) {
          throw new Error('The second input needs to be a number');
        }

        if (selectValue === 'length-equals') {
          testResult = processedInput1.length == processedInput2;
        } else if (selectValue === 'length-does-not-equal') {
          testResult = processedInput1.length != processedInput2;
        } else if (selectValue === 'length-is-less-than') {
          testResult = processedInput1.length < processedInput2;
        } else if (selectValue === 'length-is-greater-than') {
          testResult = processedInput1.length > processedInput2;
        } else if (selectValue === 'length-is-less-than-or-equal-to') {
          testResult = processedInput1.length <= processedInput2;
        } else if (selectValue === 'length-is-greater-than-or-equal-to') {
          testResult = processedInput1.length >= processedInput2;
        } else throw new Error(`Invalid select value given: ${selectValue}`);
      }
      // end of length compared to number
      else if (
        [
          'less-than',
          'greater-than',
          'less-than-or-equal-to',
          'greater-than-or-equal-to',
        ].includes(selectValue)
      ) {
        if (isNaN(+processedInput1) && typeof processedInput1 !== 'string') {
          throw new Error('The first input needs to be a string or number');
        } else if (
          isNaN(+processedInput2) &&
          typeof processedInput2 !== 'string'
        ) {
          throw new Error('The second input needs to be a string or number');
        }

        if (
          !isNaN(get_number(processedInput1)) &&
          !isNaN(get_number(processedInput2))
        ) {
          // convert both to numbers if possible
          processedInput1 = get_number(processedInput1);
          processedInput2 = get_number(processedInput2);
          // console.log('if/else conversion', processedInput1, processedInput2);
        }

        // console.log('if/else comparison', processedInput1, processedInput2);
        //console.log(get_number(processedInput1),get_number(processedInput2));

        if (selectValue === 'less-than') {
          testResult = processedInput1 < processedInput2;
        } else if (selectValue === 'greater-than') {
          testResult = processedInput1 > processedInput2;
        } else if (selectValue === 'less-than-or-equal-to') {
          testResult = processedInput1 <= processedInput2;
        } else if (selectValue === 'greater-than-or-equal-to') {
          testResult = processedInput1 >= processedInput2;
        } else throw new Error(`Invalid select value given: ${selectValue}`);
      } else if (
        ['starts-with', 'ends-with', 'contains-character-sequence'].includes(
          selectValue
        )
      ) {
        if (isArray(processedInput1) && processedInput1.length === 1) {
          processedInput1 = processedInput1[0];
        }
        if (isArray(processedInput2) && processedInput2.length === 1) {
          processedInput2 = processedInput2[0];
        }
        if (
          typeof processedInput1 !== 'string' ||
          typeof processedInput2 !== 'string'
        ) {
          throw new Error('Both inputs need to be strings');
        }

        if (selectValue === 'starts-with')
          testResult = processedInput1.startsWith(processedInput2);
        else if (selectValue === 'ends-with')
          testResult = processedInput1.endsWith(processedInput2);
        else if (selectValue === 'contains-character-sequence')
          testResult = processedInput1.includes(processedInput2.toString());
        else throw new Error(`Invalid select value given: ${selectValue}`);
      } else throw new Error('Invalid select input');
    } catch (e: any) {
      error = e.toString();
      setErrorMessage(error);
      testResult = true;
    }

    const ifInput = +block.data.ifInput;
    const elseInput = +block.data.elseInput;

    let ifBlockReference = ifInput;
    let elseBlockReference = elseInput;

    // console.log(ifInput, elseInput);

    if (!isValidBlockNumber(ifInput) || !isValidBlockNumber(elseInput)) {
      error =
        'Please enter valid block numbers in the "Go to" and "Else go to" inputs, otherwise the next block will run by default.';
      setErrorMessage(error);
      if (!isValidBlockNumber(ifInput)) ifBlockReference = index + 1;
      if (!isValidBlockNumber(elseInput)) elseBlockReference = index + 1;
    }

    const response = testResult ? ifBlockReference : elseBlockReference;
    if (error === undefined) setErrorMessage('');
    //console.log(response);

    setIsLoading(false);
    setActivityLog((prevLog: string[]) => [
      ...prevLog,
      `Saved if-else block at index: ${index}`,
    ]);

    const blockUpdater = (blocks: Block[]) => {
      const newBlocks = [...blocks];

      newBlocks[index] = {
        ...newBlocks[index],
        updatedBlock: true,
        data: {
          ...newBlocks[index].data,
          type: 'if-else',
          response: [response.toString()],
          ifBlockReference,
          elseBlockReference,
        },
      };

      //console.log("ifthen response in update blocks ",response);

      return newBlocks;
    };

    if (!runnerMode) {
      setBlocks(blockUpdater);
    } else {
      return blockUpdater;
    }
  }

  function onNumberInputChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    if (e.target.validity.valid) {
      setErrorMessage('');
      handleChange(e);
    }
  }
};

export default IfElseBlock;
