import React, { FC, useState, useEffect } from 'react';
import Logo from '../../shared/Logo/Logo';
import MenuBar from '../../shared/MenuBar/MenuBar';
import Navigation from '../../shared/Navigation/Navigation';
import { useSignOut, useAuthUser } from 'react-auth-kit';
import { useCredit } from '../../context/CreditContext';
import PagePay from '../../containers/PagePay/PagePay';
import PageSignUp from '../../containers/PageSignUp/PageSignUp';
import { MdClose } from 'react-icons/md';
import { useNavigate, useLocation } from 'react-router-dom';
import ModalContainer from '../ModalContainer';
import SignupModalSequence from '../SignupModalSequence';

export interface MainNav2LoggedProps {}

function LogOutCheck() {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (window.location.hash === '#logout') {
      // Handle logout logic here
      console.log('Logging out...');
      signOut();
      navigate('/');
    }
  }, [location]);

  return null;
}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const user = useAuthUser();
  const location = useLocation();
  const urlPath = location.pathname.slice(1); // remove the initial forward slash

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showPayPage, setShowPayPage] = useState<boolean>(false);
  const [showSignupModalSequence, setShowSignupModalSequence] =
    useState<boolean>(false);

  const { credit } = useCredit();

  const userId = user()?.id;
  const trialAccount = user()?.trialAccount;

  const isTouchScreen = window.matchMedia('(pointer: coarse)').matches;

  const showBanner =
    credit !== undefined &&
    credit <= 0 &&
    userId &&
    !(trialAccount ? ['signup', 'login'] : 'buy-credits').includes(urlPath);

  // const { setReferAFriendModalOpen } = useContext(HeaderContext);

  return (
    <div className={`nc-MainNav2Logged relative z-10 ${'onTop '}`}>
      <div className='container py-5 relative flex justify-between items-start md:items-center space-x-4 xl:space-x-8'>
        <div className='flex flex-col md:flex-row justify-start flex-grow items-start md:items-center space-x-3'>
          <LogOutCheck />
          <Logo />
          <Navigation />
        </div>
        <div className='flex-shrink-0 flex mt-2 md:mt-0 items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1'>
          <div className='items-center xl:flex space-x-2'>
            {/* <div className="hidden sm:block h-6 border-l border-neutral-300 dark:border-neutral-6000"></div>
                <div className="flex">
                  <SwitchDarkMode />
                  <NotifyDropdown />
                </div>
                <div></div> */}
          </div>

          <div
            className={`flex items-center space-x-3${
              isTouchScreen ? '' : ' lg:hidden'
            }`}
          >
            <MenuBar />
          </div>
        </div>
      </div>
      {showBanner && (
        <div className='flex flex-col md:flex-row gap-2 items-center justify-center'>
          {trialAccount ? (
            <div>
              <h1 className='text-center text-2xl font-black'>
                It looks like you are enjoying Skillfusion!
              </h1>
              <h1 className='text-center text-2xl font-black'>
                Register an account to continue and get 100 free credits.
              </h1>
            </div>
          ) : (
            <h1 className='text-center text-2xl font-black'>
              It looks like you are enjoying Skillfusion! Buy more credits to
              continue.
            </h1>
          )}
          {trialAccount ? (
            <button
              onClick={() => {
                setShowModal(true);
                setShowSignupModalSequence(true);
              }}
              className='text-3xl font-black border-black border-2 p-1 px-2 rounded-xl text-align-center'
            >
              Register
            </button>
          ) : (
            <button
              onClick={() => {
                navigate(`/buy-credits/${user()?.id}`);
                //setShowPayPage(true);
              }}
              className='text-3xl font-black border-black border-2 p-1 px-2 rounded-xl text-align-center'
            >
              Buy more
            </button>
          )}
          {/*<h1 className='text-center text-3xl font-black'>or</h1>
            <button
              className='text-3xl font-black border-black border-2 p-1 px-2 rounded-xl text-align-center'
              onClick={() => setReferAFriendModalOpen(true)}
            >
              Refer a friend
            </button>*/}
        </div>
      )}
      {showModal && (
        <div className='share-modal fixed inset-0 flex justify-center'>
          <div className='absolute inset-0 bg-black opacity-50 z-10'></div>
          <div className='relative z-20'>
            <div className='m-auto flex items-center justify-center p-4'>
              <div className='bg-white p-4 rounded shadow-md m-auto max-w-[90vw] z-20'>
                <div className='flex flex-col gap-4'>
                  <div>
                    <button
                      className='sticky left-0 top-0'
                      onClick={() => {
                        setShowModal(false);
                        setShowSignupModalSequence(false);
                      }}
                    >
                      <MdClose />
                    </button>
                  </div>
                  <div
                    className={`${
                      showPayPage ? 'p-x-8' : 'flex p-8'
                    } justify-between gap-4 overflow-y-auto max-w-full max-h-[87vh]`}
                  >
                    {showSignupModalSequence && <SignupModalSequence />}
                    {showPayPage && (
                      <PagePay
                        inModal={true}
                        onPayment={() => setShowPayPage(false)}
                      />
                    )}
                  </div>
                  <div className='flex gap-2 items-center'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainNav2Logged;
