import React, { useContext } from "react";
import FuserContext from "../../context/FuserContext";

function SeoSection() {
  const {
    toolMetadata: { customMeta, customUrlSlug },
    updateToolMetadata,
  } = useContext(FuserContext);

  return (
    <div className="w-full gap-2 bg-blue-200 dark:bg-neutral-800 p-2 flex flex-col items-center justify-between rounded-lg shadow-xl">
      <h2>SEO Section</h2>
      <div className="flex flex-col gap-2 items-center">
        <label className="flex gap-2 items-center">
          Custom meta:
          <input
            className="py-1 px-2 border border-1 border-black"
            value={customMeta}
            onChange={({ target: { value } }) =>
              updateToolMetadata({ customMeta: value })
            }
          />
        </label>
        <label className="flex gap-2 items-center">
          Custom url slug:
          <input
            className="py-1 px-2 border border-1 border-black"
            value={customUrlSlug}
            onChange={({ target: { value } }) =>
              updateToolMetadata({ customUrlSlug: value })
            }
          />
        </label>
      </div>
    </div>
  );
}

export default SeoSection;
