import React, { FC, useContext, Fragment, useEffect, useState } from 'react';
import ButtonPrimary from '../shared/Button/ButtonPrimary';
import ButtonThird from '../shared/Button/ButtonThird';
import Checkbox from '../shared/Checkbox/Checkbox';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { FilterContext } from '../containers/PageSearch';
import { useNavigate } from 'react-router-dom';
import PageSearchContext from '../context/PageSearchContext';
import { Dialog, Popover, Transition } from '@headlessui/react';
import { AiOutlineClose, AiOutlineSortDescending } from 'react-icons/ai';
import { GoLightBulb, GoVerified } from 'react-icons/go';
import { BiWallet } from 'react-icons/bi';
import ButtonClose from '../shared/ButtonClose/ButtonClose';
import Slider from 'rc-slider';
import Radio from '../shared/Radio/Radio';
// DEMO DATA
// const typeOfSales = [
//   {
//     name: 'Free',
//   },
//   {
//     name: 'Premium',
//   },
//   {
//     name: 'New',
//   },
//   {
//     name: 'Verified Creator',
//   },
// ];

const typeFreePaid = [
  {
    name: 'Free',
  },
  {
    name: 'Paid',
  },
];

// const sortOrderRadios = [
//   { name: 'Recently listed', id: 'Recently-listed' },
//   { name: 'Ending soon', id: 'Ending-soon' },
//   { name: 'Price low - hight', id: 'Price-low-hight' },
//   { name: 'Price hight - low', id: 'Price-hight-low' },
//   { name: 'Most favorited', id: 'Most-favorited' },
// ];

interface TabFiltersProps {
  onFilterChange?: (filters: any) => void;
}

const TabFilters: FC<TabFiltersProps> = () => {
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  //
  // const [isVerifiedCreator, setIsVerifiedCreator] = useState(true);
  // const [rangePrices, setRangePrices] = useState([0.01, 100]);
  // const [fileTypesState, setfileTypesState] = useState<string[]>([]);
  // const [sortOrderStates, setSortOrderStates] = useState<string>('');
  const [saleTypeStates, setSaleTypeStates] = useState<string[]>([]);

  const filterContext = useContext(FilterContext);
  const {
    setSearchTerm,
    blockSortingMethod,
    setBlockSortingMethod,
    freePaid,
    setFreePaid,
    setSelectedCategoryId,
  } = useContext(PageSearchContext);

  if (!filterContext) {
    throw new Error('TabFilters must be used within a FilterContext.Provider');
  }

  // const { filters, setFilters } = filterContext;

  // useEffect(() => {
  //   setFilters({
  //     freePaid,
  //     rangePrices,
  //     saleTypeStates,
  //     sortOrderStates,
  //   });
  // }, [rangePrices, saleTypeStates, sortOrderStates, setFilters, freePaid]);
  //
  // const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  // const openModalMoreFilter = () => setisOpenMoreFilter(true);

  // const handleChangeSaleType = (checked: boolean, name: string) => {
  //   checked
  //     ? setSaleTypeStates([...saleTypeStates, name])
  //     : setSaleTypeStates(saleTypeStates.filter((i: any) => i !== name));
  // };

  const handleFreePaidType = (checked: boolean, name: string) => {
    checked
      ? setFreePaid([...freePaid, name])
      : setFreePaid(freePaid.filter((i: any) => i !== name));
  };

  useEffect(() => {
    if (window.location.search.includes('price=free')) {
      setFreePaid([...freePaid, 'Free']);
    }
  }, []);

  useEffect(() => {
    if (window.location.search.includes('price=paid')) {
      setFreePaid([...freePaid, 'Paid']);
    }
  }, []);

  const renderXClear = () => {
    return (
      <span className='flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer'>
        <AiOutlineClose className={`color-white h-3 w-3`} />
      </span>
    );
  };

  const renderTabsFreePaid = () => {
    return (
      <Popover className='relative'>
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none
               ${
                 open
                   ? '!border-primary-500 '
                   : 'border-neutral-300 dark:border-neutral-700'
               }
                ${
                  freePaid?.length
                    ? '!border-primary-500 bg-primary-50 text-primary-900'
                    : 'border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500'
                }
                `}
            >
              <GoLightBulb className={`color-white h-3 w-3`} />
              <span className='ml-2'>Free / Paid</span>
              {!freePaid?.length ? (
                <ChevronDownIcon className='w-4 h-4 ml-3' />
              ) : (
                <span onClick={() => setFreePaid([])}>{renderXClear()}</span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute z-40 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md'>
                <div className='overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700'>
                  <div className='relative flex flex-col px-5 py-6 space-y-5'>
                    {/*}
                    <Checkbox
                      name="Free & Paid"
                      label="Free & Paid"
                      defaultChecked={freePaid.includes("Free & Paid")}
                      onChange={(checked) =>
                        handleFreePaidType(checked, "Free & Paid")
                      }
                    />
                    
                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
                    */}
                    {typeFreePaid.map(item => (
                      <div
                        key={item.name}
                        className=''
                      >
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={freePaid.includes(item.name)}
                          onChange={checked =>
                            handleFreePaidType(checked, item.name)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className='p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between'>
                    <ButtonThird
                      onClick={() => {
                        close();
                        setFreePaid([]);
                      }}
                      sizeClass='px-4 py-2 sm:px-5'
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass='px-4 py-2 sm:px-5'
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  // const renderTabsTypeOfSales = () => {
  //   return (
  //     <Popover className='relative'>
  //       {({ open, close }) => (
  //         <>
  //           <Popover.Button
  //             className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none
  //              ${
  //                open
  //                  ? '!border-primary-500 '
  //                  : 'border-neutral-300 dark:border-neutral-700'
  //              }
  //               ${
  //                 saleTypeStates.length
  //                   ? '!border-primary-500 bg-primary-50 text-primary-900'
  //                   : 'border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500'
  //               }
  //               `}
  //           >
  //             <GoLightBulb className={`color-white h-3 w-3`} />
  //             <span className='ml-2'>Sale Types</span>
  //             {!saleTypeStates.length ? (
  //               <ChevronDownIcon className='w-4 h-4 ml-3' />
  //             ) : (
  //               <span onClick={() => setSaleTypeStates([])}>
  //                 {renderXClear()}
  //               </span>
  //             )}
  //           </Popover.Button>
  //           <Transition
  //             as={Fragment}
  //             enter='transition ease-out duration-200'
  //             enterFrom='opacity-0 translate-y-1'
  //             enterTo='opacity-100 translate-y-0'
  //             leave='transition ease-in duration-150'
  //             leaveFrom='opacity-100 translate-y-0'
  //             leaveTo='opacity-0 translate-y-1'
  //           >
  //             <Popover.Panel className='absolute z-40 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md'>
  //               <div className='overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700'>
  //                 <div className='relative flex flex-col px-5 py-6 space-y-5'>
  //                   <Checkbox
  //                     name='All Sale Types'
  //                     label='All Sale Types'
  //                     defaultChecked={saleTypeStates.includes('All Sale Types')}
  //                     onChange={checked =>
  //                       handleChangeSaleType(checked, 'All Sale Types')
  //                     }
  //                   />
  //                   <div className='w-full border-b border-neutral-200 dark:border-neutral-700' />
  //                   {typeOfSales.map(item => (
  //                     <div
  //                       key={item.name}
  //                       className=''
  //                     >
  //                       <Checkbox
  //                         name={item.name}
  //                         label={item.name}
  //                         defaultChecked={saleTypeStates.includes(item.name)}
  //                         onChange={checked =>
  //                           handleChangeSaleType(checked, item.name)
  //                         }
  //                       />
  //                     </div>
  //                   ))}
  //                 </div>
  //                 <div className='p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between'>
  //                   <ButtonThird
  //                     onClick={() => {
  //                       close();
  //                       setSaleTypeStates([]);
  //                     }}
  //                     sizeClass='px-4 py-2 sm:px-5'
  //                   >
  //                     Clear
  //                   </ButtonThird>
  //                   <ButtonPrimary
  //                     onClick={close}
  //                     sizeClass='px-4 py-2 sm:px-5'
  //                   >
  //                     Apply
  //                   </ButtonPrimary>
  //                 </div>
  //               </div>
  //             </Popover.Panel>
  //           </Transition>
  //         </>
  //       )}
  //     </Popover>
  //   );
  // };

  // const renderTabsSortOrder = () => {
  //   return (
  //     <Popover className='relative'>
  //       {({ open, close }) => (
  //         <>
  //           <Popover.Button
  //             className={`flex items-center justify-center px-4 py-2 text-sm border rounded-full focus:outline-none
  //             ${open ? '!border-primary-500 ' : ''}
  //               ${
  //                 sortOrderStates.length
  //                   ? '!border-primary-500 bg-primary-50 text-primary-900'
  //                   : 'border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500'
  //               }
  //               `}
  //           >
  //             <AiOutlineSortDescending className={`color-white h-3 w-3`} />
  //             <span className='ml-2'>
  //               {sortOrderStates
  //                 ? sortOrderRadios.filter(i => i.id === sortOrderStates)[0]
  //                     .name
  //                 : 'Sort order'}
  //             </span>
  //             {!sortOrderStates.length ? (
  //               <ChevronDownIcon className='w-4 h-4 ml-3' />
  //             ) : (
  //               <span onClick={() => setSortOrderStates('')}>
  //                 {renderXClear()}
  //               </span>
  //             )}
  //           </Popover.Button>
  //           <Transition
  //             as={Fragment}
  //             enter='transition ease-out duration-200'
  //             enterFrom='opacity-0 translate-y-1'
  //             enterTo='opacity-100 translate-y-0'
  //             leave='transition ease-in duration-150'
  //             leaveFrom='opacity-100 translate-y-0'
  //             leaveTo='opacity-0 translate-y-1'
  //           >
  //             <Popover.Panel className='absolute z-40 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md'>
  //               <div className='overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700'>
  //                 <div className='relative flex flex-col px-5 py-6 space-y-5'>
  //                   {sortOrderRadios.map(item => (
  //                     <Radio
  //                       id={item.id}
  //                       key={item.id}
  //                       name='radioNameSort'
  //                       label={item.name}
  //                       defaultChecked={sortOrderStates === item.id}
  //                       onChange={setSortOrderStates}
  //                     />
  //                   ))}
  //                 </div>
  //                 <div className='p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between'>
  //                   <ButtonThird
  //                     onClick={() => {
  //                       close();
  //                       setSortOrderStates('');
  //                     }}
  //                     sizeClass='px-4 py-2 sm:px-5'
  //                   >
  //                     Clear
  //                   </ButtonThird>
  //                   <ButtonPrimary
  //                     onClick={close}
  //                     sizeClass='px-4 py-2 sm:px-5'
  //                   >
  //                     Apply
  //                   </ButtonPrimary>
  //                 </div>
  //               </div>
  //             </Popover.Panel>
  //           </Transition>
  //         </>
  //       )}
  //     </Popover>
  //   );
  // };

  // OK
  // const renderTabsPriceRage = () => {
  //   return (
  //     <Popover className='relative'>
  //       {({ open, close }) => (
  //         <>
  //           <Popover.Button
  //             className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-900 focus:outline-none `}
  //           >
  //             <BiWallet className={`color-white h-3 w-3`} />
  //             <span className='ml-2'>{`${rangePrices[0]} USD - ${rangePrices[1]} USD`}</span>
  //             {renderXClear()}
  //           </Popover.Button>
  //           <Transition
  //             as={Fragment}
  //             enter='transition ease-out duration-200'
  //             enterFrom='opacity-0 translate-y-1'
  //             enterTo='opacity-100 translate-y-0'
  //             leave='transition ease-in duration-150'
  //             leaveFrom='opacity-100 translate-y-0'
  //             leaveTo='opacity-0 translate-y-1'
  //           >
  //             <Popover.Panel className='absolute z-40 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 '>
  //               <div className='overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700'>
  //                 <div className='relative flex flex-col px-5 py-6 space-y-8'>
  //                   <div className='space-y-5'>
  //                     <span className='font-medium'>Price range</span>
  //                     <Slider
  //                       range
  //                       min={0.01}
  //                       max={10}
  //                       step={0.01}
  //                       defaultValue={[rangePrices[0], rangePrices[1]]}
  //                       allowCross={false}
  //                       onChange={(_input: number | number[]) =>
  //                         setRangePrices(_input as number[])
  //                       }
  //                     />
  //                   </div>

  //                   <div className='flex justify-between space-x-5'>
  //                     <div>
  //                       <label
  //                         htmlFor='minPrice'
  //                         className='block text-sm font-medium text-neutral-700 dark:text-neutral-300'
  //                       >
  //                         Min price
  //                       </label>
  //                       <div className='mt-1 relative rounded-md'>
  //                         <span className='absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-sm'>
  //                           USD
  //                         </span>
  //                         <input
  //                           type='text'
  //                           name='minPrice'
  //                           disabled
  //                           id='minPrice'
  //                           className='block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent'
  //                           value={rangePrices[0]}
  //                         />
  //                       </div>
  //                     </div>
  //                     <div>
  //                       <label
  //                         htmlFor='maxPrice'
  //                         className='block text-sm font-medium text-neutral-700 dark:text-neutral-300'
  //                       >
  //                         Max price
  //                       </label>
  //                       <div className='mt-1 relative rounded-md'>
  //                         <span className='absolute inset-y-0 right-4 flex items-center pointer-events-none text-neutral-500 sm:text-sm'>
  //                           USD
  //                         </span>
  //                         <input
  //                           type='text'
  //                           disabled
  //                           name='maxPrice'
  //                           id='maxPrice'
  //                           className='block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent'
  //                           value={rangePrices[1]}
  //                         />
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div className='p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between'>
  //                   <ButtonThird
  //                     onClick={() => {
  //                       setRangePrices([0.01, 10]);
  //                       close();
  //                     }}
  //                     sizeClass='px-4 py-2 sm:px-5'
  //                   >
  //                     Clear
  //                   </ButtonThird>
  //                   <ButtonPrimary
  //                     onClick={close}
  //                     sizeClass='px-4 py-2 sm:px-5'
  //                   >
  //                     Apply
  //                   </ButtonPrimary>
  //                 </div>
  //               </div>
  //             </Popover.Panel>
  //           </Transition>
  //         </>
  //       )}
  //     </Popover>
  //   );
  // };

  // OK
  // const renderTabVerifiedCreator = () => {
  //   return (
  //     <div
  //       className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer  ${
  //         isVerifiedCreator
  //           ? 'border-primary-500 bg-primary-50 text-primary-900'
  //           : 'border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500'
  //       }`}
  //       onClick={() => setIsVerifiedCreator(!isVerifiedCreator)}
  //     >
  //       <GoVerified className={`color-white w-3 h-3`} />
  //       <span className='line-clamp-1 ml-2'>Verified creator</span>
  //       {isVerifiedCreator && renderXClear()}
  //     </div>
  //   );
  // };

  // OK
  // const renderMoreFilterItem = (
  //   data: {
  //     name: string;
  //     description?: string;
  //     defaultChecked?: boolean;
  //   }[]
  // ) => {
  //   const list1 = data.filter((_, i) => i < data.length / 2);
  //   const list2 = data.filter((_, i) => i >= data.length / 2);
  //   return (
  //     <div className='grid grid-cols-2 gap-8'>
  //       <div className='flex flex-col space-y-5'>
  //         {list1.map(item => (
  //           <Checkbox
  //             key={item.name}
  //             name={item.name}
  //             subLabel={item.description}
  //             label={item.name}
  //             defaultChecked={item.defaultChecked}
  //           />
  //         ))}
  //       </div>
  //       <div className='flex flex-col space-y-5'>
  //         {list2.map(item => (
  //           <Checkbox
  //             key={item.name}
  //             name={item.name}
  //             subLabel={item.description}
  //             label={item.name}
  //             defaultChecked={item.defaultChecked}
  //           />
  //         ))}
  //       </div>
  //     </div>
  //   );
  // };

  // FOR RESPONSIVE MOBILE
  // const renderTabMobileFilter = () => {
  //   return (
  //     <div className='flex-shrink-0'>
  //       <div
  //         className={`flex flex-shrink-0 items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-900 focus:outline-none cursor-pointer`}
  //         onClick={openModalMoreFilter}
  //       >
  //         <span>
  //           <span className='hidden sm:inline'>SkillFusion AI</span> filters (3)
  //         </span>
  //         {renderXClear()}
  //       </div>

  //       <Transition
  //         appear
  //         show={isOpenMoreFilter}
  //         as={Fragment}
  //       >
  //         <Dialog
  //           as='div'
  //           className='fixed inset-0 z-50 overflow-y-auto'
  //           onClose={closeModalMoreFilter}
  //         >
  //           <div className='min-h-screen text-center'>
  //             <Transition.Child
  //               as={Fragment}
  //               enter='ease-out duration-300'
  //               enterFrom='opacity-0'
  //               enterTo='opacity-100'
  //               leave='ease-in duration-200'
  //               leaveFrom='opacity-100'
  //               leaveTo='opacity-0'
  //             >
  //               <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60' />
  //             </Transition.Child>

  //             {/* This element is to trick the browser into centering the modal contents. */}
  //             <span
  //               className='inline-block h-screen align-middle'
  //               aria-hidden='true'
  //             >
  //               &#8203;
  //             </span>
  //             <Transition.Child
  //               className='inline-block py-8 h-screen w-full'
  //               enter='ease-out duration-300'
  //               enterFrom='opacity-0 scale-95'
  //               enterTo='opacity-100 scale-100'
  //               leave='ease-in duration-200'
  //               leaveFrom='opacity-100 scale-100'
  //               leaveTo='opacity-0 scale-95'
  //             >
  //               <div className='inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full'>
  //                 <div className='relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center'>
  //                   <Dialog.Title
  //                     as='h3'
  //                     className='text-lg font-medium leading-6 text-gray-900'
  //                   >
  //                     SkillFusion AI filters
  //                   </Dialog.Title>
  //                   <span className='absolute left-3 top-3'>
  //                     <ButtonClose onClick={closeModalMoreFilter} />
  //                   </span>
  //                 </div>

  //                 <div className='flex-grow overflow-y-auto'>
  //                   <div className='px-8 md:px-10 divide-y divide-neutral-200 dark:divide-neutral-800'>
  //                     {/* --------- */}
  //                     {/* ---- */}
  //                     <div className='py-7'>
  //                       <h3 className='text-xl font-medium'>Sale Types</h3>
  //                       <div className='mt-6 relative '>
  //                         {renderMoreFilterItem(typeOfSales)}
  //                       </div>
  //                     </div>
  //                     {/* --------- */}
  //                     {/* ---- */}

  //                     {/* --------- */}
  //                     {/* ---- */}
  //                     <div className='py-7'>
  //                       <h3 className='text-xl font-medium'>Range Prices</h3>
  //                       <div className='mt-6 relative '>
  //                         <div className='relative flex flex-col space-y-8'>
  //                           <div className='space-y-5'>
  //                             <Slider
  //                               range
  //                               className='text-red-400'
  //                               min={0}
  //                               max={2000}
  //                               defaultValue={[0, 1000]}
  //                               allowCross={false}
  //                               onChange={(_input: number | number[]) =>
  //                                 setRangePrices(_input as number[])
  //                               }
  //                             />
  //                           </div>

  //                           <div className='flex justify-between space-x-5'>
  //                             <div>
  //                               <label
  //                                 htmlFor='minPrice'
  //                                 className='block text-sm font-medium text-neutral-700 dark:text-neutral-300'
  //                               >
  //                                 Min price
  //                               </label>
  //                               <div className='mt-1 relative rounded-md'>
  //                                 <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
  //                                   <span className='text-neutral-500 sm:text-sm'>
  //                                     $
  //                                   </span>
  //                                 </div>
  //                                 <input
  //                                   type='text'
  //                                   name='minPrice'
  //                                   disabled
  //                                   id='minPrice'
  //                                   className='focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900'
  //                                   value={rangePrices[0]}
  //                                 />
  //                               </div>
  //                             </div>
  //                             <div>
  //                               <label
  //                                 htmlFor='maxPrice'
  //                                 className='block text-sm font-medium text-neutral-700 dark:text-neutral-300'
  //                               >
  //                                 Max price
  //                               </label>
  //                               <div className='mt-1 relative rounded-md'>
  //                                 <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
  //                                   <span className='text-neutral-500 sm:text-sm'>
  //                                     $
  //                                   </span>
  //                                 </div>
  //                                 <input
  //                                   type='text'
  //                                   disabled
  //                                   name='maxPrice'
  //                                   id='maxPrice'
  //                                   className='focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900'
  //                                   value={rangePrices[1]}
  //                                 />
  //                               </div>
  //                             </div>
  //                           </div>
  //                         </div>
  //                       </div>
  //                     </div>

  //                     {/* --------- */}
  //                     {/* ---- */}
  //                     <div className='py-7'>
  //                       <h3 className='text-xl font-medium'>Sort Order</h3>
  //                       <div className='mt-6 relative '>
  //                         <div className='relative flex flex-col space-y-5'>
  //                           {sortOrderRadios.map(item => (
  //                             <Radio
  //                               id={item.id}
  //                               key={item.id}
  //                               name='radioNameSort'
  //                               label={item.name}
  //                               defaultChecked={sortOrderStates === item.id}
  //                               onChange={setSortOrderStates}
  //                             />
  //                           ))}
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>

  //                 <div className='p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between'>
  //                   <ButtonThird
  //                     onClick={() => {
  //                       setRangePrices([0.01, 10]);
  //                       setSaleTypeStates([]);
  //                       setfileTypesState([]);
  //                       setSortOrderStates('');
  //                       closeModalMoreFilter();
  //                     }}
  //                     sizeClass='px-4 py-2 sm:px-5'
  //                   >
  //                     Clear
  //                   </ButtonThird>
  //                   <ButtonPrimary
  //                     onClick={closeModalMoreFilter}
  //                     sizeClass='px-4 py-2 sm:px-5'
  //                   >
  //                     Apply
  //                   </ButtonPrimary>
  //                 </div>
  //               </div>
  //             </Transition.Child>
  //           </div>
  //         </Dialog>
  //       </Transition>
  //     </div>
  //   );
  // };

  const navigate = useNavigate();

  const [searchTermsSelectValue, setSearchTermsSelectValue] =
    useState<any>('selectheadervalue');

  const recommendedSearchTerms = [
    { displayText: 'Writer tools', searchTerms: 'Writer tools' },
    { displayText: 'Business', searchTerms: 'Business' },
    { displayText: 'Marketing', searchTerms: 'Marketing' },
    { displayText: 'Show all tools', searchTerms: '' },
  ];

  const searchButtonClass =
    'px-4 py-2 text-sm rounded-full border border-neutral-300 hover:border-neutral-400 active:border-neutral-500';

  const sortSelect = (deviceType: string) => (
    <select
      className='text-sm rounded-full border border-neutral-300 hover:border-neutral-400 active:border-primary-500 focus:border-1 focus:border-primary-500'
      value={blockSortingMethod}
      onChange={({ target: { value } }) => setBlockSortingMethod(value)}
    >
      {deviceType === 'mobile' && (
        <option
          value=''
          disabled={true}
        >
          Sort by
        </option>
      )}
      <option value='recent-api-usage-descending'>
        Most used (last 30 days)
      </option>
      <option value='publish-date-descending'>Newest</option>
      {/* <option value='creation-date-descending'>Newest</option> */}
      <option value='number-of-downloads-descending'>Most purchased</option>
      <option value='number-of-views-descending'>Most viewed</option>
      <option value='rating'>Rating</option>
    </select>
  );

  // console.log(new URLSearchParams(window.location.search));
  return (
    <div className='flex justify-center mt-4 lg:space-x-4'>
      {/* FOR DESKTOP */}
      <div className='hidden lg:flex lg:items-center space-x-4'>
        <span>Sort by:</span>
        {sortSelect('desktop')}
        <span>Search for:</span>
        {recommendedSearchTerms.map(({ displayText, searchTerms }) => (
          <button
            key={searchTerms}
            className={searchButtonClass + (!searchTerms ? ' ml-8' : '')}
            onClick={() => {
              setSearchTerm(searchTerms);
              setSelectedCategoryId('all');
              const params = new URLSearchParams(window.location.search);
              params.set('q', searchTerms);
              params.delete('c');
              navigate(`/page-search?${params}`);
            }}
          >
            {displayText}
          </button>
        ))}
        {renderTabsFreePaid()}
        {/* {renderTabsPriceRage()}
        {renderTabsTypeOfSales()}
        {renderTabsSortOrder()}
  {renderTabVerifiedCreator()} */}
      </div>

      {/* FOR RESPONSIVE MOBILE */}
      <div className='flex flex-wrap gap-y-4 justify-center height-auto lg:hidden space-x-4 w-full'>
        {renderTabsFreePaid()}
        {sortSelect('mobile')}

        <select
          className='text-sm rounded-full border border-neutral-300 hover:border-neutral-400 active:border-primary-500 focus:border-1 focus:border-primary-500'
          value={searchTermsSelectValue}
          onChange={({ target: { value: searchTerms } }) => {
            setSearchTermsSelectValue(searchTerms);
            setSearchTerm(searchTerms);
            setSelectedCategoryId('all');
            navigate(`/page-search?q=${searchTerms}`);
          }}
        >
          <option
            value='selectheadervalue'
            disabled={true}
          >
            Search for
          </option>
          {recommendedSearchTerms.map(({ displayText, searchTerms }) => (
            <option
              key={searchTerms}
              value={searchTerms}
            >
              {displayText}
            </option>
          ))}
        </select>

        {/*  {renderTabMobileFilter()}
        {renderTabVerifiedCreator()} */}
      </div>
    </div>
  );
};

export default TabFilters;
