import React, { FC } from "react";
import { Tooltip }  from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'


const MyToolTips: FC<{ content: string; tipID: string; datatooltipplace:string }> = ({ content, tipID, datatooltipplace="bottom" }) => {
  
  const tooltipID = `my-tooltip-${tipID}`;

  return (
    <span style={{zIndex:1}}>
      <style>{`
        #MyToolTips p {
          margin: 10px;
          z-index:1;
        }
      `}</style>
      <Tooltip id={tooltipID} style={{ maxWidth: "50%", zIndex:99}} />
      <span 
        data-tooltip-html={`<div class="text-base" id="MyToolTips">${content}</div>`}
        data-tooltip-place=  {datatooltipplace as any}
        data-tooltip-id={tooltipID}
        className="rounded-full border-2 border-slate-500 w-fit p-1"
      >
        ?
      </span>
    </span>
  );
};

export default MyToolTips;
