import { useNavigate } from 'react-router-dom';
import { YoutubeAccordionInfo } from '../../containers/NftDetailPage/AccordionInfo';
import React, { FC } from 'react';
import SectionFounder from './SectionFounder';
import SectionStatistic from './SectionStatistic';
import { Helmet } from 'react-helmet';
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from '../../components/BgGlassmorphism/BgGlassmorphism';
import BackgroundSection from '../../components/BackgroundSection/BackgroundSection';
import SectionHero, { VideoHero } from './SectionHero';
import SectionClientSay from '../../components/SectionClientSay/SectionClientSay';

export interface PageAboutProps {
  className?: string;
}

const MakerHelp: FC<PageAboutProps> = ({ className = '' }) => {
  const navigate = useNavigate();

  const handleLinkClick = (event: any) => {
    // Prevent the default link behavior
    event.preventDefault();
    const newPath = event.currentTarget.getAttribute('href');
    // Navigate to the link href
    navigate(newPath);
  };

  let description_block = `Example Videos:

  https://www.youtube.com/watch?v=PtSLqlxxvjI
  
  `;

  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id='PageAbout'
    >
      <Helmet>
        <title>About || Skillfusion.ai</title>
        <link
          rel='canonical'
          href='https://skillfusion.ai/quality-help'
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>
        <VideoHero
          videoUrl={
            'https://www.youtube.com/embed/KHHGixkWABY?si=tyoTZrVtW8b5kPt6'
          }
          heading='Making Quality Tools'
          btnText=''
          rightImg=''
          leftText={false}
          subHeading="Quality tools can: <br/>
          <ul style='list-style: inside;'>
          <li>Produce better output</li>
          <li>Solve more complex problems</li>
          <li>Output in a style that is unique to your tool</li>
          <li>Produce longer output than you can get form a single prompt</li>
          <li>Asks the right questions of the user</li>
          </ul><br/> <br/>
          "
        />

        <VideoHero
          videoUrl={''}
          heading='Prompts'
          btnText=''
          rightImg=''
          leftText={true}
          subHeading='
          Break your task down in to multiple prompts to improve the 
          quality of the output. <br/> <br/>
          
          Consider each prompt a single thought. More complicated tasks need more thoughts or steps to solve them. <br/> <br/>

          '
        />

        <VideoHero
          videoUrl={''}
          heading='Examples'
          btnText=''
          rightImg=''
          leftText={false}
          subHeading='          
          Examples show the AI how to answer the question and the format the response should be in. Including the type of language and the length of the response. <br/> <br/> 
          
          - show the AI how to answer the request by including example inputs and outputs <br/>
          - show the AI how to format its answer using examples with the correct format <br/> <br/>
          
          If you have more examples or information available than you could fit in a single prompt, then consider using the Embeddings Block. It can retrieve the most relevant examples for that user.  <br/> <br/>

          '
        />

        <VideoHero
          videoUrl={''}
          heading='Longer Output'
          btnText=''
          rightImg=''
          leftText={true}
          subHeading='          
          You can ask the AI to produce a plan in the format of a numbered list.  <br/>
          Then use a Processing Block to break the list in to separate items. <br/>
          Then pass those items in to the next prompt. <br/>
          Like a chapter list for a book, or a 5 point plan for a web article. <br/>
          E.g. any prompt that references the 5 point plan will run 5 times. 
   
          '
        />
      </div>
    </div>
  );
};

export default MakerHelp;
