const { isArray } = Array;

export function getOrdinalSuffix(number: number) {
  let suffix = 'th';
  const lastDigit = number % 10;
  const lastTwoDigits = number % 100;

  if (lastTwoDigits < 11 || lastTwoDigits > 19) {
    switch (lastDigit) {
      case 1:
        suffix = 'st';
        break;
      case 2:
        suffix = 'nd';
        break;
      case 3:
        suffix = 'rd';
        break;
    }
  }

  return number + suffix;
}

export const truncateAfter = (numberOfChars: number, string: string) => {
  // console.log('truncating', string, 'by', numberOfChars);
  return string.length > numberOfChars
    ? string.slice(0, numberOfChars) + ' ...'
    : string;
};

export function encodeHtmlEntities(str: string) {
  return str.replace(
    /[\u00A0-\u9999<>&]/gim,
    character => `&#${character.charCodeAt(0)};`
  );
}

export const escapeRegExpPattern = (pattern: string) => {
  return pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export function format_newlines(input: string | any[], level = 0): string {
  if (typeof input === 'string') {
    return input.replace(/\n/g, '<br>');
  } else if (isArray(input)) {
    if (input.length === 0) {
      return ''; // If the array is empty, return an empty string
    }
    if (typeof input[0] === 'string' || isArray(input[0])) {
      // If the first element is a string or an array, assume it's a multi-dimensional array
      const formatted = input.map(item => format_newlines(item, level + 1));
      let responseTemp = '<table>';

      formatted.forEach(row => {
        responseTemp += '<tr>';
        if (isArray(row)) {
          responseTemp += row.map(item => `<td>${item}</td>`).join('');
        } else {
          responseTemp += `<td>${row}</td>`;
        }
        responseTemp += '</tr>';
      });

      responseTemp += '</table>';
      return responseTemp;
    } else {
      // If the first element is not a string or an array, assume it's a one-dimensional array
      return input.map(item => format_newlines(item, level + 1)).join('<br>');
    }
  } else {
    return String(input); // If the input is neither a string nor an array, convert it to a string and return it
  }
}

export function convertHTMLtableToText(htmlTable: string) {
  // Extract the table headings and rows using regular expressions
  const tableRegex = /<table[^>]*>(.*?)<\/table>/gs;
  const rowRegex = /<tr[^>]*>(.*?)<\/tr>/gs;
  const cellRegex = /<t[dh][^>]*>(.*?)<\/t[dh]>/gs;

  let result = '';

  // Find the table within the HTML string
  const tableMatch = tableRegex.exec(htmlTable);
  if (tableMatch) {
    // Extract the table rows
    const tableRows = tableMatch[1].match(rowRegex);

    if (tableRows === null) return '';
    // Process each row
    for (let i = 0; i < tableRows.length; i++) {
      const row = tableRows[i];

      // Extract the cells within the row
      const cells = row.match(cellRegex);

      if (cells === null) continue;

      // Skip the first row (heading row)
      if (i > 0) {
        // Extract the heading row
        const headingRow = tableRows[0];
        const headingCells = headingRow.match(cellRegex);

        if (headingCells === null) continue;

        // Process each cell
        for (let j = 0; j < cells.length; j++) {
          const cell = cells[j];

          // Extract the content of the cell
          const cellContent = cell.replace(/<[^>]*>/g, '');

          // Prepend the corresponding heading (if it exists)
          const heading = headingCells[j];

          if (heading === null) continue;

          const headingContent = heading ? heading.replace(/<[^>]*>/g, '') : '';

          // Append the text to the result
          result += `${headingContent}: ${cellContent}, `;
        }
        result = result.slice(0, -2); //remove trailing comma and space
        // Add a newline after each row
        result += '\n';
      }
    }
  }

  return result;
}

export const convertNumbThousand = (x?: number): string => {
  if (!x) {
    return '0';
  }
  return x.toLocaleString('en-US');
};
