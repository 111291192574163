import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from '../../components/BgGlassmorphism/BgGlassmorphism';
import SectionHero, { VideoHero } from './SectionHero';
import toolsHelp from '../../images/tools-help.png';

export interface PageAboutProps {
  className?: string;
}

const ToolsHelp: FC<PageAboutProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id='PageAbout'
    >
      <Helmet>
        <title>About || Skillfusion.ai</title>
        <link
          rel='canonical'
          href='https://skillfusion.ai/tools-help'
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>
        <VideoHero
          videoUrl={
            'https://www.youtube.com/embed/MqTmH4D31Qw?si=oa7-eQzoL9PkbiXq'
          }
          heading='How to use our tools'
          btnText=''
          rightImg={''}
          leftText={false}
          subHeading='<p>This video will give you an overview on how to use the tools on our site</p>
          <br>
          <p>A transcript of the video is below</p>'
        />

        <SectionHero
          rightImg={toolsHelp}
          mediumOrSmallerScreenImg={''}
          heading='Transcript'
          btnText=''
          leftText={true}
          subHeading="<p>Hello there and welcome to Skillfusion. And so in this video, I'm going to show you how to use some of the different tools on our website. We've got about 118 tools at the moment. And it's a marketplace where anyone can come on and make their own tools on the website. And all the tools are what we call block based or multi step tools, so that allows us to kind of get more from the AI. So they go through a sequence of steps to get the final output. Some of them up to 60 steps long. And that's how we're able to have tools like this, that can write entire books from a single idea. So you put in your idea, it'll go through a bunch of steps and your finished book will come out at the other end, there's a whole list of other tools on there as well.</p>
          <br>
          <p>So that's all the tools, which you can see, we've got business, marketing, images, audio tools and so on and so on. So there's quite a few different tools there and they all use the same approach. They all work through a sequence of blocks. All you need to do is click on the tool you're interested in.</p>
          <br>
          <p>You have to sign up to the site and then most of the tools are free to have a play with until your free credits run out. And once you've clicked the use tool, you'll be presented with a sequence of blocks like this. It can look a bit complicated, but, you literally just answer the questions and do what the tool tells you to do.</p>
          <br>
          <p>So if you get lost, it will always tell you here at the top which block you're on. So we're on block zero now. I'll start with this one, the shorter one. So let's see, it says, what would you like your picture to look like? And it would draw it four times. So what this does is it actually comes up with four detailed descriptions of possible pictures based on your simple command and then it will draw the four images for you. Let's see, it's just kind of a quicker way to get four images at a time instead of one image at a time, basically. And it means you don't have to make the prompt. So you can see it says now we're on block three.</p>
          <br>
          <p>A few different types of blocks as well, which we'll come across some of them, so these ones we call prompt blocks, talk to the AI and then image blocks, store images, obviously audio blocks will turn text into an audio file, and a few other types as well. Sometimes you can't see all the blocks. So the ones you don't need to see are hidden basically.</p>
          <br>
          <p>What you can do, sometimes the prompt will show you what it's done and sometimes it won't, if the creator felt like he didn't need to see it really. So there's the, it's come up with like four descriptions there. And it's drawn the four images here. One of them's not loaded properly, it just pans out a little bit slower.</p>
          <br>
          <p>Here we go. So you get them to draw four pictures for us and then you can click on them and save them. So yeah, this is just a basic tool, one of our most basic tools is an Image Generator. So yeah, that's what you can do. So if you do it in a larger tool like this, this one's going to write a 10,000 word story.</p>
          <br>
          <p>You can see it goes through a lot of steps. Most of them are just Prompts so it's just a case of waiting for it to get to the end. Every now and then it might pop up with a question. So one of the book writers will ask you how many characters you want and how many chapters you want things like that and then I'll use the image generator as well to draw a picture like a cover for your story and things like that. More advanced usage if you're interested is with these prompts the ai does, so you can start running it, pause it if you wanted to if you wanted to go if you want to use it again. You literally just go back to the first block and change your answer and it'll just carry on from there.</p>
          <br>
          <p>So for example, here, I've already read it previously and asked it to write a sci fi story, which is my go to when I'm testing the stories and things like that. So you'll see. It generated a title for the story. Well, I can do as I go in and I can edit that title and press update and the tool will resume from there, from, from the bit that I edited.</p>
          <br>
          <p>What will happen is because I've given it a different title for the story, it will write a different story based on that title, and then it comes up with a plan for the story, and again, I can have a look at that, I could pause it if I wanted to just kind of buy myself more time, so I could pause it once it's done, block 3, go and edit the story idea.</p>
          <br>
          <p>And again, that will affect the story that it's writing. So yeah, you can edit the outputs on some of them. Same again, the characters for the story. I could change them. So if you already had kind of the plan for a story a short story, for example. And this one, or a full length story on some of the other tools.</p>
          <br>
          <p>And then, yeah, you can just go in and kind of replace what the AI is coming up with or mix your idea with the AI's idea to get your own unique book. And then other tools, for example, so this one writes web content to help you rank higher on Google. Again, it'll write a plan for the article. You can just go in and you can kind of alter the plan to improve the quality or add in your own ideas.</p>
          <br>
          <p>So yeah, you can edit the prompts, so keep that in mind. And the other thing to keep in mind with some of the large tools like this is it will go in the loop. So it's because this is writing like full length stories, it's going to make 60,000 word stories. If you specify 10 chapters, it gives you three ideas. You choose which idea you want.</p>
          <br>
          <p>So it'll run through this as you go. As it's working its way through it will write that chapter. So we write chapter one and then we'll come back to block 12 and give you three ideas for the next chapter. So we'll go through the loop 10 times. So yeah, don't worry, if it does that, it's intentional. It's just how to get more output from the tool basically. So yeah, it's intentional. I'll just keep doing it until it's in the full 10 or 12 chapters. So all you need to do is just kind of do as the AI tells you to, as you'll see, it's kind of with our tools, it's not chatbots as such.</p>
          <br>
          <p>It's all the steps and stuff and all the prompts for the AI been predefined by the person who made the tool and it's been tested to work. It's high quality, reliable output. Yeah, that's pretty much it. So I hope that made sense. So for, yeah, for the majority of tools, you literally just answer the question on the first block and just let it do its thing and if it pops up with another question, just answer that one and that's it, that's how you use the tools and if you have any problems, there's a little chat here, which will message us as well on live chat. So yeah, if you get stuck, let us know, hopefully, and that's enough to get you started.</p>"
        />
      </div>
    </div>
  );
};

export default ToolsHelp;
