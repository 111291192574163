import { useRef } from 'react';

const useCancellablePause = () => {
  const cancelToken = useRef<any>(null);

  const pause = (duration: number) =>
    new Promise((resolve, reject) => {
      const id = setTimeout(() => {
        cancelToken.current = null;
        resolve(true);
      }, duration);
      cancelToken.current = () => {
        clearTimeout(id);
        cancelToken.current = null;
        reject(new Error('Cancelled'));
      };
    });

  const cancelPause = () => {
    if (cancelToken.current) cancelToken.current();
  };

  return { pause, cancelPause };
};

export default useCancellablePause;
