import zapierBlockImg2 from 'images/zapier-creator-3.jpg';
import { useNavigate } from 'react-router-dom';
import { YoutubeAccordionInfo } from '../../containers/NftDetailPage/AccordionInfo';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import BgGlassmorphism from '../../components/BgGlassmorphism/BgGlassmorphism';
import SectionHero, { VideoHero } from './SectionHero';
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
// import SectionFounder from './SectionFounder';
// import SectionStatistic from './SectionStatistic';
// import BackgroundSection from '../../components/BackgroundSection/BackgroundSection';
// import SectionClientSay from '../../components/SectionClientSay/SectionClientSay';

export interface PageAboutProps {
  className?: string;
}

const ChatHelp: FC<PageAboutProps> = ({ className = '' }) => {
  const navigate = useNavigate();

  const handleLinkClick = (event: any) => {
    // Prevent the default link behavior
    event.preventDefault();
    const newPath = event.currentTarget.getAttribute('href');
    // Navigate to the link href
    navigate(newPath);
  };

  const description_block = `Example Videos:

  https://www.youtube.com/watch?v=PtSLqlxxvjI
  
  `;

  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id='PageAbout'
    >
      <Helmet>
        <title>About || Skillfusion.ai</title>
        <link
          rel='canonical'
          href='https://skillfusion.ai/maker-help'
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>

      <VideoHero
          videoUrl={
            'https://blog.skillfusion.ai/wp-content/uploads/chat-option.jpg'
          }
          heading='How To Make Chat Bots'
          btnText=''
          rightImg=''
          leftText={false}
          isImg={true}
          subHeading='Go to the “All Tools” menu and choose “Tool Maker”<br /><br />
          Choose to add a block, and select "Chat" as the block type.<br /><br />
          Click the running man at the top at any time to see what the tool looks like for users and to test it. And then click that icon again to go back into creator mode. 
          
          '
        />


<VideoHero
          videoUrl={
            'https://blog.skillfusion.ai/wp-content/uploads/chat-processing.jpg'
          }
          heading='Add Instructions to Your Chatbot'
          btnText=''
          rightImg=''
          leftText={true}
          isImg={true}
          subHeading='
          Add another block and this time choose processing.<br/><br/>
          Then under the "Modify References" menu choose "Send extra info to chat block (don&#8217;t add to chat history)". This will add a message to the AI that the user can&#8217;t see in the chat. You can use this like the custom instructions in the OpenAI GPTs<br/><br/>
          The processing block also allows you to add messages to the chat for the user to see. The option that also says “don&#8217;t send to OpenAI” will stop the AI replying to the last message so it will just be the reply that the processing block sends that will be visible          
          '
        />


<VideoHero
          videoUrl={
            'https://blog.skillfusion.ai/wp-content/uploads/chat-knowledge.jpg'
          }
          heading='Add Knowledge to Your Chatbot'
          btnText=''
          rightImg=''
          leftText={false}
          isImg={true}
          subHeading='
          Use the Embeddings block to add searchable information (“knowledge”). You need to separate the information out into pieces. You can choose how many pieces of information you want to return at once. You can also import your info from a spreadsheet that has 1 or 2 columns.
          <br/><br/>Under <i>"Text to compare"</i> you could use any of these as your “lookup” for your knowledge:
          <ul style="list-style: disc;"><li>search based on the user&#8217;s latest message e.g. <0:output></li>
          <li>Or you can do a lookup based on the chat history e.g. <0:input></li>
          <li>Or the whole chat but make it clear the last bit is from the user:<br/>
          “<0:input>
          <br/>Human: <0:output>”</li>
          <li>Or you can use the processing block to search for certain info or extract the last xx words from the chat</li>
          <li>You can add a prompt block and feed the recent chat to the AI and ask it to choose from a list of subjects that you know your knowledge-base covers</li></ul>
          <br/>The embeddings block can have one column or two if you select <i>"Use custom outputs?"</i>. When there are two columns the first column is used like a search key and the second column is what is returned.
          <br/><br/>Once the embeddings block has returned results you will need to use the processing block again to add the retrieved information to the chatblock for the AI to consider. 
          <br/><br/>You may want to directly instruct the AI to answer using the extra information provided. Prompt the AI based on how strict you want it to be, e.g. <i>“consider the extra information provided when answering the user’s question”</i> will be less strict than <i>“answer the question only using the information provided”</i>.
          
          '
        />



<VideoHero
          videoUrl={
            'https://blog.skillfusion.ai/wp-content/uploads/chat-more.jpg'
          }
          heading='Adding More To The Chatbot'
          btnText=''
          rightImg=''
          leftText={true}
          isImg={true}
          subHeading='
          If you want the chatbot to split off into "tool mode", then you’ll need to add some if/then logic to your tool to make it happen. 
          <br/><br/>Tool Mode is where you go through a series of steps to perform something that can be done with a single prompts. Like our book writing tools which write a whole book.
<br/><br/>You can add a If-Else Block. For example if you tell the user to write “write poem” when they want your tool to generate a poem you could set the block options like this:
<br />If <0:output>
<br />“contains”
<br />Write poem
<br />Go to: (the next block)
<br />Else go to: 0 (back to the chatblock)
<br /><br />
<b>Other Techniques</b>
<br /><br />The embeddings block can be used to categorise the user’s reply and then you can use the if-else block to control what the tool does next. Based on teh category it matched


          '
        />


<VideoHero
          videoUrl={
            'https://blog.skillfusion.ai/wp-content/uploads/chat-share.jpg'
          }
          heading='Getting Sales'
          btnText=''
          rightImg=''
          leftText={false}
          isImg={true}
          subHeading='
          Once your tool is published don’t forget to share it.<br/><br/>
          When using the tool like a user you can click on the link on the right to view the tool details page, share that page to show people your tool.<br/><br/>
          You get 100% comission for tool sales. Paid monthly via paypal on the 8th of each month.           

          '
        />


<VideoHero
          videoUrl={
            'https://www.youtube.com/embed/fKjD0N_ZaMA?si=-jG60QWfNK5Ad3CH'
          }
          heading='Videos'
          btnText=''
          rightImg=''
          leftText={true}
          isImg={false}
          subHeading='
          Here is a tutorial video on how to make the chatbot tools.        

          '
        />        


<VideoHero
          videoUrl={
            'https://www.youtube.com/embed/0VEmdhhZaTM?si=Sqdg2Zp4blVJA3KB'
          }
          heading=''
          btnText=''
          rightImg=''
          leftText={true}
          isImg={false}
          subHeading='
          Here is an example of a chatbot that is also an AI tool.        

          '
        />              



<br/>
      </div>
    </div>
  );
};

export default ChatHelp;
