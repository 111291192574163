import React, { useContext } from "react";
import FuserContext from "../../context/FuserContext";
import { backendURL } from "../../constants/environmental";
import { useAuthHeader } from "react-auth-kit";
import axios from "axios";

function SubscriptionInfoSection({ toolId }: { toolId: string }) {
  const {
    toolMetadata: { subscriptionInfo },
    updateToolMetadata,
    userId,
  } = useContext(FuserContext);

  const authHeader = useAuthHeader()();

  return !subscriptionInfo ? null : subscriptionInfo.subscriptionDueCancellation ? (
    <>
      <p>
        Your access to this tool will end on{" "}
        {new Date(subscriptionInfo.reviewDate).toLocaleDateString()}.
      </p>
      <button
        className="border border-gray-300 border-2 p-1 px-2 rounded-lg bg-white hover:bg-blue-100"
        onClick={reactivateSubscription}
      >
        Reactivate subscription
      </button>
      <p className="text-center text-sm">
        (If you reactivate, you will not be charged until your renewal date)
      </p>
      <br />
    </>
  ) : (
    <>
      <p>
        Your monthly subscription of {subscriptionInfo.subscriptionCharge * 10}{" "}
        credits for this tool will renew on{" "}
        {new Date(subscriptionInfo.reviewDate).toLocaleDateString()}.
      </p>
      <button
        className="border border-gray-300 border-2 p-1 px-2 rounded-lg bg-white hover:bg-blue-100"
        onClick={cancelSubscription}
      >
        Cancel subscription
      </button>
      <br />
    </>
  );

  async function cancelSubscription() {
    try {
      const response = await axios.patch(
        `${backendURL}/user/${userId}/${toolId}/subscription`,
        { subscriptionDueCancellation: true },
        {
          headers: {
            Authorization: authHeader,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      updateToolMetadata({
        subscriptionInfo: {
          ...subscriptionInfo,
          subscriptionDueCancellation: true,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function reactivateSubscription() {
    try {
      const response = await axios.patch(
        `${backendURL}/user/${userId}/${toolId}/subscription`,
        { subscriptionDueCancellation: false },
        {
          headers: {
            Authorization: authHeader,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      updateToolMetadata({
        subscriptionInfo: {
          ...subscriptionInfo,
          subscriptionDueCancellation: false,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export default SubscriptionInfoSection;
