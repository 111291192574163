import { createContext } from 'react';

interface PageSearchContextProps {
  setSearchTerm: any;
  blockSortingMethod: any;
  setBlockSortingMethod: any;
  freePaid: any;
  setFreePaid: any;
  setSelectedCategoryId: any;
}

const PageSearchContext = createContext<PageSearchContextProps>({
  setSearchTerm: null,
  blockSortingMethod: null,
  setBlockSortingMethod: null,
  freePaid: null,
  setFreePaid: null,
  setSelectedCategoryId: null,
});

export default PageSearchContext;
