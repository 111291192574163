import React, { FC, useContext, useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { nftsImgs } from '../../constants/fakeData';
import axios from 'axios';
import PageGigContext from '../../context/PageGigContext';
import LinkToProfile from '../../components/LinkToProfile';
import { timestampToLocalDate } from '../../utils/date';
import ReplyForm from '../../components/ReplyForm';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { backendURL } from '../../constants/environmental';
import { updateAtIndex } from '../../utils/array';
import ReactTextareaAutosize from 'react-textarea-autosize';

const QuestionAndAnswersDisplay: FC<any> = ({ toolId }) => {
  const { isOwner } = useContext(PageGigContext);

  const authHeader = useAuthHeader()();
  const user = useAuthUser()();
  const userId = user?.id;

  const [questionAndAnswers, setQuestionAndAnswers] = useState<
    any[] | undefined
  >();

  const [newQuestionText, setNewQuestionText] = useState('');

  const handleQuestionInput = (e: any) => {
    setNewQuestionText(e.target.value);
  };

  const processQuestionAndAnswerForDisplay = (questionAndAnswer: any) => {
    return questionAndAnswer;
  };

  const loadQuestionAndAnswers = async () => {
    try {
      const response: any = await axios.get(
        `${backendURL}/blocks/getBlockQuestionAndAnswers/${toolId}`
      );

      //console.log('q&a fetch response:', response);
      const questionAndAnswers = response?.data?.questionAndAnswers;

      if (questionAndAnswers)
        setQuestionAndAnswers(
          questionAndAnswers.map(processQuestionAndAnswerForDisplay)
        );
      else throw new Error('Malformed response');
      //console.log('questionAndAnswers:', questionAndAnswers);
    } catch (error) {
      console.error('Error fetching q&as: ', error);
    }
  };

  useEffect(() => {
    loadQuestionAndAnswers();
  }, []);

  const [sendQuestionButtonText, setSendQuestionButtonText] = useState('Send');
  const [addingQuestion, setAddingQuestion] = useState<boolean>(false);

  const addQuestion = async () => {
    setSendQuestionButtonText('Sending...');
    setAddingQuestion(true);

    const question = {
      tool: toolId,
      questionAuthor: userId,
      questionText: newQuestionText,
    };

    try {
      const response: any = await axios.post(
        `${backendURL}/blocks/askQuestion/${userId}`,
        { question },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      if (response) {
        //console.log('response:', response);
        const newQuestionAndAnswer = response.data.question;
        setQuestionAndAnswers((questionAndAnswers: any) => [
          ...questionAndAnswers,
          processQuestionAndAnswerForDisplay(newQuestionAndAnswer),
        ]);
      } else throw new Error('Malformed response');
    } catch (error) {
      console.error('Error adding question: ', error);
    } finally {
      setSendQuestionButtonText('Send');
      setAddingQuestion(false);
    }
  };

  const handleDeleteQuestionAndAnswer = async (questionAndAnswerId: string) => {
    try {
      const response = await axios.delete(
        `${backendURL}/blocks/questionAndAnswer/${questionAndAnswerId}`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      //console.log('Delete successful', response.data);
      setQuestionAndAnswers((questionAndAnswers: any) =>
        questionAndAnswers.filter(
          (questionAndAnswer: any) =>
            questionAndAnswer._id !== questionAndAnswerId
        )
      );
    } catch (err) {
      console.log('There was an error deleting your q&a', err);
      //setStatusMessage('There was an error deleting your q&a');
    }
  };

  const handleDeleteQuestionReply =
    (questionAndAnswerId: string) => async () => {
      try {
        const response = await axios.delete(
          `${backendURL}/blocks/questionAndAnswer/reply/${questionAndAnswerId}`,
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );

        //console.log('Delete successful', response.data);

        setQuestionAndAnswers((questionAndAnswers: any) => {
          const indexOfQuestionAndAnswerToUpdate = questionAndAnswers.findIndex(
            ({ _id }: any) => _id === questionAndAnswerId
          );
          return updateAtIndex(
            indexOfQuestionAndAnswerToUpdate,
            response.data.questionAndAnswer,
            questionAndAnswers
          );
        });
      } catch (err) {
        console.log('There was an error deleting your reply', err);
        //setStatusMessage('There was an error deleting your q&a');
      }
    };

  const [replyFormQuestionId, setReplyFormQuestionId] = useState(false);

  const createReply =
    (questionId: string) => (replyText: string) => async () => {
      try {
        const response: any = await axios.post(
          `${backendURL}/blocks/questionAndAnswer/${questionId}/reply`,
          {
            toolId,
            replyText,
          },
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );

        if (response) {
          //console.log('response:', response);
          setQuestionAndAnswers((questionAndAnswers: any) => {
            const indexOfOldQandA = questionAndAnswers.findIndex(
              ({ _id }: any) => _id === questionId
            );
            return updateAtIndex(
              indexOfOldQandA,
              response.data.questionAndAnswer,
              questionAndAnswers
            );
          });
          setReplyFormQuestionId(false);
        } else throw new Error('Malformed response');
      } catch (error) {
        console.error('Error adding question: ', error);
      }
    };

  return (
    <>
      <div className='pt-9'>
        <h2>Questions & Answers:</h2>
        {questionAndAnswers === undefined ? (
          <p>Loading questions and answers...</p>
        ) : questionAndAnswers.length === 0 ? (
          <p className='text-sm'>No questions and answers yet</p>
        ) : (
          <ul>
            {questionAndAnswers.map((questionAndAnswer: any) => (
              <li key={questionAndAnswer._id}>
                <div className='bg-white shadow-md rounded px-8 py-6 flex flex-col my-2 border-slate-300 border-2 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 gap-4'>
                  <div className='flex justify-between items-center'>
                    <div className='flex items-start w-full'>
                      <div className='flex flex-col items-center gap-2 shrink-0'>
                        <img
                          src={
                            questionAndAnswer?.questionAuthor?.profileImage ||
                            nftsImgs[3]
                          }
                          alt={questionAndAnswer?.questionAuthor?.name}
                          className='w-12 h-12 object-cover rounded-full'
                        />

                        {(userId === questionAndAnswer?.questionAuthor?._id ||
                          user?.loggedin === 'false') && (
                          <button
                            onClick={() => {
                              setReplyFormQuestionId(false);
                              handleDeleteQuestionAndAnswer(
                                questionAndAnswer?._id
                              );
                            }}
                          >
                            <MdDelete
                              className='text-red-500 hover:text-red-700'
                              size={24}
                            />
                          </button>
                        )}
                      </div>

                      <div className='ml-4 flex flex-col gap-4 w-full overflow-x-hidden'>
                        <ReactTextareaAutosize
                          className='text-sm overflow-x-auto resize-none border-0 p-0'
                          value={`Answer: ${questionAndAnswer?.questionText}`}
                          readOnly
                        />

                        <h3 className='text-sm'>
                          <p className='text-sm font-medium'>Asked by</p>
                          <LinkToProfile
                            user={questionAndAnswer?.questionAuthor}
                          />
                        </h3>

                        <p className='text-sm'>
                          Date posted:{' '}
                          {timestampToLocalDate(
                            questionAndAnswer?.questionTimestamp
                          )}
                        </p>

                        {!questionAndAnswer?.replyAuthor &&
                          (isOwner || user?.loggedin === 'false') &&
                          (replyFormQuestionId === questionAndAnswer?._id ? (
                            <ReplyForm
                              setReplyFormVisible={setReplyFormQuestionId}
                              createReply={createReply(questionAndAnswer?._id)}
                            />
                          ) : (
                            <button
                              onClick={() =>
                                setReplyFormQuestionId(questionAndAnswer?._id)
                              }
                            >
                              Reply
                            </button>
                          ))}
                      </div>
                    </div>
                  </div>

                  {/* if no user exists with the replyAuthor _id then replyAuthor will be null */}
                  {questionAndAnswer?.replyAuthor && (
                    <>
                      <hr />
                      <div className='flex w-full justify-end items-start gap-4 overflow-x-hidden'>
                        {/* <div className='flex items-start gap-4'> */}
                        <div className='ml-4 flex flex-col gap-4 w-full overflow-x-hidden'>
                          <ReactTextareaAutosize
                            className='text-sm overflow-x-auto resize-none border-0 p-0'
                            value={`Answer: ${questionAndAnswer?.replyText}`}
                            readOnly
                          />

                          <div>
                            <p className='text-sm font-medium'>Answered by:</p>
                            <LinkToProfile
                              user={questionAndAnswer?.replyAuthor}
                            />
                          </div>
                          {/* <p className='text-sm'>
                                  Date posted:{' '}
                                  {timestampToLocalDate(questionAndAnswer?.replyTimestamp)}
                                </p> */}
                        </div>

                        <div className='flex flex-col items-center gap-2 shrink-0'>
                          <img
                            src={
                              questionAndAnswer?.replyAuthor?.profileImage ||
                              nftsImgs[3]
                            }
                            alt={questionAndAnswer?.replyAuthor?.name}
                            className='w-12 h-12 object-cover rounded-full'
                          />
                          {(userId === questionAndAnswer?.replyAuthor?._id ||
                            user?.loggedin === 'false') && (
                            <button
                              onClick={handleDeleteQuestionReply(
                                questionAndAnswer?._id
                              )}
                            >
                              <MdDelete
                                className='text-red-500 hover:text-red-700'
                                size={24}
                              />
                            </button>
                          )}
                        </div>
                        {/* </div> */}
                      </div>
                    </>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {userId ? (
        <div className='flex flex-col items-start pt-9'>
          <h2>Ask a question:</h2>
          <textarea
            className='resize-none bg-transparent rounded-xl text-sm border border-black-100 shadow-inner h-36 overflow-y-scroll w-full'
            onChange={handleQuestionInput}
            value={newQuestionText}
          ></textarea>
          <button
            onClick={addQuestion}
            className='mt-5 nc-Button h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 flex-1  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0'
            disabled={addingQuestion}
          >
            {sendQuestionButtonText}
          </button>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default QuestionAndAnswersDisplay;
