import React from 'react';
import Heading from '../../components/Heading/Heading';
import NcImage from '../../shared/NcImage/NcImage';

import Mike from '../../images/Founding Team/MichaelHayden.jpg';
// import Mal from "../../images/Founding Team/MalakiAiono.jpg"
// import Will from "../../images/Founding Team/WilliamBermudez.jpg"

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
  //url: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: '1',
    name: `Michael Hayden`,
    job: 'Founder & CEO',
    avatar: Mike,
    //url: "https://google.com"
  },
];

const SectionFounder = () => {
  return (
    <div className='nc-SectionFounder relative'>
      <div className='grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8'>
        {FOUNDER_DEMO.map(item => (
          <div
            key={item.id}
            className='max-w-sm'
          >
            {/* <a href={item.url}>*/}
            <NcImage
              containerClassName='relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden'
              className='absolute inset-0 object-cover'
              src={item.avatar}
            />
            <h3 className='text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200'>
              {item.name}
            </h3>
            <span className='block text-sm text-neutral-500 sm:text-base dark:text-neutral-400'>
              {item.job}
            </span>
            {/*  </a> */}
          </div>
        ))}
      </div>

      <Heading desc='Skillfusion is open to investment opportunities. Contact Mike on mike@skillfusion.ai'>
        {' '}
      </Heading>
    </div>
  );
};

export default SectionFounder;
