import React from 'react';
import {AtomSpinner} from 'react-epic-spinners'

interface FuserLoaderProps {
  loading: boolean;
  children?: React.ReactNode;
  name?: string;
}

const FuserLoader: React.FC<FuserLoaderProps> = ({ loading, children , name}) => {
  if (loading) {
    return <><AtomSpinner color="blue"/></>;
  }

  return <>{children}</>;
};

export default FuserLoader;
