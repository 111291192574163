import React, { useEffect, useRef } from 'react';

const HTMLPreviewContainer = ({ html, reloadTrigger, enabled }: any) => {
  const iframeRef: any = useRef(null);

  useEffect(() => {
    if (enabled && iframeRef && iframeRef.current) {
      const iframe = iframeRef.current;
      const doc = iframe.contentWindow.document;

      doc.open();
      doc.write(html);
      doc.close();
    }
  }, [html, reloadTrigger]);

  return (
    <iframe
      ref={iframeRef}
      style={{ backgroundColor: 'white', height:"600px" }}
      key={reloadTrigger}
    />
  );
};

export default HTMLPreviewContainer;
