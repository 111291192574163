import zapierBlockImg from 'images/zapier-creator.png';
import zapierBlockImg2 from 'images/zapier-creator-3.jpg';
import zapierBlockImg3 from 'images/zapier-creator-2.jpg';
import zapierBlockImg4 from 'images/zapier-listening.png';
import zapierBlockImg5 from 'images/zapier-creator-5.png';
import zapierBlockImg6 from 'images/zapier-user-5.png';

import React, { FC } from 'react';
import SectionFounder from './SectionFounder';
import SectionStatistic from './SectionStatistic';
import { Helmet } from 'react-helmet';
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from '../../components/BgGlassmorphism/BgGlassmorphism';
import BackgroundSection from '../../components/BackgroundSection/BackgroundSection';
import SectionHero from './SectionHero';
import SectionClientSay from '../../components/SectionClientSay/SectionClientSay';

export interface PageAboutProps {
  className?: string;
}

const ZapierHelpC: FC<PageAboutProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id='PageAbout'
    >
      <Helmet>
        <title>About || Skillfusion.ai</title>
        <link
          rel='canonical'
          href='https://skillfusion.ai/zapier-help-c'
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>
        <SectionHero
          rightImg={zapierBlockImg}
          heading='Zapier Block'
          btnText=''
          leftText={false}
          subHeading="The Zapier block will allow users to send your AI Tool's content straight to Zapier. From there the user can automatically send it to over 6,000 different APIs.<br/><br/>

          For instance: <br/>
          - a tool that drafts emails can connect to gmail <br/>
          - a blog post writer can connect to wordpress, shopify or magento <br/><br/>

          Or something more creative: <br/>
          - an AI generated slogan can be sent to a print-on-demand store provider, to be used on a mug or t-shirt <br/>
          - users with Philips Hue smart lighting could get AI to read the news and update the lighting in their home to set a matching mood!<br/>
          
          "
        />

        <SectionHero
          rightImg={zapierBlockImg2}
          heading='Using the Zapier Block'
          btnText=''
          leftText={true}
          subHeading='
          Go to the Tool Maker, and make a tool that generates the output you want, use the Prompt block to talk to the AI.<br/>
          Then add a zapier block to the end of your tool. <br/>
          '
        />

        <SectionHero
          rightImg={zapierBlockImg4}
          heading=''
          btnText=''
          leftText={false}
          subHeading="
          Head over to Zapier, choose Skillfusion as the trigger for your new zap, and follow the steps until you see <q>We're listening</q><br/>
          "
        />

        <SectionHero
          rightImg={zapierBlockImg5}
          heading=''
          btnText=''
          leftText={true}
          subHeading='
          Come back to Skillfusion and press <q>Refresh Hooks</q>, the last hook on the list should be the one you just created, it should match the current date and time.<br/><br/>
          Then fill in the fields.<br/> The <q>key</q> fields are the names of the fields you want to send e.g. <q>article heading</q><br />
          The matching <q>output</q> field is where the actual content goes, usually you will insert the output from another block like a prompts block, using the <q>Insert Block References</q> menu.<br/><br/>          Finally click <q>Sent to Zapier</q>
          '
        />

        <SectionHero
          rightImg={zapierBlockImg6}
          heading=''
          btnText=''
          leftText={false}
          subHeading='
        Go back to Zapier and click <q>Test Trigger</q>, you should see your data appear<br/><br/>
        Now you can carry on building your Zap to send that data wherever you want<br/><br/>
        Your users will have to set up their own hook and Zap to use your tool. Within your tool you should advise them which integrations your tool is designed to connect to.<br />
        To do this you can use the tool descritpion box or leave an <q>info block</q> before the Zapier block with instructions, and set it to <q>show to user</q>.'
        />
      </div>
    </div>
  );
};

export default ZapierHelpC;
