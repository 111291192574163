import React, { useContext, useState } from "react";
import FuserContext from "../../context/FuserContext";
import { MdClose } from "react-icons/md";
import { useAuthUser } from "react-auth-kit";

const TagsSection = () => {
  const {
    toolMetadata: { tags },
    updateToolMetadata,
  } = useContext(FuserContext);

  const [tagInputValue, setTagInputValue] = useState("");

  const user = useAuthUser()();

  return user?.loggedin !== "false" ? null : (
    <>
      <div style={{ alignSelf: "flex-start" }} className="flex flex-wrap gap-2">
        <p style={{ alignSelf: "flex-start" }}>Tags:</p>
        {tags.map((tag: string) => (
          <span key={tag} className="flex items-center gap-1">
            <span>{tag}</span>
            <button
              className="border border-1 border-black rounded-md"
              onClick={() =>
                updateToolMetadata({
                  tags: tags.filter((otherTag: string) => tag !== otherTag),
                })
              }
            >
              <MdClose />
            </button>
          </span>
        ))}
      </div>
      <div className="flex gap-2" style={{ alignSelf: "flex-start" }}>
        <input
          className="p-1"
          value={tagInputValue}
          onChange={({ target: { value } }) => setTagInputValue(value)}
        />
        <button onClick={handleAddTagClick}>Add tag</button>
      </div>
    </>
  );

  function handleAddTagClick() {
    const trimmedTag = tagInputValue.trim();
    if (trimmedTag === "") {
      return alert("Tag is empty");
    }
    if (tags.includes(trimmedTag)) {
      return alert("Tag already exists");
    }

    updateToolMetadata({ tags: [...tags, tagInputValue] });
    setTagInputValue("");
  }
};

export default TagsSection;
