import embeddingsPreview from 'images/embeddings_preview.png';
import embeddingsOutput from 'images/embeddings_outputs.png';

import React, { FC } from 'react';
import SectionFounder from './SectionFounder';
import SectionStatistic from './SectionStatistic';
import { Helmet } from 'react-helmet';
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from '../../components/BgGlassmorphism/BgGlassmorphism';
import BackgroundSection from '../../components/BackgroundSection/BackgroundSection';
import SectionHero, { VideoHero } from './SectionHero';
import SectionClientSay from '../../components/SectionClientSay/SectionClientSay';

export interface PageAboutProps {
  className?: string;
}

const EmbeddingsHelpC: FC<PageAboutProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id='PageAbout'
    >
      <Helmet>
        <title>About || Skillfusion.ai</title>
        <link
          rel='canonical'
          href='https://skillfusion.ai/embeddings-help-c'
        />
      </Helmet>
      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      <div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>
        <VideoHero
          videoUrl={
            'https://www.youtube.com/embed/C_XXbkEzZS8?si=DfnrpR9Zcfv_nSZz'
          }
          rightImg=''
          heading='Embeddings Block'
          btnText=''
          leftText={false}
          subHeading='Embeddings Blocks are like mini AI powered search engines.<br/><br/>

          They allow you to add information to your tool and retrieve the most relevant bits when needed.<br/><br/>
          This allows you to have more information available than you can fit in one prompt, and to just pull out the most relevant bits.<br/><br/>

          '
        />

        <SectionHero
          rightImg={embeddingsPreview}
          heading="Here's an Example"
          btnText=''
          leftText={true}
          subHeading="
          
          AI Powered Elon Musk Style Tweets (or quotes, or advice):<br/><br/>
          
          First you need to collect a bunch of examples, too many to fit in a single prompt<br/><br/>
          
          Then you would make a tool in the format:<br/>
          <ul style='list-style: inside;'>
          <li>Question Block - <q>What would you like your tweets to be about</q></li>
          <li>Embeddings Block - retrieves the 10 examples that are closest to what the user is looking for</li>
          <li>Prompt Block - <q>Here are 10 Elon Musk tweets, make 3 more in the same style about xxx</q></li>
          </ul><br/><br/>

  
          "
        />

        <SectionHero
          rightImg={embeddingsOutput}
          heading='Separate Search and Answer Fields'
          btnText=''
          leftText={false}
          subHeading='
          You can also have separate search and answer fields in an embeddings block. <br/><br/>
          
          For example a nutrition planner might ask the user for their goal, and then have embeddings like this:<br/><br/>
          
          <q>lose weight</q> - lettuce, tomatoes, carrots...<br/>
          <q>gain weight</q> - whole grain pasta, cheese....<br/>
          <q>manage blood sugar</q> - legumes, pulses, meat...<br/>
          <q>lower inflammation</q> - avocados, salmon, sweet potato, chillies<br/><br/>

          '
        />

        <SectionHero
          rightImg=''
          heading='Other Uses'
          btnText=''
          leftText={true}
          subHeading='

          You can have a list of question in your embeddings block, so your Embedding block is pulling out the most relevant questions to ask the user, and then you add those question to a question block.
          <br/><br/>
          Or you can add radnomness to your tool by pulling out one or more random values from your embeddings block.          Finally click <q>Sent to Zapier</q>
          '
        />
      </div>
    </div>
  );
};

export default EmbeddingsHelpC;
