import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import axios from 'axios';
import { backendURL } from '../constants/environmental';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

// const now = new Date();

// const currentMonthZeroBased = now.getMonth();

// const currentPeriod = {
//   monthNumber: currentMonthZeroBased + 1,
//   year: now.getFullYear(),
// };

const TrackingPage: FC<any> = () => {
  const authHeader = useAuthHeader()();
  const user = useAuthUser()();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.id) {
      console.log('Not logged in');
      navigate('/login');
    }
    if (user?.loggedin === 'true') {
      navigate('/page-search');
    }
  });

  const [trackingData, setTrackingData] = useState<any>({});
  const [fetchingTrackingData, setFetchingTrackingData] =
    useState<boolean>(false);

  const toYYYYdashMMdashDD = (date: Date) => {
    return date.toLocaleDateString().split('/').reverse().join('-');
  };

  const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;

  const [selectedPeriod, setSelectedPeriod] = useState<any>({
    startDate: toYYYYdashMMdashDD(new Date(Date.now() - sevenDaysInMs)),
    endDate: toYYYYdashMMdashDD(new Date()),
  });

  function fetchTrackingData() {
    setFetchingTrackingData(true);
    setTrackingData({});

    const { startDate, endDate } = selectedPeriod;

    let trackingTypeRouteFragmentsToCheck = [
      // 'survey',
      'newUsers',
      'creditPurchases',
      'creditSpend',
    ];

    for (const trackingTypeRouteFragment of trackingTypeRouteFragmentsToCheck) {
      axios
        .get(
          `${backendURL}/admin/tracking/${trackingTypeRouteFragment}?startDate=${startDate}&endDate=${endDate}`,
          {
            headers: {
              Authorization: authHeader,
            },
          }
        )
        .then(response => {
          setTrackingData((trackingData: any) => {
            trackingData[trackingTypeRouteFragment] = response.data;
            return trackingData;
          });
        })
        .catch(error => console.error('Error fetching tracking data', error))
        .finally(() => {
          trackingTypeRouteFragmentsToCheck =
            trackingTypeRouteFragmentsToCheck.filter(
              type => type !== trackingTypeRouteFragment
            );
          if (trackingTypeRouteFragmentsToCheck.length === 0) {
            setFetchingTrackingData(false);
          }
        });
    }
  }

  // useEffect(() => {
  //   setSelectedPeriod(currentPeriod);
  // }, []);

  // const selectedPeriodMemo = useMemo(() => selectedPeriod, [selectedPeriod]);

  // useEffect(() => {
  //   fetchTrackingData(selectedPeriod);
  //   console.log('selected period:', selectedPeriod);
  // }, [selectedPeriodMemo]);

  // console.log(selectedPeriod);
  const [isFullyExpanded, setIsFullyExpanded] = useState<any>({
    spendPerTool: false,
    spendPerUser: false,
  });

  return (
    <div>
      <div className='container lg:pt-2 space-y-2 lg:space-y-4'>
        <h2 className='flex items-center text-xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
          Tracking Page
        </h2>
        <div className='flex items-center gap-2'>
          <label>From 00:00 on</label>
          <input
            type='date'
            onChange={({ target: { value } }) =>
              setSelectedPeriod((previous: any) => ({
                ...previous,
                startDate: value,
              }))
            }
            value={selectedPeriod.startDate}
          />
          <label>to 00:00 on</label>
          <input
            type='date'
            onChange={({ target: { value } }) =>
              setSelectedPeriod((previous: any) => ({
                ...previous,
                endDate: value,
              }))
            }
            value={selectedPeriod.endDate}
          />
          <button
            className='border border-1 border-black px-1'
            onClick={fetchTrackingData}
          >
            Get data
          </button>
        </div>

        {fetchingTrackingData && <p>Loading...</p>}

        {trackingData === undefined ? null : (
          <div className='flex flex-col gap-8'>
            {/* {trackingData.survey && (
              <div>
                <h3 className='font-black text-xl'>Survey data</h3>
                <ul className='flex flex-col gap-4'>
                  <li>
                    <p className='font-bold'>{`Total users considered: ${trackingData.survey.numberOfUsersConsideredForSurvey}`}</p>
                    <ul className='ml-8'>
                      <li>
                        <p className='font-semibold'>{`Shown survey: ${trackingData.survey.numberOfUsersShownSurvey}`}</p>
                        <ul className='ml-8'>
                          <li>{`Bought credits: ${trackingData.survey.numberOfUsersShownSurveyWhoBoughtCredits}`}</li>
                          <li>{`Total dollars spent: ${trackingData.survey.totalDollarsSpentFromUsersShownTheSurvey}`}</li>
                          <li>
                            <p className='font-medium'>{`Completed the survey: ${trackingData.survey.numberOfUsersWhoCompletedSurvey}`}</p>
                            <ul className='ml-8'>
                              <li>{`Bought credits: ${trackingData.survey.numberOfUsersWhoCompletedSurveyThatBoughtCredits}`}</li>
                              <li>{`Total dollars spent: ${trackingData.survey.totalDollarsSpentFromUsersWhoCompletedTheSurvey}`}</li>
                            </ul>
                          </li>
                          <li>
                            <p className='font-medium'>
                              {`Didn't complete the survey: ${
                                trackingData.survey.numberOfUsersShownSurvey -
                                trackingData.survey
                                  .numberOfUsersWhoCompletedSurvey
                              }`}
                            </p>
                            <ul className='ml-8'>
                              <li>{`Bought credits: ${
                                trackingData.survey
                                  .numberOfUsersShownSurveyWhoBoughtCredits -
                                trackingData.survey
                                  .numberOfUsersWhoCompletedSurveyThatBoughtCredits
                              }`}</li>
                              <li>{`Total dollars spent: ${
                                trackingData.survey
                                  .totalDollarsSpentFromUsersShownTheSurvey -
                                trackingData.survey
                                  .totalDollarsSpentFromUsersWhoCompletedTheSurvey
                              }`}</li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p className='font-semibold'>
                          {`Not shown survey: ${
                            trackingData.survey
                              .numberOfUsersConsideredForSurvey -
                            trackingData.survey.numberOfUsersShownSurvey
                          }`}
                        </p>
                        <ul className='ml-8'>
                          <li>{`Bought credits: ${
                            trackingData.survey
                              .numberOfUsersConsideredForSurveyWhoBoughtCredits -
                            trackingData.survey
                              .numberOfUsersShownSurveyWhoBoughtCredits
                          }`}</li>
                          <li>{`Total dollars spent: ${
                            trackingData.survey
                              .totalDollarsSpentFromUsersConsideredForSurvey -
                            trackingData.survey
                              .totalDollarsSpentFromUsersShownTheSurvey
                          }`}</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            )} */}

            {trackingData.creditSpend && (
              <div>
                <p className='font-bold'>{`Total dollars spent on API usage: ${trackingData.creditSpend.apiSpendInDollars}`}</p>
                <p className='font-semibold'>
                  {`Per tool (Top 20 descending):`}
                </p>
                <span className='font-normal flex gap-2 items-center'>
                  <input
                    type='checkbox'
                    onChange={({ target: { checked } }) =>
                      setIsFullyExpanded((previous: any) => ({
                        ...previous,
                        spendPerTool: checked,
                      }))
                    }
                  />
                  Expand
                </span>
                <JsonView
                  src={trackingData.creditSpend.apiSpendPerToolDescending}
                  collapsed={!isFullyExpanded.spendPerTool}
                />
                <p className='font-semibold'>{`Per user (Top 20 descending):`}</p>
                <span className='font-normal flex gap-2 items-center'>
                  <input
                    type='checkbox'
                    onChange={({ target: { checked } }) =>
                      setIsFullyExpanded((previous: any) => ({
                        ...previous,
                        spendPerUser: checked,
                      }))
                    }
                  />
                  Expand
                </span>
                <JsonView
                  src={trackingData.creditSpend.apiSpendPerUserDescending}
                  collapsed={!isFullyExpanded.spendPerUser}
                />
              </div>
            )}

            <hr />

            {trackingData.newUsers && (
              <ul className='flex flex-col gap-2'>
                <li className='font-black text-xl'>New users data</li>
                <li>
                  <p className='font-bold'>
                    {`Total verified email signups: ${Object.values(
                      trackingData.newUsers
                        ?.numberOfSignupsWithEmailVerifiedGroupedByReferralSource
                    ).reduce((x: any, y: any) => x + y, 0)}`}
                  </p>
                  <ul className='ml-8'>
                    <li>{`Bought a tool: ${trackingData.newUsers?.numberOfSignupsThatBoughtATool}`}</li>
                    <li>{`Bought multiple tools: ${trackingData.newUsers?.numberOfSignupsThatBoughtMultipleTools}`}</li>
                    <li>{`Used up all free credits: ${trackingData.newUsers?.numberOfSignupsThatUsedUpAllFreeCredits}`}</li>
                    <li>{`Bought credits: ${trackingData.newUsers?.numberOfSignupsThatBoughtCredits}`}</li>
                    <li>{`Bought a paid tool: ${trackingData.newUsers?.numberOfSignupsThatBoughtAPaidTool}`}</li>
                    <li>{`Bought multiple paid tools: ${trackingData.newUsers?.numberOfSignupsThatBoughtMultiplePaidTools}`}</li>
                    <li>{`Total dollars spent on credits: ${trackingData.newUsers?.totalDollarsSpentOnCredits}`}</li>
                  </ul>
                </li>

                <li>
                  <p className='font-semibold'>Referral sources:</p>
                  <ul className='ml-8'>
                    {Object.entries(
                      trackingData.newUsers
                        ?.numberOfSignupsWithEmailVerifiedGroupedByReferralSource
                    ).map(([referralSource, signups]) => (
                      <li
                        key={referralSource}
                      >{`${referralSource}: ${signups}`}</li>
                    ))}
                  </ul>
                </li>
                <li>
                  <p className='font-semibold'>Referring users:</p>
                  <ul className='ml-8'>
                    {trackingData.newUsers?.referringUsersData?.map(
                      ({ id, name, numberOfReferrals }: any) => (
                        <li key={id}>
                          {`${name}${
                            id ? ` (${id})` : ''
                          }: ${numberOfReferrals}`}
                        </li>
                      )
                    )}
                  </ul>
                </li>
                <li className='font-bold'>
                  {`Total unverified email signups: ${trackingData.newUsers?.numberOfUnverifiedSignups}`}
                </li>
              </ul>
            )}

            <hr />

            {trackingData.creditPurchases && (
              <ul className='flex flex-col gap-2'>
                <li className='font-black text-xl'>Credit purchase data</li>
                <li>
                  <p className='font-bold'>
                    {`Total dollars spent on credits: ${trackingData.creditPurchases.totalSpentInDollars.toFixed(
                      2
                    )} (one-time: ${trackingData.creditPurchases.totalOneTimeSpendInDollars.toFixed(
                      2
                    )} / monthly: ${trackingData.creditPurchases.totalMonthlySpendInDollars.toFixed(
                      2
                    )})`}
                  </p>
                  <p className='ml-8'>
                    {`First time spending: ${(
                      trackingData.creditPurchases
                        .totalFirstTimeOneTimeSpendInDollars +
                      trackingData.creditPurchases
                        .totalFirstTimeMonthlySpendInDollars
                    ).toFixed(
                      2
                    )} (one-time: ${trackingData.creditPurchases.totalFirstTimeOneTimeSpendInDollars.toFixed(
                      2
                    )} / monthly: ${trackingData.creditPurchases.totalFirstTimeMonthlySpendInDollars.toFixed(
                      2
                    )})`}
                  </p>
                </li>

                <li className=''>
                  <p className='font-bold'>{`Total successful purchases: ${trackingData.creditPurchases.totalPurchases} (one-time: ${trackingData.creditPurchases.totalOneTimePurchases} / monthly: ${trackingData.creditPurchases.totalFirstTimeMonthlyPayments + trackingData.creditPurchases.totalMonthlyRenewals})`}</p>
                  <p className='ml-8'>
                    {`Total first time purchases: ${trackingData.creditPurchases.totalFirstTimeOneTimePurchases + trackingData.creditPurchases.totalFirstTimeMonthlyPurchases} (one-time: ${trackingData.creditPurchases.totalFirstTimeOneTimePurchases} / monthly: ${trackingData.creditPurchases.totalFirstTimeMonthlyPurchases})`}
                  </p>
                  <p className='ml-8'>
                    {/* This is new subscriptions, not first ever purchases {`Total first time purchases: ${trackingData.creditPurchases.totalFirstTimeOneTimePurchases + trackingData.creditPurchases.totalFirstTimeMonthlyPurchases} (one-time: ${} / monthly: ${})`} */}
                  </p>
                </li>

                <li className='font-bold'>{`Total cancelled monthly subscriptions: ${trackingData.creditPurchases.totalCancelledMonthlySubscriptions}`}</li>
                {/* Number of subscriptions that failed to set up (does not included failed renewals) <li className='font-bold'>{`Total failed first time monthly purchases: ${trackingData.creditPurchases.totalFailedMonthlyCreditPurchases}`}</li> */}
                <br />
                <li>
                  {Object.entries(
                    trackingData.creditPurchases?.referralSourcesData
                  )?.map(
                    ([
                      referralSource,
                      {
                        totalSpentInDollars,
                        totalOneTimeSpendInDollars,
                        totalMonthlySpendInDollars,
                        totalFirstTimeOneTimeSpendInDollars,
                        totalFirstTimeMonthlySpendInDollars,
                        totalPurchases,
                        totalOneTimePurchases,
                        totalMonthlyPurchases,
                        totalFirstTimeOneTimePurchases,
                        totalFirstTimeMonthlyPurchases,
                      },
                    ]: any) => (
                      <React.Fragment key={referralSource}>
                        <ul className='flex flex-col gap-2'>
                          <li className='font-semibold'>
                            Referral source: {referralSource}
                          </li>
                          <li className=''>
                            <p className='font-medium'>
                              {`Total dollars spent on credits: ${totalSpentInDollars.toFixed(
                                2
                              )} (one-time: ${totalOneTimeSpendInDollars.toFixed(
                                2
                              )} / monthly: ${totalMonthlySpendInDollars.toFixed(
                                2
                              )})`}
                            </p>
                            <p className='ml-8'>
                              {`First time spending: ${(
                                totalFirstTimeOneTimeSpendInDollars +
                                totalFirstTimeMonthlySpendInDollars
                              ).toFixed(
                                2
                              )} (one-time: ${totalFirstTimeOneTimeSpendInDollars.toFixed(
                                2
                              )} / monthly: ${totalFirstTimeMonthlySpendInDollars.toFixed(
                                2
                              )})`}
                            </p>
                          </li>

                          <li className=''>
                            <p className='font-medium'>{`Total successful purchases: ${totalPurchases} (one-time: ${totalOneTimePurchases} / monthly: ${totalMonthlyPurchases})`}</p>
                            <p className='ml-8'>
                              {`Total first time purchases: ${totalFirstTimeOneTimePurchases + totalFirstTimeMonthlyPurchases} (one-time: ${totalFirstTimeOneTimePurchases} / monthly: ${totalFirstTimeMonthlyPurchases})`}
                            </p>
                          </li>
                        </ul>
                        <br />
                      </React.Fragment>
                    )
                  )}
                </li>
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TrackingPage;
