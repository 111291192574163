import React, { FC } from 'react';
import { restartButtonStyles } from '../../constants/styles';

const RestartPromptButton: FC<any> = ({ onRestartPrompt, className }) => {
  return (
    <button
      className={restartButtonStyles + ' ' + className}
      onClick={onRestartPrompt}
      title='Retry Prompt'
    >
      <svg
        width='40'
        height='40'
        viewBox='0 0 70 70'
        preserveAspectRatio='xMidYMid meet'
      >
        <path
          data-name='layer1'
          d='M3.307 22.023a3 3 0 0 1 4.17.784l2.476 3.622A27.067 27.067 0 0 1 36 6c14.557 0 26 12.036 26 26.584a26.395 26.395 0 0 1-45.066 18.678 3 3 0 1 1 4.244-4.242A20.395 20.395 0 0 0 56 32.584C56 21.344 47.248 12 36 12a21.045 21.045 0 0 0-20.257 16.059l4.314-3.968a3 3 0 0 1 4.062 4.418l-9.737 8.952c-.013.013-.03.02-.043.033-.067.06-.143.11-.215.163a2.751 2.751 0 0 1-.243.17c-.076.046-.159.082-.24.12a3.023 3.023 0 0 1-.279.123c-.08.03-.163.05-.246.071a3.045 3.045 0 0 1-.323.07c-.034.006-.065.017-.1.022-.051.006-.102-.002-.154.002-.063.004-.124.017-.187.017-.07 0-.141-.007-.212-.012l-.08-.004-.05-.003c-.06-.007-.118-.03-.178-.04a3.119 3.119 0 0 1-.388-.087c-.083-.027-.16-.064-.239-.097a2.899 2.899 0 0 1-.314-.146 2.753 2.753 0 0 1-.233-.151 2.807 2.807 0 0 1-.262-.2 2.857 2.857 0 0 1-.2-.19 3.013 3.013 0 0 1-.224-.262c-.03-.04-.069-.073-.097-.114L2.523 26.194a3.001 3.001 0 0 1 .784-4.17z'
          fill='#202020'
        ></path>
      </svg>
    </button>
  );
};
export default RestartPromptButton;
