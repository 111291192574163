import React, { FC } from 'react';
import SectionFounder from './SectionFounder';
import SectionStatistic from './SectionStatistic';
import { Helmet } from 'react-helmet';
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from '../../components/BgGlassmorphism/BgGlassmorphism';
import SectionHero, { VideoHero } from './SectionHero';
import processingHelp1 from '../../images/processing-help-1.png';
import processingHelp2 from '../../images/processing-help-2.png';

export interface PageAboutProps {
  className?: string;
}

const ProcessingBlockHelp: FC<PageAboutProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id='PageAbout'
    >
      <Helmet>
        <title>About || Skillfusion.ai</title>
        <link
          rel='canonical'
          href='https://skillfusion.ai/processing-block-help'
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>
        <VideoHero
          videoUrl={
            'https://www.youtube.com/embed/sckWKSNqcyU?si=HkL-vrgs3fIwuBR7'
          }
          heading='Processing Block'
          btnText=''
          rightImg={''}
          leftText={false}
          subHeading="The processing block is a powerful addition to any tool that has many uses including:
          <ul style='list-style:inside;'>
          <li>Create multiple Prompt/Image/Text to Speech outputs at once</li>
          <li>Accumulate the results of multiple Prompt blocks</li>
          <li>Grab specific parts of other blocks and modify their values</li>
          </ul>
          <br/>
          The video on the side and the text below has more information
          "
        />

        <SectionHero
          rightImg={processingHelp1}
          heading='Generate multiple outputs at once'
          btnText=''
          leftText={true}
          subHeading='The processing block can turn text into a list of items; this list can then be input into a Prompt/Image/Text to speech block and all the items will be processed separately at the same time, and give a list of outputs. 
          <br/> 
          For example, you can make an image for each line in a block of text by using the processing block to split the text into a list of its individual lines before inputting into an Image block. 
          <br/> 
          Any reference to a block with a list output will be substituted with each item of the list separately.'
        />

        <SectionHero
          rightImg={processingHelp2}
          heading='Modify the values of other blocks'
          btnText=''
          leftText={true}
          subHeading="The processing block can also change the text of other blocks, for example you can add the output of a Prompt block to the end of an Info block. This is great when used with an If-else block to loop and store the results of multiple outputs over time.
          <br/>
          You can also perform numerical operations and remove words from other blocks which get set as the processing block's response"
        />
      </div>
    </div>
  );
};

export default ProcessingBlockHelp;
