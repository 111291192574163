import { removePropertyRecursive, toPlainObject } from "./object";

const MAX_STORAGE_AMOUNT_IN_BYTES = 2 ** 20 // 1 MB;
const MAX_SINGLE_LOG_SIZE_IN_BYTES = MAX_STORAGE_AMOUNT_IN_BYTES / 10;

function storeErrorInLocalStorage(message: any, ...optionalParams: any[]) {
  for (const argument of arguments) {
    if (typeof argument === 'object') {
      // remove sensitive data before storing
      removePropertyRecursive('Authorization', argument);
    }
  }

  const newLog:any = {
    message,
    params: optionalParams,
    timestamp: new Date().toISOString(),
  };

  if (new Blob([JSON.stringify(newLog)]).size > MAX_SINGLE_LOG_SIZE_IN_BYTES) {
    delete newLog.params;
    if (new Blob([JSON.stringify(newLog)]).size > MAX_SINGLE_LOG_SIZE_IN_BYTES) {
      return;
    }
  }

  let errorLogsString = localStorage.getItem('errorLogs');
  const errorLogs = errorLogsString ? JSON.parse(errorLogsString) : [];

  errorLogs.push(newLog);

  errorLogsString = JSON.stringify(errorLogs);

  // remove oldest logs if they take up over 1MB of space
  while (new Blob([errorLogsString]).size > MAX_STORAGE_AMOUNT_IN_BYTES) {
    errorLogs.shift();
    errorLogsString = JSON.stringify(errorLogs);
  }

  localStorage.setItem('errorLogs', errorLogsString);
}

function storeLogInLocalStorage(...data: any[]) {
  for (let i = 0; i < data.length; i++) {
    if (typeof data[i] === 'object') {
      // remove circular references and sensitive data before storing
      data[i] = toPlainObject(data[i]);
      removePropertyRecursive('Authorization', data[i]);
    }
  }
  
  const newLog = {
    data,
    timestamp: new Date().toISOString(),
  };

  // remove elements from data until it is small enough to store
  for (let dataEndIndex = data.length - 1; dataEndIndex >= 0; dataEndIndex--) {
    if (new Blob([JSON.stringify(newLog)]).size <= MAX_SINGLE_LOG_SIZE_IN_BYTES) {
      break;
    } 

    if (dataEndIndex === 0) {
      return;
    }
    
    newLog.data.pop();
  }

  let consoleLogsString = localStorage.getItem('consoleLogs');
  const consoleLogs = consoleLogsString ? JSON.parse(consoleLogsString) : [];

  consoleLogs.push(newLog);

  consoleLogsString = JSON.stringify(consoleLogs);

  // remove oldest logs if they take up over 1MB of space
  while (new Blob([consoleLogsString]).size > MAX_STORAGE_AMOUNT_IN_BYTES) {
    consoleLogs.shift();
    consoleLogsString = JSON.stringify(consoleLogs);
  }

  localStorage.setItem('consoleLogs', consoleLogsString);
}

export { storeErrorInLocalStorage,storeLogInLocalStorage };
