import React, { FC, useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import axios from 'axios';
import { backendURL } from '../constants/environmental';

const now = new Date();

const currentMonthZeroBased = now.getMonth();

const currentPeriod = {
  monthNumber: currentMonthZeroBased + 1,
  year: now.getFullYear(),
};

const SalesPage: FC<any> = ({ className = '' }) => {
  const authHeader = useAuthHeader()();

  const user = useAuthUser();
  const navigate = useNavigate();
  // const location = useLocation();
  // const uid = location.pathname.split('/').pop()?.split('?')[0] || '';

  // redirects user if not logged in
  useEffect(() => {
    if (!user()?.id) {
      console.log('Not logged in');
      navigate('/login');
    }
  });

  const [salesData, setSalesData] = useState<any[]>([]);
  const [fetchingSalesData, setFetchingSalesData] = useState<boolean>(true);
  const [selectedPeriod, setSelectedPeriod] = useState<any>(currentPeriod);

  async function fetchSales(period: any) {
    setFetchingSalesData(true);
    const { monthNumber, year } = period;
    try {
      const response: any = await axios.get(
        `${backendURL}/admin/sales/${year}/${monthNumber}`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      console.log(response);
      setSalesData(response?.data?.salesData);
    } catch (error) {
      console.error('Error fetching sales', error);
    } finally {
      setFetchingSalesData(false);
    }
  }

  useEffect(() => {
    setSelectedPeriod(currentPeriod);
  }, []);

  // console.log('user ID when getting error: ' + user()?.id);
  // if (user()?.id !== uid) {
  //   // front end?
  //   return (
  //     <div className='w-full  flex items-center justi fy-center flex-col gap-2 min-h-96'>
  //       <p>
  //         You have entered an incorrect user ID {user()?.id} vs {uid}
  //       </p>
  //     </div>
  //   );
  // }

  // const testSalesData = [
  //   {
  //     _id: 3,
  //     name: 'alan p',
  //     paymentEmail: undefined,
  //     registeredEmail: 'aaa@aaa.com',
  //     dollarsOwed: 987,
  //   },
  //   {
  //     _id: 322872349823,
  //     name: 'm corrigan',
  //     paymentEmail: 'marky@paypal.com',
  //     registeredEmail: 'corrigan@jlbcredit.org',
  //     dollarsOwed: 1,
  //   },
  //   {
  //     _id: 322872349824,
  //     name: 'jez',
  //     paymentEmail: undefined,
  //     registeredEmail: 'jez@jlbcredibt.org',
  //     dollarsOwed: 1,
  //   },
  // ];

  const handleSelectedMonthChange = (e: any) => {
    const selectedMonth = +e.target.value;
    setSelectedPeriod({ ...selectedPeriod, monthNumber: selectedMonth });
  };

  const handleSelectedYearChange = (e: any) => {
    const selectedYear = +e.target.value;
    setSelectedPeriod({ ...selectedPeriod, year: selectedYear });
  };

  const selectedPeriodMemo = useMemo(() => selectedPeriod, [selectedPeriod]);

  useEffect(() => {
    fetchSales(selectedPeriod);
    console.log('selected period:', selectedPeriod);
  }, [selectedPeriodMemo]);

  return (
    <div>
      <div className='container lg:pt-2 space-y-2 lg:space-y-4'>
        <h2 className='flex items-center text-xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
          Sales Page
        </h2>
        <div className='flex items-center gap-2'>
          <label>Month:</label>
          <select
            value={selectedPeriod.monthNumber}
            onChange={handleSelectedMonthChange}
          >
            {Array.from({ length: 12 }, (_, i) => (
              <option
                value={i + 1}
                key={i + 1}
              >
                {(i + 1).toString().padStart(2, '0')}
              </option>
            ))}
          </select>
          <label>Year:</label>
          <select
            value={selectedPeriod.year}
            onChange={handleSelectedYearChange}
          >
            {Array.from(
              { length: new Date().getFullYear() - 2023 + 1 },
              (_, i) => {
                const year = i + 2023;
                return (
                  <option
                    value={year}
                    key={year}
                  >
                    {year}
                  </option>
                );
              }
            )}
          </select>
          {/* <button
            className='border border-black p-1'
            onClick={() => fetchSales(selectedPeriod)}
          >
            Fetch sales
          </button> */}
        </div>
        {fetchingSalesData ? (
          <p>Loading...</p>
        ) : salesData?.length === 0 ? (
          <p>No sales in this period</p>
        ) : (
          <ul className='flex flex-col gap-4'>
            {salesData?.map(
              ({
                _id,
                name,
                paymentEmail,
                registeredEmail,
                dollarsOwed,
                totalSales,
              }: any) => {
                return (
                  <li key={_id}>
                    <p>Name: {name}</p>
                    <p>Id: {_id}</p>
                    <p>Total sales: {totalSales}</p>
                    <p>Dollars owed: {dollarsOwed}</p>
                    <p>
                      {paymentEmail
                        ? `Payment email: ${paymentEmail}`
                        : `No payment email found, registered email: ${registeredEmail}`}
                    </p>
                  </li>
                );
              }
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SalesPage;
