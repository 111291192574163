import React, { FC, useContext } from 'react';
import FuserContext from '../../context/FuserContext';

const NoBlocksDisplay: FC<{}> = () => {
  const { addBlock, blocksHaveLoaded, toolId, errorLoadingBlocks } =
    useContext(FuserContext);

  const onAddFirstBlockClick = () => addBlock();

  return (
    <div className=' h-full flex items-center justify-center flex-col gap-2'>
      {blocksHaveLoaded || !toolId ? (
        <>
          <p>There are no blocks</p>

          <button
            className='bg-blue-400 p-2 rounded-lg text-xs text-white'
            onClick={onAddFirstBlockClick}
          >
            Add First Block
          </button>
        </>
      ) : errorLoadingBlocks ? (
        <p>{errorLoadingBlocks}</p>
      ) : (
        <p>Loading blocks...</p>
      )}
    </div>
  );
};

export default NoBlocksDisplay;
