import React, { useState } from 'react';

const ReplyForm: React.FC<{
  createReply: (replyText: string) => any;
  setReplyFormVisible: any;
}> = ({ createReply, setReplyFormVisible }) => {
  const [replyText, setReplyText] = useState('');
  const handleReplyTextChange = (e: any) => setReplyText(e.target.value);

  const [replyButtonText, setReplyButtonText] = useState('Send reply');

  const handleSendReplyClick = async () => {
    setReplyButtonText('Sending reply...');
    await createReply(replyText)();
    setReplyButtonText('Send reply');
  };

  return (
    <div className='flex flex-col gap-2 w-5/6'>
      <label>Reply:</label>
      <textarea
        value={replyText}
        onChange={handleReplyTextChange}
        className='border-2 border-black'
      />
      <div className='flex gap-2 justify-between'>
        <button onClick={() => setReplyFormVisible(false)}>
          Discard reply
        </button>
        <button onClick={handleSendReplyClick}>{replyButtonText}</button>
      </div>
    </div>
  );
};

export default ReplyForm;
