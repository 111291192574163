import React, { useContext, useState } from "react";
import { backendURL } from "../../constants/environmental";
import { useAuthHeader } from "react-auth-kit";
import FuserContext from "../../context/FuserContext";
import axios from "axios";
import Toggle from "../../components/Toggle";

function AutosaveSection({ toolId }: { toolId: string }) {
  const [autosaveErrorMessage, setAutosaveErrorMessage] = useState("");

  const {
    userId,
    toolMetadata: { autosaveMode },
    updateToolMetadata,
  } = useContext(FuserContext);

  const authHeader = useAuthHeader()();

  return (
    <>
      <label className="flex gap-2 items-center">
        {`Autosave responses ${autosaveMode ? "(on)" : "(off)"}`}
        <Toggle
          className="cursor-pointer"
          toggled={autosaveMode}
          onChange={handleAutosaveToggle}
        />
      </label>
      {autosaveErrorMessage && (
        <p className="text-center">{autosaveErrorMessage}</p>
      )}
    </>
  );

  async function handleAutosaveToggle() {
    const newAutosaveMode = !autosaveMode;
    try {
      const saveAutosaveModeResponse = await axios.patch(
        `${backendURL}/user/${userId}/tools/${toolId}/access`,
        { autosaveMode: newAutosaveMode },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      setAutosaveErrorMessage("");
      updateToolMetadata({ autosaveMode: newAutosaveMode });
    } catch (e) {
      console.log(e, e.response.status);
      if (e?.response?.status === 404) {
        setAutosaveErrorMessage(
          "Please save this block again to enable autosave mode for yourself (it is already enabled for users other than the tool creator)."
        );
      }
    }
  }
}

export default AutosaveSection;
