import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import Pagination from '../../shared/Pagination/Pagination';
import { backendURL } from '../../constants/environmental';
// import Input from '../../shared/Input/Input';
// import ReactGA from 'react-ga4';

const SharedBlocksPage: FC<any> = ({ className = '' }) => {
  // const user = useAuthUser()();
  const [loadingSharedBlocks, setLoadingSharedBlocks] = useState<boolean>(true);
  // const authHeader = useAuthHeader()();
  const navigate = useNavigate();
  const urlParameters = new URLSearchParams(window.location.search);

  const pageNumberParameter: string | null = urlParameters.get('page');

  const initialPageNumber =
    pageNumberParameter === null || isNaN(+pageNumberParameter)
      ? 1
      : +pageNumberParameter;

  const [sharedBlocks, setSharedBlocks] = useState<any[] | undefined>();
  const [totalSharedBlocks, setTotalSharedBlocks] = useState<
    number | undefined
  >();
  const [currentPage, setCurrentPage] = useState<number>(initialPageNumber);

  useEffect(() => {
    urlParameters.set('page', currentPage.toString());
    navigate(`/sharedBlocks?${urlParameters}`);
  }, [currentPage]);

  const sortingMethodParameter = urlParameters.get('sortingMethod');

  const [blockSortingMethod, setBlockSortingMethod] = useState<string>(
    sortingMethodParameter ?? 'views-descending'
  );

  useEffect(() => {
    urlParameters.set('sortingMethod', blockSortingMethod);
    navigate(`/sharedBlocks?${urlParameters}`);
  }, [blockSortingMethod]);

  const loadPageOfSharedBlocks = async ({ page, sortingMethod }: any) => {
    setLoadingSharedBlocks(true);
    try {
      const response = await axios.get(
        `${backendURL}/blocks/shared?page=${page}&sortingMethod=${sortingMethod}`
      );
      setSharedBlocks(response.data.sharedBlocks);
      setTotalSharedBlocks(response.data.totalSharedBlocks);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSharedBlocks(false);
    }
  };

  useEffect(() => {
    loadPageOfSharedBlocks({
      page: initialPageNumber,
      sortingMethod: blockSortingMethod,
    });
  }, []);

  return (
    <div
      className={`nc-SharedBlocksPage ${className}`}
      data-nc-id='SharedBlocksPage'
    >
      <Helmet>
        <title>Shared Blocks</title>
        <link
          rel='canonical'
          href='https://skillfusion.ai/sharedBlocks'
        />
      </Helmet>

      <div className='container mb-24 lg:mb-32 flex flex-col gap-2'>
        <h2 className='my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
          Shared Blocks
        </h2>
        {loadingSharedBlocks ? (
          <p>Loading shared blocks...</p>
        ) : sharedBlocks === undefined ? (
          <p>Error loading shared blocks</p>
        ) : sharedBlocks?.length === 0 ? (
          <p>No shared blocks found</p>
        ) : (
          <>
            <label
              htmlFor='block-sorting-method'
              className='flex gap-2 items-center'
            >
              <span>Sort by:</span>
              <select
                className='text-sm rounded-full border border-neutral-300 hover:border-neutral-400 active:border-primary-500 focus:border-1 focus:border-primary-500'
                value={blockSortingMethod}
                onChange={({ target: { value } }) => {
                  setBlockSortingMethod(value);
                  loadPageOfSharedBlocks({ page: 1, sortingMethod: value });
                  setCurrentPage(1);
                }}
                id='block-sorting-method'
              >
                <option value='views-descending'>Most views</option>
                <option value='likes-descending'>Most likes</option>
                <option value='creation-date-descending'>Newest</option>
              </select>
            </label>
            <ul className='flex flex-col gap-2'>
              {sharedBlocks.map(
                ({
                  _id,
                  blockHeading,
                  response,
                  dateCreated,
                  blockType,
                  customTitle,
                  likes,
                  viewCount,
                }: any) => {
                  return (
                    <li
                      key={_id}
                      className='flex gap-2 items-center mb-1 w-full'
                    >
                      <Link
                        to={`/sharedBlock/${_id}`}
                        className='w-full'
                      >
                        <div className='border-2 p-4 rounded-md shadow-lg w-full flex flex-col h-40 justify-between bg-gradient-to-b from-blue-100 to-purple-200 overflow-y-auto'>
                          <div className='flex flex-col gap-1 max-w-full overflow-hidden '>
                            <h2 className='font-bold block whitespace-nowrap'>
                              {['prompt', 'info'].includes(blockType)
                                ? customTitle ||
                                  // response?.toString() ||
                                  blockHeading ||
                                  blockType[0].toUpperCase() +
                                    blockType.slice(1)
                                : blockType === 'image'
                                  ? 'Image'
                                  : blockType === 'textToSpeech'
                                    ? 'Audio'
                                    : ''}
                            </h2>

                            <p className=''>
                              {(['prompt', 'info'].includes(blockType) &&
                                response?.toString() &&
                                `Preview: ${response.toString()}`) ||
                                'See details...'}
                            </p>
                          </div>
                          <div className='flex justify-between items-end text-slate-600 shrink-0'>
                            <div className='flex gap-4 text-slate-600 shrink-0'>
                              <p>
                                {`${viewCount ?? 0} view${
                                  viewCount === 1 ? '' : 's'
                                }`}
                              </p>
                              {likes?.length > 0 && (
                                <p>
                                  {likes.length} like
                                  {likes.length === 1 ? '' : 's'}
                                </p>
                              )}
                            </div>
                            <p className='text-xs'>{`Shared on ${new Date(
                              dateCreated
                            ).toLocaleDateString()}`}</p>
                          </div>
                        </div>
                      </Link>

                      {/* {uid === userId && (
                    <>
                      <label>Visible on profile</label>
                      <input
                        type='checkbox'
                        checked={linkToProfile}
                        onChange={setSharedBlockVisibilityOnProfile({
                          id: _id,
                          visible: !linkToProfile,
                        })}
                      />

                      {idOfSharedBlockBeingEdited === _id ? (
                        <>
                          <input
                            className='border-2 border-black rounded-lg px-2'
                            value={newCustomTitle}
                            onChange={({ target: { value } }) =>
                              setNewCustomTitle(value)
                            }
                          />
                          <button
                            className='border border-black rounded-lg px-2'
                            onClick={editSharedBlockTitle({
                              title: newCustomTitle,
                              id: _id,
                            })}
                          >
                            Save new title
                          </button>
                          <button
                            className='border border-black rounded-lg px-2'
                            onClick={() => {
                              setNewCustomTitle('');
                              setIdOfSharedBlockBeingEdited(undefined);
                            }}
                          >
                            Discard changes
                          </button>
                        </>
                      ) : (
                        <button
                          className='border border-black rounded-lg px-2'
                          onClick={() => {
                            setIdOfSharedBlockBeingEdited(_id);
                            setNewCustomTitle(customTitle);
                          }}
                        >
                          Edit title
                        </button>
                      )}

                      { {linkToProfile ? (
                                  <button
                                    className='border border-black rounded-lg px-2'
                                    onClick={setSharedBlockVisibilityOnProfile({
                                      id: _id,
                                      visible: false,
                                    })}
                                  >
                                    Hide from profile
                                  </button>
                                ) : (
                                  <button
                                    className='border border-black rounded-lg px-2'
                                    onClick={setSharedBlockVisibilityOnProfile({
                                      id: _id,
                                      visible: true,
                                    })}
                                  >
                                    Show on profile
                                  </button>
                                )} }
                      {_id === idOfBlockWithDeleteConfirmation ? (
                        <>
                          <span>Confirm unshare:</span>
                          <button
                            className='border border-black rounded-lg px-2'
                            onClick={deleteSharedBlock(_id)}
                          >
                            Confirm
                          </button>
                          <button
                            className='border border-black rounded-lg px-2'
                            onClick={() =>
                              setIdOfBlockWithDeleteConfirmation(undefined)
                            }
                          >
                            Cancel
                          </button>
                        </>
                      ) : (
                        <button
                          className='border border-black rounded-lg px-2'
                          onClick={() =>
                            setIdOfBlockWithDeleteConfirmation(_id)
                          }
                        >
                          Unshare
                        </button>
                      )}
                    </>
                  )} */}
                    </li>
                  );
                }
              )}
            </ul>
          </>
        )}
        {totalSharedBlocks && (
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(totalSharedBlocks / 50)}
            onPageChange={page => {
              loadPageOfSharedBlocks({
                page,
                sortingMethod: blockSortingMethod,
              });
              setCurrentPage(page);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SharedBlocksPage;
