import React, { FC } from 'react';
import NcImage from 'shared/NcImage/NcImage';
import HIW1img from 'images/HIW1img.png';
import HIW2img from 'images/HIW2img.png';
import HIW3img from 'images/HIW3img.png';
import HIW4img from 'images/HIW4img.png';
import VectorImg from 'images/VectorHIW.svg';
import Badge from 'shared/Badge/Badge';

import signup from 'images/signup.svg';
import startBuilding from 'images/startBuilding.svg';
import publishFusion from 'images/publishFusion.svg';
import earnPassiveRevenue from 'images/earnPassiveRevenue.svg';

export interface SectionHowItWorkProps {
  className?: string;
  data?: (typeof DEMO_DATA)[0][];
}

const DEMO_DATA = [
  {
    id: 1,
    img: signup,
    imgDark: signup,
    title: 'Browse Tools',
    link: '/page-search',
    desc: '\n\nFind the perfect tools to complete your tasks on autopilot',
  },
  {
    id: 2,
    img: startBuilding,
    imgDark: startBuilding,
    link: '/fuser/0',
    title: 'Build Tools',
    desc: "Create your own tools for free, it's easy with our no-code tool builder",
  },
  {
    id: 3,
    img: earnPassiveRevenue,
    imgDark: earnPassiveRevenue,
    //link: "/dashboard/?type=creator", // need to get the ID in there some how
    title: 'Sell Tools',
    desc: 'Earn revenue by sharing augmented versions of your own expertiese',
  },
  {
    id: 4,
    img: publishFusion,
    imgDark: publishFusion,
    //link: "/help",
    title: 'Share Your Creations',
    desc: 'Share your creations online to maximise your revenue',
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = '',
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-SectionHowItWork  ${className}`}
      data-nc-id='SectionHowItWork'
    >
      <div className='relative grid sm:grid-cols-2 lg:grid-cols-4 gap-10 sm:gap-16 xl:gap-20'>
        <img
          className='hidden md:block absolute inset-x-0 -top-1'
          src={VectorImg}
          alt='vector'
        />
        {data.map((item: (typeof DEMO_DATA)[number], index: number) => (
          <div
            key={item.id}
            className='relative flex flex-col items-center max-w-xs mx-auto'
          >
            <NcImage
              containerClassName='mb-5 sm:mb-10 lg:mb-20 max-w-[200px] mx-auto'
              src={item.img}
            />
            <div className='text-center space-y-5'>
              <Badge
                name={`Step ${index + 1}`}
                color={
                  !index
                    ? 'blue'
                    : index === 1
                    ? 'pink'
                    : index === 2
                    ? 'yellow'
                    : 'green'
                }
              />
              <h3 className='text-lg font-semibold text-blue-600 dark:text-blue-500 underline hover:font-bold'>
                <a href={item.link}>{item.title}</a>
              </h3>
              <span className='block text-neutral-500 dark:text-neutral-400'>
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
