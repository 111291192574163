import React, { useContext, useEffect, useState } from 'react';
import Multiselect from 'react-widgets/Multiselect';
import FuserContext from '../../context/FuserContext';
import { backendURL } from '../../constants/environmental';
import { buildCategoryTree } from '../../utils/tree';
import axios from 'axios';

let categoryTree: any[] = [];

function CategoriesSection({
  isForSharedBlock,
}: {
  isForSharedBlock: boolean;
}) {
  const {
    toolMetadata: { chosenCategories },
    updateToolMetadata,
  } = useContext(FuserContext);

  const [categories, setCategories] = useState<any>();
  const [displayedCategories, setDisplayedCategories] = useState(categories);
  const [selectedCategory, setSelectedCategory] = useState<any>();

  const chosenCategoryNames = chosenCategories?.map(
    ({ catName }: any) => catName
  );

  useEffect(() => {
    if (isForSharedBlock) {
      return;
    }
    fetchCategories();
  }, []);

  useEffect(() => {
    const multiselectInput = document.querySelector('.rw-widget-input');
    if (!multiselectInput) {
      return;
    }

    multiselectInput.addEventListener('click', goToTopLevelCategories);
    multiselectInput.addEventListener('blur', goToTopLevelCategories);

    return () => {
      multiselectInput.removeEventListener('click', goToTopLevelCategories);
      multiselectInput.removeEventListener('blur', goToTopLevelCategories);
    };
  }, []);

  return (
    <>
      <p style={{ alignSelf: 'flex-start' }}>
        Categories{' '}
        <i style={{ fontSize: '8pt', color: 'darkred' }}>
          (3 max, choose most relevant first)
        </i>{' '}
        :
      </p>
      <Multiselect
        style={{ width: '100%' }}
        dataKey='_id'
        textField='catName'
        value={chosenCategoryNames}
        onChange={handleCatChange}
        onSelect={handleCatSelect}
        // onSearch={handleCatSearch}
        data={displayedCategories}
        filter='contains'
        // add a heading indicating when the list is of subcategories
        {...(selectedCategory
          ? { groupBy: (subcategory: any) => 'Subcategory (optional)' }
          : {})}
        // listComponent={CustomListComponent}
      />
    </>
  );

  // need to do this for blur or maybe typing etc
  function goToTopLevelCategories() {
    setDisplayedCategories(categoryTree.map(({ catName }: any) => catName));
    setSelectedCategory(undefined);
  }

  function handleCatChange(newSelectedCategoryNames: string[]) {
    if (newSelectedCategoryNames.length >= chosenCategories.length) {
      return;
    }

    // run this code if a category has been removed
    const unselectedCategory = chosenCategories.filter(
      ({ catName }: any) => !newSelectedCategoryNames.includes(catName)
    )?.[0];

    updateToolMetadata({
      chosenCategories: chosenCategories.filter(
        (item: string) => item !== unselectedCategory
      ),
    });
    setDisplayedCategories(categoryTree.map(({ catName }: any) => catName));
    setSelectedCategory(undefined);
  }

  function handleCatSelect(selectedName: any) {
    updateToolMetadata({
      chosenCategories: [
        ...(selectedCategory
          ? chosenCategories.slice(0, -1)
          : chosenCategories),
        categories.find(({ catName }: any) => selectedName === catName),
      ],
    });

    const categoryNode = (
      selectedCategory ? selectedCategory.children : categoryTree
    ).find(({ catName }: any) => catName === selectedName);

    const { children } = categoryNode;
    const childrenNotChosen = children.filter(
      ({ catName }: any) => !chosenCategoryNames.includes(catName)
    );
    if (childrenNotChosen.length > 0) {
      setDisplayedCategories(
        childrenNotChosen.map(({ catName }: any) => catName)
      );
      setSelectedCategory(categoryNode);
    } else {
      setDisplayedCategories(categoryTree.map(({ catName }: any) => catName));
      setSelectedCategory(undefined);
    }
  }

  async function fetchCategories() {
    const res = await axios.get(`${backendURL}/categories/all`);
    const loadedCategories = res.data;
    categoryTree = buildCategoryTree(loadedCategories);

    setCategories(loadedCategories);
    setDisplayedCategories(categoryTree.map(({ catName }: any) => catName));
  }
}

export default CategoriesSection;
