import React, { FC, ReactNode } from 'react';
import ButtonPrimary from '../../shared/Button/ButtonPrimary';

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  mediumOrSmallerScreenImg?: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
  leftText: boolean;
  videoUrl?: string;
  isImg?: boolean
}

const SectionHero: FC<SectionHeroProps> = ({
  className = '',
  rightImg,
  heading,
  subHeading,
  btnText,
  leftText = true,
  mediumOrSmallerScreenImg,
  // videoUrl,
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id='SectionHero'
    >
      <div className='flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left'>
        {!leftText ? (
          <div className='flex-grow'>
            <img
              className='w-full'
              src={rightImg}
              alt=''
            />
          </div>
        ) : null}
        <div className='w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7'>
          <h2 className='text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100'>
            {heading}
          </h2>
          <span
            className='block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400'
            dangerouslySetInnerHTML={{ __html: subHeading }}
          ></span>
          {btnText && <ButtonPrimary href='/login'>{btnText}</ButtonPrimary>}
        </div>
        {leftText ? (
          <div className='flex-grow'>
            {mediumOrSmallerScreenImg ? (
              <picture className='w-full'>
                <source
                  media='(min-width: 1024px)'
                  srcSet={rightImg}
                />
                <img
                  src={mediumOrSmallerScreenImg}
                  alt='Responsive'
                />
              </picture>
            ) : (
              <img
                className='w-full hidden lg:inline'
                src={rightImg}
                alt=''
              />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SectionHero;


type VideoFrameProps = {
  videoUrl: string;
  isImg: boolean;
};

const videoFrame =({videoUrl,isImg} : VideoFrameProps): JSX.Element => {
  if (!isImg){
    return <div className='flex-grow'>
    <iframe
      className='w-full'
      width='560'
      height='315'
      src={videoUrl}
      title='YouTube video player'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
      allowFullScreen
    ></iframe>
  </div>
  }

  return <div className='flex-grow'><img src={videoUrl} /></div>
}


export const VideoHero: FC<SectionHeroProps> = ({
  className = '',
  videoUrl ="",
  isImg = false,
  heading,
  subHeading,
  btnText,
  leftText = true,
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id='SectionHero'
    >
      <div className='flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left'>
        {!leftText ? (
         videoFrame({videoUrl,isImg})
        ) : null}
        <div className='w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7'>
          <h2 className='text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100'>
            {heading}
          </h2>
          <span
            className='block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400'
            dangerouslySetInnerHTML={{ __html: subHeading }}
          ></span>
          {btnText && <ButtonPrimary href='/login'>{btnText}</ButtonPrimary>}
        </div>
        {leftText ? (
          <div className='flex-grow'>
            <iframe
              className='w-full'
              width='560'
              height='315'
              src={videoUrl}
              title='YouTube video player'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen
            ></iframe>
          </div>
        ) : null}
      </div>
    </div>
  );
};
