import React, { createContext, useState, useEffect } from "react";

export const BrowserSettingsContext = createContext();

export function BrowserSettingsProvider({ children }) {
  const [localStorageAvailable, setLocalStorageAvailable] = useState(undefined); // Initialize with 0 or fetch from API

  useEffect(() => {
    try {
      const l = localStorage;
      setLocalStorageAvailable(true);
    } catch {
      setLocalStorageAvailable(false);
    }
  }, []);

  const value = {
    localStorageAvailable,
  };

  return (
    <BrowserSettingsContext.Provider value={value}>
      {children}
    </BrowserSettingsContext.Provider>
  );
}
