import { useContext, useEffect } from 'react';
import useDeepCompareMemo from './useDeepCompareMemo';
import FuserContext from '../context/FuserContext';

const useRunnerFocusOnEdit = (block: any, blockIndex: number) => {
  const { runnerMode, setRunnerIndex } = useContext(FuserContext);
  const { dataResponse, ...dataExceptResponse } = block.data;
  const memoizedDataExceptResponse = useDeepCompareMemo(dataExceptResponse);

  useEffect(() => {
    //if (runnerMode) setRunnerIndex(blockIndex); // turned off for now, debugging why it runs from a question that is edited ahead of time
  }, [memoizedDataExceptResponse]);
};

export default useRunnerFocusOnEdit;
