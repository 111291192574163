import React from 'react';

interface ErrorPopupProps {
  message: string;
  onClose: () => void;
}

const ErrorPopup: React.FC<ErrorPopupProps> = ({ message, onClose }) => {
  return (
    <div className='bg-red-600 text-white p-2 ' >
      <button onClick={onClose}>X</button>
      <p>{message}</p>
    </div>
  );
};

export default ErrorPopup;
