import React from "react";
import { MdStar, MdStarHalf, MdStarOutline } from "react-icons/md";

const StarRating = ({ ratingOutOfFive }: { ratingOutOfFive: number }) => {
  // round the star rating up to the nearest half star
  const ratingOutOfTenRoundedUp = Math.ceil(ratingOutOfFive * 2);

  const numberOfFullStars = Math.floor(ratingOutOfTenRoundedUp / 2);

  const numberOfHalfStars = ratingOutOfTenRoundedUp % 2 === 1 ? 1 : 0;

  const numberOfEmptyStars = 5 - numberOfFullStars - numberOfHalfStars;

  const starClass = `text-yellow-400 fill-current w-6 h-6`;

  return (
    <div className="flex justify-between w-fit">
      {Array.from({ length: numberOfFullStars }).map((_, index) => (
        <MdStar key={index} className={starClass} />
      ))}
      {numberOfHalfStars > 0 && <MdStarHalf className={starClass} />}
      {Array.from({ length: numberOfEmptyStars }).map((_, index) => (
        <MdStarOutline key={index} className={starClass} />
      ))}
    </div>
  );
};

export default StarRating;
