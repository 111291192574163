import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';

interface ProfileDescriptionProps {
  description?: string;
}

const ProfileDescription: FC<ProfileDescriptionProps> = ({ description }) => {
  const defaultDescription = 'No description available.';

  return (
    <div className="w-full h-72 overflow-y-scroll p-4">
      <ReactMarkdown>{description || defaultDescription}</ReactMarkdown>
    </div>
  );
};

export default ProfileDescription;
