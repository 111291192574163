import React, { FC, useContext, useEffect, useState } from 'react';
import MyToolTips from '../../components/MyTooltip';
import FuserLoader from '../../containers/FuserPage/FuserLoader';
import Block from '../../models/Block';
import BlockProps from '../../models/BlockProps';
import FuserContext from '../../context/FuserContext';
import useBlockRunner from '../../hooks/useBlockRunner';
import useRunnerFocusOnEdit from '../../hooks/useRunnerFocusOnEdit';
import AutocompleteTextarea from '../../containers/FuserPage/AutocompleteTextarea';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { updateAtIndexRun } from '../../utils/array';
import { truncateAfter } from '../../utils/string';
import { MAX_PREVIEW_CHARS } from '../../constants/blocks';
import { replacePlaceholders } from '../../utils/fuser';

const InfoBlock: FC<BlockProps> = ({
  isLoading,
  index,
  block,
  setIsLoading,
  handleChange,
  updateBlockData,
  collapsed,
  isShared,
  updateBlocks,
  resultHtml,
}) => {
  const {
    blockStyles,
    blocks,
    runnerMode,
    setBlocks,
    setActivityLog,
    runnerIndex,
    stillRunning,
  } = useContext(FuserContext);

  useRunnerFocusOnEdit(block, index);

  useBlockRunner(onInfoSaveClick, index);

  const [updatedOutput, setUpdatedOutput] = useState('');

  const [updateButtonText, setUpdateButtonText] = useState('update');

  const [editingTextArea, setEditingTextArea] = useState(false);

  const disabledButtonClass = 'bg-slate-200 text-slate-500 hover:bg-slate-200';

  const {
    response,
    selectValue,
    editableOutput,
    inputToProcess,
    message,
    blockHeading,
  } = block.data;

  const processedMessage = replacePlaceholders(message, blocks);

  useEffect(() => {
    if (collapsed) setEditingTextArea(false);
  }, [collapsed]);

  if (isShared) {
    return (
      <>
        <div>{truncateAfter(MAX_PREVIEW_CHARS, blockHeading || 'Info')}</div>
        <ReactTextareaAutosize
          className='resize-y grow min-h-[20vh] max-h-[50vh] bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner overflow-y-scroll w-full'
          value={response}
          readOnly={true}
          // onFocus={() => setEditingTextArea(true)}
        />
      </>
    );
  }

  if (collapsed) {
    return selectValue === 'external' ? (
      <>
        <div>
          {truncateAfter(
            MAX_PREVIEW_CHARS,
            message ? processedMessage : 'Info block'
          )}
        </div>
        {response?.length > 0 && (
          <p>{truncateAfter(MAX_PREVIEW_CHARS, response.toString())} </p>
        )}
      </>
    ) : (
      <div>Internal data block</div>
    );
  }

  const testButtonClass =
    'w-max bg-blue-100 border-2 border-neutral-300 p-2 rounded-lg hover:bg-white';

  // console.log(response);
  return (
    <FuserLoader
      name='Info Block'
      loading={isLoading}
    >
      {runnerMode ? (
        selectValue === 'external' ? (
          <>
            <div>
              {truncateAfter(
                MAX_PREVIEW_CHARS,
                message ? processedMessage : 'Info'
              )}
            </div>
            <textarea
              className='resize-y bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner h-36 overflow-y-scroll w-full'
              {...(editableOutput
                ? {
                    ...(editingTextArea
                      ? { defaultValue: response }
                      : { value: response }),
                    onChange: editsMade,
                  }
                : { value: response, readOnly: true })}
              onFocus={() => setEditingTextArea(true)}
            />
            {editableOutput ? (
              <span className='flex items-center gap-4'>
                <button
                  className={`${testButtonClass} ${
                    !editingTextArea ? disabledButtonClass : ''
                  }`}
                  onClick={handleOutputUpdate}
                >
                  {updateButtonText}
                </button>
                <button
                  className={`${testButtonClass} ${
                    !editingTextArea ? disabledButtonClass : ''
                  }`}
                  onClick={() => setEditingTextArea(false)}
                >
                  discard changes
                </button>
              </span>
            ) : null}
          </>
        ) : (
          <div>Internal data block</div>
        )
      ) : (
        <div
          className={blockStyles}
          key={index}
        >
          <label
            className='text-xs'
            id='prompt-textarea'
          >
            info to be referenced later:{' '}
            <MyToolTips
              content='
            <p>Use this to store text to use later or to put together different outputs.</p>

            <p>Or you can use it to display a message to the user by selecting <q>show to user</q> from the dropdown.</p>
            '
              tipID='block-types'
              datatooltipplace='below'
            />
          </label>

          <AutocompleteTextarea
            className='w-full info-textarea resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner h-36'
            name='inputToProcess'
            value={inputToProcess || ''}
            block={block}
            index={index}
            onChange={handleChange}
            textAreaIndex={0}
          />

          <select
            className='bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner text-xs'
            id='info-block-select'
            name='selectValue'
            value={selectValue || ''}
            onChange={handleChange}
          >
            <option value='internal'>for internal use</option>
            <option value='external'>show to user</option>
          </select>

          {selectValue === 'external' && (
            <label className='flex gap-2 items-center'>
              <input
                type='checkbox'
                name='editableOutput'
                checked={editableOutput ?? false}
                onChange={handleEditableOutput}
              />
              <p className='select-none'>Let user edit the output</p>
            </label>
          )}

          <label className='text-xs'>Optional heading for user:</label>

          <AutocompleteTextarea
            block={block}
            index={index}
            autosize={true}
            onChange={handleChange}
            textAreaIndex={1}
            className='w-full resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner'
            name='message'
            value={message ?? ''}
            placeholder='Heading for user'
          />

          <button
            className={testButtonClass}
            onClick={onInfoSaveClick}
          >
            Save info
          </button>
          <div
            className='overflow-x-auto'
            dangerouslySetInnerHTML={{ __html: resultHtml }}
          />

          {/* <p>{statusMessage}</p> */}
        </div>
      )}
    </FuserLoader>
  );

  async function onInfoSaveClick() {
    console.log('I am here', index);
    setIsLoading(true); // start loading

    const currentBlock = blocks[index];

    const info = currentBlock.data.inputToProcess;
    //const infoTypeSelect = currentBlock.data.selectValue;

    const processedInput = info ? replacePlaceholders(info, blocks) : '';

    console.log({ processedInput, blocks });
    //if (!Array.isArray(processedInput)) processedInput = [processedInput];
    // start of loop for dealing with processed input
    // you can replace these with a React state
    let inputHtml = '';

    if (Array.isArray(processedInput)) {
      for (let i = 0; i < processedInput.length; i++) {
        if (Array.isArray(processedInput[i])) {
          // for 2d or more
          for (let j = 0; j < processedInput[i].length; j++) {
            inputHtml +=
              '<b>input [' +
              i +
              ',' +
              j +
              ']:</b><br/>' +
              processedInput[i][j] +
              '<br/>----<br/><br/>';
          }
        } else {
          inputHtml +=
            '<b>input [' +
            i +
            ']:</b><br/>' +
            processedInput[i] +
            '<br/>----<br/><br/>'; // for 1D
        }
      }
    } else {
      inputHtml +=
        '<b>input:</b><br/>' + processedInput + '<br/>----<br/><br/>'; // for string
    }

    /*
    // Store the unprocessed text
    if (infoTypeSelect === "internal") newBlocks[index].data.content = info;
    if (infoTypeSelect === "external") newBlocks[index].data.response = info;
    */

    console.log('onInfoSaveClick');
    //setInputParagraph(inputHtml);
    const blockUpdater = (blocks: any) => {
      // Update the blocks state
      const newBlocks = [...blocks];
      newBlocks[index] = {
        ...newBlocks[index],
        updatedBlock: true,
        data: {
          ...newBlocks[index].data,
          type: 'info',
          response: processedInput,
        },
      };
      //console.log('setting info block to', newBlocks[index]);
      return newBlocks;
    };

    setIsLoading(false);
    setActivityLog((prevLog: string[]) => [
      ...prevLog,
      `Saved info block at index: ${index}`,
    ]);
    // setStatusMessage('Saved successfully');
    // setActivityLog(prevLog => [...prevLog, `Saved info block at index: ${index}`]);
    if (!runnerMode) {
      setBlocks(blockUpdater);
    } // update the blocks
    else {
      return blockUpdater;
    }
  }

  function handleEditableOutput() {
    const { editableOutput } = block.data;
    if (editableOutput) {
      updateBlockData('editableOutput', false);
    } else {
      updateBlockData('editableOutput', true);
    }
  }

  function editsMade(e: React.ChangeEvent<HTMLTextAreaElement>) {
    if (e.target.value != response) {
      setUpdateButtonText('update');
      setUpdatedOutput(e.target.value);
    }

    // set the button back to update
  }

  function handleOutputUpdate() {
    // console.log('infoblock', runnerMode, selectValue, inputToProcess);
    // console.log('running? before', stillRunning, runnerMode, runnerIndex);
    block.data.inputToProcess = updatedOutput;

    const processedUpdatedOutput = updatedOutput
      ? replacePlaceholders(updatedOutput, blocks)
      : '';

    block.data.response = processedUpdatedOutput;

    setUpdateButtonText('updated!');
    //runnerIndex = index + 1;
    setBlocks((blocks: Block[]) => updateAtIndexRun(index, block, blocks)); // restarts runner

    setEditingTextArea(false);

    console.log(
      'running?',
      stillRunning,
      runnerMode,
      runnerIndex,
      index
      //blocks[runnerIndices.previous].data.response?.length
    );
  }
};

export default InfoBlock;
