import React, { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddOrSaveBlockSection from './AddOrSaveBlockSection';
import BlockReferenceInputSection from './BlockReferenceInputSection';
import IsPublishedSection from './IsPublishedSection';
import IsAdminPublishedSection from './IsAdminPublishedSection';
import MyToolTips from '../../components/MyTooltip';
import Avatar from '../../shared/Avatar/Avatar';
import { Link } from 'react-router-dom';
import ImageGenerator from './ImageGenerator';
import PublishForm from './PublishForm';
import { useAuthUser } from 'react-auth-kit';
import ReviewForm from './ReviewForm';
import FuserContext from '../../context/FuserContext';
import 'react-widgets/styles.css';
import { YoutubeAccordionInfo } from '../NftDetailPage/AccordionInfo';
import { nftsImgs } from '../../constants/fakeData';
import { TbLayoutSidebarLeftExpand } from 'react-icons/tb';
import { iconStyle, menuButtonStyles } from '../../constants/styles';
import ShareToolSection from '../../components/ShareToolSection';
import RecommendedToolsCarousel from './RecommendedToolsCarousel';
import TagsSection from './TagsSection';
import SubscriptionInfoSection from './SubscriptionInfoSection';
import CategoriesSection from './CategoriesSection';
import AutosaveSection from './AutosaveSection';
import HideFromSearchSection from './HideFromSearchSection';
import ReportIssueButton from './ReportIssueButton';
import LoadResponsesSection from './LoadResponsesSection';
import SeoSection from './SeoSection';

const FuserSidebar: FC<any> = ({
  isForSharedBlock,
  title,
  description,
  isPublished,
  authorCoverPhoto,
  authorId,
  authorName,
  toolId,
  customUrlSlug,
  toggleSidebar,
}) => {
  const { runnerMode, isAuthor, toolMetadata } = useContext(FuserContext);

  const user = useAuthUser()();

  const navigate = useNavigate();

  const goToToolDetailsPage = () => {
    return navigate(`/fusion/${customUrlSlug ?? toolId}`);
  };

  return (
    <aside className='flex runner-height flex-col overflow-x-hidden bg-neutral-200 dark:bg-neutral-900 p-2 rounded-lg gap-8 overflow-y-scroll lg:h-screen sticky top-0 lg:w-1/4 lg:shrink-0 lg:static items-center lg:pb-80'>
      {/* <div className='flex gap-2 justify-center items-center'>
        <p>Hide sidebar</p>
        <button
          className={menuButtonStyles}
          onClick={toggleSidebar}
        >
          <ArrowRightIcon className={iconStyle} />
        </button>
      </div> */}

      {!runnerMode && !isForSharedBlock && <AddOrSaveBlockSection />}

      {runnerMode || isForSharedBlock ? (
        <>
          {title && (
            <div className='w-full bg-blue-200 dark:bg-neutral-800 p-4 sm:px-32 lg:p-4 flex flex-col gap-2 items-center justify-between rounded-lg shadow-xl'>
              <button
                className={menuButtonStyles + ' hidden sm:block mr-auto'}
                onClick={toggleSidebar}
              >
                <TbLayoutSidebarLeftExpand className={iconStyle} />
              </button>

              <RecommendedToolsCarousel toolId={toolId} />

              <hr />

              <br />

              <div className='w-full flex justify-between items-center'>
                <h1 className='text-xl'>{title}</h1>
              </div>

              {description && (
                <>
                  <YoutubeAccordionInfo description={description} />
                  <br />
                </>
              )}

              {toolMetadata?.furtherInfo && (
                <>
                  <YoutubeAccordionInfo
                    description={toolMetadata?.furtherInfo}
                    customTitle='Further info'
                    defaultOpen={false}
                    scroll
                  />
                  <br />
                </>
              )}

              {!isForSharedBlock && (
                <>
                  <SubscriptionInfoSection toolId={toolId} />

                  <AutosaveSection toolId={toolId} />

                  <br />

                  {isPublished && (
                    <a
                      className='border border-gray-300 border-2 p-1 px-2 rounded-lg bg-white hover:bg-blue-100'
                      href='https://skillfusion.ai/tools-help'
                      target='_blank'
                    >
                      Help Using Tools
                    </a>
                  )}

                  <br />

                  {!isAuthor && <ReviewForm />}

                  <br />
                </>
              )}

              {isPublished && (
                <button
                  className={
                    'border border-gray-300 border-2 p-1 px-2 rounded-lg bg-white hover:bg-blue-100'
                  }
                  onClick={goToToolDetailsPage}
                >
                  {isForSharedBlock
                    ? 'Check out this tool'
                    : 'Return to tool details page'}
                </button>
              )}
              <br />

              {!isForSharedBlock && (
                <>
                  <ReportIssueButton />
                  {user?.loggedin === 'false' && (
                    <>
                      <LoadResponsesSection />
                      <br />
                    </>
                  )}
                </>
              )}

              <br />

              <div className='flex items-center '>
                <Avatar
                  imageUrl={`${authorCoverPhoto || nftsImgs[3]}`}
                  sizeClass='h-9 w-9'
                  radius='rounded-full'
                />
                {authorId && (
                  <span className='ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col'>
                    <span className='text-sm'>Creator</span>
                    <span className='text-neutral-900 dark:text-neutral-200 font-medium flex items-center'>
                      <span>
                        <Link to={`/profile/${authorId}`}>
                          <p>{authorName ?? 'username'} </p>
                          <p className='underline'>Visit Profile</p>
                        </Link>
                      </span>

                      {/*
                        // could have an admin icon here:
                        <VerifyIcon iconClass="w-4 h-4" />
                        */}
                    </span>
                  </span>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <BlockReferenceInputSection />

          <div className='w-full gap-2 bg-blue-200 dark:bg-neutral-800 p-2 flex flex-col items-center justify-between rounded-lg shadow-xl'>
            <h1>
              Publish Details{' '}
              <MyToolTips
                content='
                <p>Publish Settings:</p>
                <p>You get 100% on all tool sales</p>
                <p>Please test your tools thrououghly by clicking the "running man" icon to run it in user mode</p>
                <p>Include terms in your title and description that users may search for.
                <br/>(e.g. <q>translation</q>, <q>summarise</q>, <q>fitness plan</q>)</p>

                <p>Include relevant industries, locations and other qualifying criteria in the title if applicable.
                <br />(e.g. <q>HR Documents <u>for US Businesses</u></q>, <q>Parking Ticket Advice <u>- UK Law</u></q>...)</p>

                <p>Make your description detailed, this is the marketing copy for your tool! It should explain what it can do and entice users to want to try it.</p>

                <p>For your tool image you could grab a screenshot of the final output if you want to.</p>

                <p>If you make a video of your tool and add the Youtube link in your description, then our system will automatically embed the video. </p>

                <p>Price can be changed at any time but monthly users that have already subscribed will be locked in to the lower price if the price goes up</p>

                <p>To receive your commission please make sure you have added your paypal email to the<br/><q>Discover</q> > <q>My Creations</q> page.</p>
                <p>You are welcome to promote yourself at the end of your description or a message at the end of your tool. E.g. <q>for more advice on ... call us on ...</q></p>
                '
                tipID='block-types'
                datatooltipplace='left'
              />
            </h1>

            <PublishForm />

            <CategoriesSection isForSharedBlock={isForSharedBlock} />

            <TagsSection />

            <HideFromSearchSection />

            <ImageGenerator />

            <IsPublishedSection />

            {user?.loggedin === 'false' && <IsAdminPublishedSection />}
          </div>

          {user?.loggedin === 'false' && <SeoSection />}

          <ShareToolSection toolId={toolId} />

          <div className='w-full gap-2 bg-blue-200 dark:bg-neutral-800 p-2 flex flex-col items-center justify-between rounded-lg shadow-xl'>
            <ReportIssueButton />
          </div>

          <br />
        </>
      )}
    </aside>
  );
};

export default FuserSidebar;
