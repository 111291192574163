import axios from 'axios';
import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import { useAuthHeader } from 'react-auth-kit';
import { MdClose } from 'react-icons/md';
import { getBrowser, getOperatingSystem } from '../utils/userDeviceInfo';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { backendURL } from '../constants/environmental';

const SurveyDialog: FC<any> = () => {
  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const feedbackRef = useRef<HTMLTextAreaElement | null>(null);
  const [submissionStatusMessage, setSubmissionStatusMessage] =
    useState<string>('');
  const authHeader = useAuthHeader()();

  useLayoutEffect(() => {
    dialogRef.current?.showModal();
  }, [dialogRef]);

  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  return (
    <dialog
      className='backdrop:bg-gray-400 backdrop:bg-opacity-50'
      ref={dialogRef}
    >
      <div className='flex justify-between gap-4 mb-4'>
        <h1 className='text-lg font-bold'>
          {feedbackSubmitted ? 'Feedback submitted' : 'Wait, before you go!'}
        </h1>
        <button
          className='px-2'
          onClick={() => {
            dialogRef.current?.close();
            if (!feedbackSubmitted) {
              axios.get(
                `${backendURL}/survey/buy-credits/rejectIfNotAlreadyCompleted`,
                {
                  headers: {
                    Authorization: authHeader,
                  },
                }
              );
              // ReactGA.event('survey_test', {
              //   shownToUser: true,
              //   completed: false,
              // });
              // ReactPixel.track('survey_test', {
              //   shownToUser: false,
              //   completed: false,
              // });
            }
          }}
        >
          <MdClose />
        </button>
      </div>

      {feedbackSubmitted ? (
        <p>Thank you for your help!</p>
      ) : (
        <form
          className='flex flex-col gap-4 items-center'
          onSubmit={e => {
            e.preventDefault();

            setSubmissionStatusMessage('Sending feedback...');

            axios
              .post(
                `${backendURL}/survey/buy-credits/feedback`,
                {
                  feedback: feedbackRef.current?.value,
                  browser: getBrowser(),
                  operatingSystem: getOperatingSystem(),
                },
                {
                  headers: {
                    Authorization: authHeader,
                  },
                }
              )
              .then(() => {
                setFeedbackSubmitted(true);
                // ReactGA.event('survey_test', {
                //   shownToUser: true,
                //   completed: true,
                // });
                // ReactPixel.track('survey_test', {
                //   shownToUser: false,
                //   completed: true,
                // });
              })
              .catch(error => {
                console.log(error);
                setSubmissionStatusMessage(error.response.data);
              });
          }}
        >
          <label className='w-full flex flex-col gap-4'>
            <p>
              {
                "We noticed you haven’t purchased any credits yet. If anything is stopping you then please let us know, we'd love to hear your feedback!"
              }
            </p>
            <textarea
              autoFocus
              className='resize rounded-md w-full'
              rows={6}
              ref={feedbackRef}
            />
          </label>
          {submissionStatusMessage && <p>{submissionStatusMessage}</p>}{' '}
          <button
            type='submit'
            className='justify-self-center md:justify-self-end bg-blue-100 border-2 p-2 rounded-lg hover:bg-white border border-black border-1 rounded-lg p-1 px-2'
          >
            Share feedback
          </button>
        </form>
      )}
    </dialog>
  );
};

export default SurveyDialog;
