import React, { FC, useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { nftsImgs } from '../constants/fakeData';
import LinkToProfile from './LinkToProfile';
import { timestampToLocalDate } from '../utils/date';
import axios from 'axios';
import ReplyForm from './ReplyForm';
import { useAuthUser, useAuthHeader } from 'react-auth-kit';
import { backendURL } from '../constants/environmental';
import { updateAtIndex } from '../utils/array';
import ReactTextareaAutosize from 'react-textarea-autosize';

const CommentsDisplay: FC<any> = ({
  targetType,
  targetId,
  isOwner,
  noReplies,
}) => {
  const authHeader = useAuthHeader()();
  const user = useAuthUser()();
  const userId = user?.id;

  const [comments, setComments] = useState<any[] | undefined>();

  const loadComments = () => {
    axios
      .get(`${backendURL}/comments/${targetType}/${targetId}`)
      .then(response => setComments(response.data))
      .catch(error => {
        console.error('Error fetching block comments: ', error);
      });
  };

  useEffect(() => {
    loadComments();
  }, []);

  const [addingComment, setAddingComment] = useState<boolean>(false);

  const addComment = () => {
    setAddCommentButtonText('Adding comment...');
    setAddingComment(true);

    return axios
      .post(
        `${backendURL}/comments/${targetType}/${targetId}`,
        { text: newCommentText },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      )
      .then(response => {
        const newComment = response.data;
        setComments((comments: any) => [...comments, newComment]);
      })
      .catch(error => {
        console.error('Error adding comment: ', error);
      })
      .finally(() => {
        setAddCommentButtonText('Add comment');
        setAddingComment(false);
      });
  };

  const deleteComment = (commentId: string) => () => {
    return axios
      .delete(`${backendURL}/comments/${commentId}`, {
        headers: {
          Authorization: authHeader,
        },
      })
      .then(() => {
        setComments((comments: any) =>
          comments.filter((comment: any) => comment._id !== commentId)
        );
      })
      .catch(err => {
        console.log('There was an error deleting your comment', err);
      });
  };

  const [replyFormCommentId, setReplyFormCommentId] = useState(false);

  const createReply = (commentId: string) => (replyText: string) => () => {
    return axios
      .post(
        `${backendURL}/comments/${commentId}/reply`,
        {
          targetId,
          replyText,
        },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      )
      .then(response => {
        setComments((comments: any) => {
          const indexOfOldComment = comments.findIndex(
            ({ _id }: any) => _id === commentId
          );
          return updateAtIndex(indexOfOldComment, response.data, comments);
        });
        setReplyFormCommentId(false);
      })
      .catch(error => {
        console.error('Error adding comment: ', error);
      });
  };

  const deleteCommentReply = (commentId: string) => () => {
    return axios
      .delete(`${backendURL}/comments/${commentId}/reply`, {
        headers: {
          Authorization: authHeader,
        },
      })
      .then(response => {
        setComments((comments: any) => {
          const indexOfCommentToUpdate = comments.findIndex(
            ({ _id }: any) => _id === commentId
          );
          return updateAtIndex(indexOfCommentToUpdate, response.data, comments);
        });
      })
      .catch(err => {
        console.log('There was an error deleting your reply', err);
      });
  };

  const [newCommentText, setNewCommentText] = useState<string>('');
  const [addCommentButtonText, setAddCommentButtonText] =
    useState<string>('Add comment');

  return (
    <>
      <div className='pt-9'>
        <h2>Comments:</h2>
        {comments === undefined ? (
          <p>Loading comments...</p>
        ) : comments.length === 0 ? (
          <p className='text-sm'>No comments yet</p>
        ) : (
          <>
            {comments.map((comment: any, index: number) => {
              return (
                <div
                  key={index}
                  className='bg-white shadow-md rounded px-8 py-6 mb-4 flex flex-col gap-4 my-2 border-slate-300 border-2 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200'
                >
                  <div className='flex justify-between items-center overflow-x-hidden'>
                    <div className='flex items-start w-full'>
                      <div className='flex flex-col items-center gap-2 shrink-0'>
                        <img
                          src={comment.author.profileImage || nftsImgs[3]}
                          alt={comment.author.name}
                          className='w-12 h-12 object-cover rounded-full'
                        />

                        {(userId === comment.author._id ||
                          user?.loggedin === 'false') && (
                          <button onClick={deleteComment(comment._id)}>
                            <MdDelete
                              className='text-red-500 hover:text-red-700'
                              size={24}
                            />
                          </button>
                        )}
                      </div>

                      <div className='ml-4 flex flex-col gap-4 w-full'>
                        <ReactTextareaAutosize
                          className='text-sm overflow-x-auto resize-none border-0 p-0'
                          value={`Answer: ${comment?.text}`}
                          readOnly
                        />

                        <div>
                          <p className='text-sm font-semibold'>Author:</p>
                          <LinkToProfile user={comment.author} />
                        </div>

                        <p className='text-sm'>
                          Date posted:{' '}
                          {timestampToLocalDate(comment?.timestamp)}
                        </p>

                        {noReplies ||
                          (!comment?.replyAuthor &&
                            (isOwner || user?.loggedin === 'false') &&
                            (replyFormCommentId === comment?._id ? (
                              <ReplyForm
                                setReplyFormVisible={setReplyFormCommentId}
                                createReply={createReply(comment?._id)}
                              />
                            ) : (
                              <button
                                onClick={() =>
                                  setReplyFormCommentId(comment?._id)
                                }
                              >
                                Reply
                              </button>
                            )))}
                      </div>
                    </div>
                  </div>

                  {/* if no user exists with the replyAuthor _id then replyAuthor will be null */}
                  {noReplies ||
                    (comment?.replyAuthor && (
                      <>
                        <hr />
                        <div className='flex w-full justify-end items-start gap-4 overflow-x-hidden'>
                          <div className='ml-4 flex flex-col gap-4 w-full overflow-x-hidden'>
                            <ReactTextareaAutosize
                              className='text-sm overflow-x-auto resize-none border-0 p-0'
                              value={`Answer: ${comment?.replyText}`}
                              readOnly
                            />

                            <h3 className='text-sm'>
                              <p className='text-sm font-medium'>Author:</p>
                              <LinkToProfile user={comment?.replyAuthor} />
                            </h3>
                            {/* <p className='text-sm'>
                                  Date posted:{' '}
                                  {timestampToLocalDate(questionAndAnswer?.replyTimestamp)}
                                </p> */}
                          </div>

                          <div className='flex flex-col items-center gap-2 shrink-0'>
                            <img
                              src={
                                comment?.replyAuthor?.profileImage ||
                                nftsImgs[3]
                              }
                              alt={comment?.replyAuthor?.name}
                              className='w-12 h-12 object-cover rounded-full'
                            />
                            {(userId === comment?.replyAuthor?._id ||
                              user?.loggedin === 'false') && (
                              <button
                                onClick={deleteCommentReply(comment?._id)}
                              >
                                <MdDelete
                                  className='text-red-500 hover:text-red-700'
                                  size={24}
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              );
            })}
          </>
        )}
      </div>

      {userId && (
        <div className='flex flex-col items-start pt-9'>
          <h2>Write a comment:</h2>
          <textarea
            className='resize-none bg-transparent rounded-xl text-sm border border-black-100 shadow-inner h-36 overflow-y-scroll w-full'
            onChange={({ target: { value } }) => {
              setNewCommentText(value);
            }}
            value={newCommentText}
          ></textarea>
          <button
            onClick={addComment}
            className={`mt-5 nc-Button h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 flex-1  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0`}
            disabled={addingComment}
          >
            {addCommentButtonText}
          </button>
        </div>
      )}
    </>
  );
};

export default CommentsDisplay;
