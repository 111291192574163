import FuserLoader2 from './FuserPage/FuserLoader2';
import React, { useState, useEffect } from 'react';
import { AiOutlineSend } from 'react-icons/ai';
import { Socket } from 'socket.io-client';
import io from 'socket.io-client';
import { backendURL } from '../constants/environmental';

let socket: Socket;

const ChatPage: React.FC = () => {
  const [chat, setChat] = useState<string[]>([]);
  const [message, setMessage] = useState<string>('');
  const [conversations, setConversations] = useState<string[]>([]); // assuming you will have your conversations here
  const [search, setSearch] = useState<string>('');
  const [selectedChatRoom, setSelectedChatRoom] = useState<string>(''); // track the selected chat room

  // connect to backend websocket server
  // useEffect(() => {
  //   const [, backendUrlWithoutProtocol] = backendURL!.split('://');
  //   console.log(`connecting to ws://${backendUrlWithoutProtocol}`);
  //   const socket = new WebSocket(`ws://${backendUrlWithoutProtocol}`);

  //   socket.addEventListener('open', () => console.log('connected'));

  //   socket.addEventListener('message', ({ data }) => {
  //     console.log(data);
  //   });

  //   setTimeout(() => {
  //     socket.close();
  //     console.log('disconnected');
  //   }, 3000);
  // }, []);

  // useEffect(() => {
  //   socket = io('http://localhost:5000');

  //   socket.on('message', (message: string) => {
  //     setChat([...chat, message]);
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, [chat]);

  const sendMessage = (event: React.FormEvent) => {
    event.preventDefault();
    socket.emit('message', message);
    setMessage('');
  };

  const handleChatRoomSelection = (chatRoom: string) => {
    setSelectedChatRoom(chatRoom);
  };

  const handleCreateChatRoom = () => {
    // Logic for creating a chat room
  };

  return (
    <div className='flex flex-col h-screen bg-gray-200'>
      <div className='flex-grow flex h-full'>
        {/* Conversation list */}
        <aside className='bg-white w-1/6 md:w-1/4 overflow-auto border-r'>
          <div className='p-4'>
            <div className='flex border-b pb-2 items-center justify-between'>
              <h2 className='text-xl border-b pb-2'>Conversations</h2>
              <button
                className='text-blue-500 shadow-xl border px-2 rounded-lg'
                onClick={handleCreateChatRoom}
              >
                +
              </button>
            </div>

            <input
              type='text'
              className='w-full rounded-md p-2 mt-4'
              placeholder='Search...'
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </div>
          {conversations.length > 0 ? (
            conversations.map((conversation, index) => (
              <div
                key={index}
                className={`p-4 border-b cursor-pointer hover:bg-gray-100 ${
                  selectedChatRoom === conversation ? 'bg-gray-100' : ''
                }`}
                onClick={() => handleChatRoomSelection(conversation)}
              >
                {conversation}
              </div>
            ))
          ) : (
            <div className='p-4 flex flex-col items-center justify-center gap-2'>
              No conversations found.{' '}
              <button
                className=' bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-xl'
                onClick={handleCreateChatRoom}
              >
                Create First Chat Room
              </button>
            </div>
          )}
        </aside>

        {/* Chat area */}
        <div className='flex flex-col w-full md:w-5/6'>
          {selectedChatRoom ? (
            <div
              className='mt-10 w-full overflow-auto p-5'
              style={{ flex: '1 1 calc(100vh - 16rem)' }}
            >
              {chat.length > 0 ? (
                chat.map((message, index) => (
                  <div
                    key={index}
                    className='border rounded-xl bg-white p-2 my-2'
                  >
                    {message}
                  </div>
                ))
              ) : (
                <div className='flex items-center justify-center h-full'>
                  Please select a conversation.
                </div>
              )}
            </div>
          ) : (
            <div className='flex items-center justify-center h-full'>
              <FuserLoader2 loading={true} />
              Please select a conversation.
            </div>
          )}
          <form
            onSubmit={sendMessage}
            className='w-full flex justify-between p-5 bg-white border-t'
          >
            <input
              type='text'
              className='w-full rounded-xl p-2'
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
            <button
              type='submit'
              className='ml-2 bg-blue-500 text-white rounded-xl p-2 px-4'
            >
              <AiOutlineSend />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
