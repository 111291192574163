const testButtonStyles =
  'bg-blue-100 border-2 p-2 rounded-lg hover:bg-white w-36';

const restartButtonStyles =
  'bg-purple-100 border-2 p-2 rounded-lg hover:bg-white w-14';

const menuButtonStyles =
  'bg-blue-200 dark:bg-neutral-900 dark:text-neutral-200 p-2 rounded-xl text-xs cursor-pointer hover:bg-blue-300 hover:shadow-lg transform hover:-translate-y-1 transition-all duration-200 ease-in-out';
const iconStyle =
  'w-6 h-6 font-bold dark:text-neutral-200 dark:text-neutral-200';

export { testButtonStyles, restartButtonStyles, menuButtonStyles, iconStyle };
