import { useRef } from 'react';

function useShallowEqualMemo(array: any[]) {
  const ref = useRef(array);

  if (!shallowEqual(ref.current, array)) {
    ref.current = array;
  }

  return ref.current;
}

function shallowEqual(arr1: any[], arr2: any[]) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

export default useShallowEqualMemo;
